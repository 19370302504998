import React from "react";

const CalculatedIcon = (props) => {
  const { size, style } = props;
  const dimensions = {};
  if (typeof size !== "undefined") {
    dimensions.width =
      style.width > style.height ? size : (style.width * size) / style.height;
    dimensions.height =
      style.height > style.width ? size : (style.height * size) / style.width;
  }
  const styles = { ...style, ...dimensions };
  return (
    <>
    <img {...props} style={styles} />
    </>
  )
  ;
};

export default CalculatedIcon;
