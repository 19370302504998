import history from "../history";
//Constants
import { EXPIRE_TIME } from "../utils/constants"

//Cookie
import Cookies from 'js-cookie'
import { refreshToken, logOut } from "../services/auth.service";
class Auth {

  login = () => {


    history.replace("/");
  };

  // parses the result after authentication from URL hash
  handleAuthentication = (authResult) => {
    if (authResult) {
      this.setSession(authResult);
    } else if ("err") {
      history.replace("/home");
    }
  };

  logout  = async () => {
    const token = this.getToken()
    if (token) {
      const userInfo = this.getUserInfo()
      await logOut({email: userInfo?.email})
    }
    Cookies.remove('user')
    Cookies.remove('access_token')
    history.replace("/login");
    window.location.reload()
  };

  getUserInfo = () => {
    return Cookies.get("user")
      ? JSON.parse(Cookies.get("user"))
      : null;
  }

  isAuthenticated = async () => {
    const token = Cookies.get("access_token");
    if (token) {
      const userInfo = this.getUserInfo()
      if (!this.isValidToken()) {
        const newToken = await this.handleRefreshToken();
        if (!newToken) return null;
        Cookies.set('access_token', newToken)
        Cookies.set('user', JSON.stringify({
          ...userInfo,
          expire:  Math.floor(Date.now() / 1000) + (+userInfo.validity),
          token: newToken
        }))
        return token;
      }
      return token;
    }
    return null;
  };

  /**
   * @return Logged user if exists or null otherwise
   **/
  getUser = () => {
    const user = sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : null;
    return user && user.RolsUser.length > 0 ? user : null;
  };

  getToken = () => {
    return Cookies.get("access_token")
      ? Cookies.get("access_token")
      : null;
  }

  handleRefreshToken = async () => {
    try {
      const userInfo = this.getUserInfo()
      //console.log(userInfo)
      const result = await refreshToken({ payload: userInfo.refreshToken })
      return result?.authToken;
    } catch (err) {
      console.log(err)
      return null;
    }
  }

  isValidToken = () => {
    const userInfo = this.getUserInfo()
    return !((Math.floor(Date.now() / 1000) > userInfo.expire))
  }


}



export default new Auth();
