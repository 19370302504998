import { ALERT_ACTIONS } from "../actionsTypes/alert.types";


export const showAlertAction = (payload) => (dispatch, getPrevState) => {
	dispatch({
		type: ALERT_ACTIONS.SHOW_ALERT,
		payload: payload,
	});
};

export const hideAlertAction = (payload) => (dispatch, getPrevState) => {
	dispatch({
		type: ALERT_ACTIONS.HIDE_ALERT,
		payload: payload,
	});
};
