import React, { Component } from 'react';
import {environment} from '../config/config';
import history from '../history';
import Loading from './Loading';
import { Redirect } from 'react-router';

class Checklog extends Component {

    render() {
        const { auth } = this.props;
        if (auth.isAuthenticated()) {
            history.replace("/principal");
        } else {
            window.location.href = '/login';
        }
        return !auth.isAuthenticated() ? <Loading{...this.props} auth={auth}/> :  <Redirect to="/principal" />
    }
}

export default Checklog;
