import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import { DataGrid, esES } from "@material-ui/data-grid";

import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';


const useStyles = makeStyles((theme) => ({
	container: {
		width: "100%",
	},
	button: {
		textTransform: "none",
		borderRadius: "1rem",
		width: "8rem",
	},
	buttonContainer: {
		marginTop: 30,
	},
	notFoundText: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		paddingTop: 100,
		height: "100%",
	},
	columns: {
		color: theme.palette.primary.main,
		fontWeight: "bold",
		textAlign: "center",
	},
	buttonSee: {
		borderRadius: "20px",
		textTransform: "none !important",
	},
	buttonUpdate: {
		textTransform: "none !important",
	}
}));

export default function TableContent(props) {
	const {
		rowsThirdList,
		handleChangeRowsSelect,
		deleteColaborator,
		pageSize,
        setPageSize,
		handleEdit
	} = props;

	const classes = useStyles();

	const columns = [
		{
			field: "no",
			renderHeader: () => {
				return <span className={classes.columns}>{"No."}</span>;
			},
			width: 150,
			headerAlign: "center",
			cellStyle: classes.columns,
			renderCell: (cellValues) => {
				return (
					<span>
						{cellValues.row.index}
					</span>
				);
			},
		},
		{
			field: "FullName",
			renderHeader: () => {
				return <span className={classes.columns}>{"Nombre"}</span>;
			},
			width: 200,
			headerAlign: "center",
			renderCell: (cellValues) => {
				return (
					<span>
						{cellValues.row.FullName}
					</span>
				);
			},
		},
		{
			field: "LastName",
			headerAlign: "center",
			renderHeader: () => {
				return (
					<span className={classes.columns}>{"Primer apellido"}</span>
				);
			},
			width: 200,
			renderCell: (cellValues) => {
				return <span>{cellValues.row.LastName}</span>;
			},
		},
		{
			field: "SurName",
			renderHeader: () => {
				return <span className={classes.columns}>Segundo apellido</span>;
			},
			width: 190,
			headerAlign: "center",
			renderCell: (cellValues) => {
				return <span>{cellValues.row.SurName}</span>;
			},
		},
		{
			field: "IdentificationNumber",
			renderHeader: () => {
				return <span className={classes.columns}>{"Cédula"}</span>;
			},
			width: 190,
			headerAlign: "center",
			renderCell: (cellValues) => {
				return <span>{cellValues.row.IdentificationNumber}</span>;
			},
		},
		{
			field: "Durations",
			width: 150,
			headerAlign: "center",
			renderHeader: () => {
				return <span className={classes.columns}>{"Duración"}</span>;
			},
			renderCell: (cellValues) => {
				return <span>{cellValues.row.Durations}</span>;
			},
		},
		{
			field: "Acciones",
			width: 200,
			headerAlign: "center",
			renderHeader: () => {
				return <span className={classes.columns}>{"Acciones"}</span>;
			},
			renderCell: (cellValues) => {
				return (
						<div>
							<Button
								onClick={() => handleEdit(cellValues.row)}
								color="secondary"
								className={classes.buttonUpdate}
								startIcon={<EditIcon />}

							>
								Editar
							</Button>
							<Button
								onClick={() => deleteColaborator(cellValues.row)}
								color="secondary"
								className={classes.buttonUpdate}
								startIcon={<DeleteOutlineIcon />}

							>
								Eliminar
							</Button>
						</div>
				);
			},
		},
	];

	return (
		<div className={classes.container}>
			<DataGrid
				rows={rowsThirdList}
				columns={columns}
				pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[25, 50, 100]}
                pagination
				disableColumnSelector
				disableColumnMenu
				disableSelectionOnClick
				autoHeight
				getRowId={(row) => row.Id}
				onSelectionModelChange={(rows) => {
					handleChangeRowsSelect(rows);
				}}
				isRowSelectable={(e) => { }}
				components={{
					NoRowsOverlay: () => (
						<span className={classes.notFoundText}>
							No se han encontrado registros
						</span>
					),
				}}
				localeText={esES.props.MuiDataGrid.localeText}
			/>
		</div>
	);
}
