import Box from "@material-ui/core/Box"
import PasswordChecklist from "react-password-checklist";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import styleGlobal from "../../../global/styleGlobal";
import { Advice } from "../../../components/Advice/Advice";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";


export const AccountForm = (props) => {
    const classesGlobal = styleGlobal();
    const {
        dataForm,
        handleChange,
        showPassword, 
        isValidCreate,
        handleCreate
    } = props;

    return (
        <Box sx={{ mt: 3 }}>
            <Typography component="h1" variant="h5" style={{ marginBottom: 35 }} className={classesGlobal.title}>
                <b>Ingrese sus datos para crear una cuenta</b>
            </Typography>
            <p className={classesGlobal.infoText}>
                <b>{dataForm?.email}</b>
                <Button variant="text" className={classesGlobal.buttonText} style={{ textTransform: "none" }} onClick={props.returnValidEmail}>
                    <b>Cancelar</b>
                </Button>
            </p>
            <label className={classesGlobal.label}>Nombre * </label>
            <TextField
                variant="outlined"
                required
                fullWidth
                value={dataForm?.name ?? ""}
                id="name"
                name="name"
                autoComplete="name"
                onChange={handleChange}
                autocomplete="off"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Advice title={""} text={"El nombre debe tener una longitud de entre 3 a 30 caracteres."}></Advice>
                        </InputAdornment>
                    ),
                }}
            />
            <br />
            <label className={classesGlobal.label} style={{ marginTop: 15 }}>Apellido * </label>
            <TextField
                variant="outlined"
                fullWidth
                value={dataForm?.lastname ?? ""}
                id="lastname"
                name="lastname"
                autocomplete="off"
                autoComplete="lastname"
                onChange={handleChange}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Advice tittle={""} text={"Los apellidos deben tener una longitud de entre 3 a 30 caracteres."}></Advice>
                        </InputAdornment>
                    ),
                }}
            />
            <br />
            <label className={classesGlobal.label}>Correo electrónico</label>
            <TextField
                required
                fullWidth
                id="email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={props.handleChange}
                variant="outlined"
                value={props.dataForm?.email ?? ""}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" tabindex="-1">
                            <Advice
                                tabindex="-1"
                                title={""}
                                text={"Ingrese un correo electrónico válido ."}
                            ></Advice>
                        </InputAdornment>
                    ),
                }}
            />
            <br/>
            <label className={classesGlobal.label} style={{ marginTop: 15 }}>Contraseña * </label>
            <OutlinedInput
                type={showPassword ? "text" : "password"}
                value={dataForm?.pp ?? ""}
                fullWidth
                id="pp"
                name="pp"
                onChange={handleChange}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => { if (props.handleTogglePassword) props.handleTogglePassword() }}
                            onMouseDown={() => { if (props.handleTogglePassword) props.handleTogglePassword() }}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            <p className={classesGlobal.infoText} style={{ marginTop: 15 }}>La contraseña debe contener:</p>
            <PasswordChecklist
                className={classesGlobal.infoText}
                rules={["maxLength", "minLength", "capital", "lowercase", "specialChar", "number"]}
                minLength={8}
                maxLength={100}
                value={dataForm?.pp ?? ""}
                onChange={(isValid) => {
                    const event = {
                        target: {
                            name: 'passwordValid',
                            value: isValid
                        }
                    }
                    handleChange(event)
                }}
                messages={{
                    minLength: "Al menos 8 caracteres",
                    maxLength: "Un máximo de 100 caracteres",
                    specialChar: "Al menos un carácter especial",
                    capital: "Al menos una letra mayúscula",
                    lowercase: "Al menos una letra minúscula",
                    number: "Al menos un número.",
                }}
            />
            <br />
            <FormControlLabel
                control={
                    <Checkbox inputProps={{ "aria-label": "controlled" }} />
                }
                label={
                    <>
                        Acepto el <a href={"https://www.walmartcentroamerica.com/media-library/document/aviso-de-privacidad-proveedores-centroamerica/_proxyDocument?id=0000015a-23f5-df1b-a97b-73f797dc0000"} target="_blank" rel="noopener noreferrer">Aviso de Privacidad</a> y los
                        <a href={"https://www.walmartcentroamerica.com/media-library/document/manual-visado-electronic-visa/_proxyDocument?id=00000175-be8e-d68b-adf5-beae72070000"} target="_blank" rel="noopener noreferrer">Términos y Condiciones</a>.
                    </>
                }

            />
            <Button className={classesGlobal.button}  fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} style={{ textTransform: "none" }} disabled={!isValidCreate()} onClick={handleCreate}  >
              Crear cuenta
            </Button>

        </Box>
    )
}