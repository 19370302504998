import React from 'react';
import spark from './../../assets/imagen/Spark.gif'

//Assets
import './loading.css';
import {useSelector} from 'react-redux';

export default function LoadingModal() {
	const loadingReducer = useSelector(({loadingReducer}) => loadingReducer);

    return (
        <div>
              {
                    loadingReducer.loading &&
                    <div className="loading-modal">
                        <img src={spark}  className='spark'/>
                    </div>
                }
        </div>
    )
}

