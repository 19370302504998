


export const VALID_VENDORS = ["Sin Registro", "Cancelado"];

export const EXPIRE_TIME = 240 * 60000; // MIN * 60000

export const EXPIRE_DAYS = 56; // Registros de Empresa

export const VALID_EDIT_VISA = ["Sin Asignar", "Recibido", "Rechazado", "Borrador"]

export const IGNORE_STATUS = ["En tramite", "Revisado"]

export const INVALID_EDIT_STATUS = ["Aprobado", "Cancelado"]

export const INVALID_DETAIL_VISA = ['Cancelado']

export const VALID_VISADO_REQUEST = ['Aprobado']

export const extraCompanyFileNicaragua =
    [
        {
            name: "Licencia de higiene y seguridad del trabajo",
            nameInput: "licenciaHigieneSeguridadtrabajo",
            nameHiddenInput: "licenciaHigieneSeguridadtrabajoHidden",
            key: 5,
        }
    ]

export const companyFiles = [
    {
        name: "Nombramiento de representante legal o personería jurídica",
        nameInput: "nombramientoRepresentanteLegal",
        nameHiddenInput: "nombramientoRepresentanteLegalHidden",
        key: 1,
    },
    {
        name: "Declaración jurada emitida ante un abogado",
        nameInput: "personariaJuridica",
        nameHiddenInput: "personariaJuridicaHidden",
        key: 2,
    },
    {
        name: "Documento de identificación personal o cédula de RL",
        nameInput: "documentoIndentificacion",
        nameHiddenInput: "documentoIndentificacion Hidden",
        key: 3,
    },
    {
        name: "Anexo 1 información de empresa proveedora",
        nameInput: "anexoDientification",
        nameSendForm: "Anexo 1",
        nameHiddenInput: "	",
        key: 4,
    },
];



export const MAX_ITERATIONS = 1000000;
