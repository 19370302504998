import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	root: {},
	bannerPrivacity: {
        background: "#0072ce !important",
		color: "white !important",
		position: "fixed",
		textAlign: "center",
		left: "0",
		margin: "0 auto",
		right: "0",
		bottom: "0",
		maxWidth: "100%",
	},
	link: {
		textDecoration: "underline",
		marginLeft: "5px",
        color: "#fff !important"
	},
}));

export default function Disclamer() {
    const classes = useStyles();
	return (
		<div className={classes.bannerPrivacity}>
			Al ingresar a esta sección y suministrar sus datos personales e
			información usted reconoce y declara que ha leído y acepta, en todas y
			cada una de sus partes y términos, el aviso de privacidad que se puede
			revisar y leer a través del siguiente link:
			<Link to="/privacidad" className={classes.link}>Leer privacidad</Link>
		</div>
	);
}
