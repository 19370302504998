
import { environment } from "../config/config";
import { axiosWithToken } from "../config/axiosConfig";
export const getVisaDangerZonesSaved = (visaAplication) => {
    return new Promise((resolve, reject) => {
        axiosWithToken.get(`${environment.baseUrl}/VisaApplicationDangerType/api/GetByApplicationID/${visaAplication}`).then(result => {
            resolve(result.data.Results);
        }).catch(err => {
            reject(err);
        });
    });
}

export const saveVisaAplicationDangerZones = (body) => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data'
        }
    }
    return new Promise((resolve, reject) => {
        axiosWithToken.post(`${environment.baseUrl}/VisaApplicationDangerType/api/VisaApplicationDangerType`, body, config)
            .then(result => {
                resolve(result.data.Results)
            })
            .catch(err => {
                reject(err)
            });
    });
}

export const updateVisaAplicationDangerZones = (body) => {

    return new Promise((resolve, reject) => {
        axiosWithToken.put(`${environment.baseUrl}/VisaApplicationDangerType/api/Update`, body)
            .then(result => {
                resolve(result.data.Results)
            })
            .catch(err => {
                reject(err)
            });
    });
}
