import { environment } from "../config/config";
import { axiosWithToken } from "../config/axiosConfig";
export const getCompanyRequestList = async (userEmail, countryId) => {
    const userEncript = window.btoa(userEmail);
    const res = await axiosWithToken.get(
        `${environment.baseUrl}/RegistrationCompanyRequest/api/RegistrationCompanyRequestbyEmail/${userEncript}?CountrieId=${countryId}`
    );
    if (res.data && res.data.Results) {
        return res.data.Results;
    }
    return [];
};

export const getCompanyRegisterISD = async (userEmail, vendorId) => {
    const emailEncript = window.btoa(userEmail);
    const res = await axiosWithToken.get(
        `${environment.baseUrl}/VisaThirdParty/RegistrationCompanyByVendorId/${emailEncript}/${vendorId}`
    );
    if (res.data && res.data.Results) {
        return res.data.Results;
    }
    return [];
};

export const getVendorValidator = async (userEmail, vendorId) => {
    const userEncript = window.btoa(userEmail);
    const res = await axiosWithToken.get(
        `${environment.baseUrl}/VisaThirdParty/RegistrationCompanyStatus/${userEncript}/${vendorId}`
    );
    if (res.data && res.data.Results) {
        return res.data.Results;
    }
    return [];
};

export const getCompanyDashboardData = async (userEmail, countryId) => {
    const userEncript = window.btoa(userEmail);
    const res = await axiosWithToken.get(
        `${environment.baseUrl}/Dashboard/VisaApplication/${userEncript}?CountrieId=${countryId}`
    );
    if (res.data && res.data.Results) {
        return res.data.Results;
    }
    return [];
};

export const getVisaThirdInfoByEnail = async (userEmail) => {
    const userEncript = window.btoa(userEmail);
    const res = await axiosWithToken.get(`${environment.baseUrl}/VisaThirdParty/VisaThirdPartyUserByEmail/${userEncript}`);
    if (res.data && res.data.Results) {
        return res.data.Results;
    }
    return [];
}

export const createThirdCompany = async (data) => {
    const config = {
        headers: {
            "Content-type": "multipart/form-data",
        },
    };
    return new Promise((resolve, reject) => {
        axiosWithToken
            .post(
                `${environment.baseUrl}/VisaThirdParty/AddRegistrationCompany`,
                data,
                config
            )
            .then((result) => {
                resolve(result.data.Results);
            })
            .catch((err) => {
                reject(err);
            });
    });
};


export const getRegistrationCompanyById = async (id) => {
    const res = await axiosWithToken.get(`${environment.baseUrl}/VisaThirdParty/RegistrationCompanyById/${id}`);
    if (res.data && res.data.Results) {
        return res.data.Results;
    }
    return null;
}

export const getRegistrationCompanyCommentariesById = async (id) => {
    const res = await axiosWithToken.get(`${environment.baseUrl}/RegistrationCompanyRequest/CommentariesByCompanyId/${id}`);
    if (res.data && res.data.Results) {
        return res.data.Results;
    }
    return [];
}

export const AddCommentary = async (data) => {
    return new Promise((resolve, reject) => {
        axiosWithToken.post(`${environment.baseUrl}/RegistrationCompanyRequest/AddCommentary`, data)
            .then(result => {
                resolve(result.data.Results)
            })
            .catch(err => {
                reject(err)
            });
    });
}

export const UpdateThirdCompany = async (data) => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data'
        }
    }
    return new Promise((resolve, reject) => {
        axiosWithToken.put(`${environment.baseUrl}/VisaThirdParty/UpdateRegistrationCompany`, data, config)
            .then(result => {
                resolve(result.data.Results)
            })
            .catch(err => {
                reject(err)
            });
    });
}

export const ReSendRegistrationCompanyRequest = async (data) => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data'
        }
    }
    return new Promise((resolve, reject) => {
        axiosWithToken.put(`${environment.baseUrl}/RegistrationCompanyRequest/ReSend`, data, config)
            .then(result => {
                resolve(result.data.Results)
            })
            .catch(err => {
                reject(err)
            });
    });
}


export const DeleteRegistrationCompanyCommentaries = async (Id) => {
    return new Promise((resolve, reject) => {
        axiosWithToken.delete(`${environment.baseUrl}/RegistrationCompanyRequest/DeleteCommentaries/${Id}`)
            .then(result => {
                resolve(result.data.Results)
            })
            .catch(err => {
                reject(err)
            });
    });
}

export const getRegistrationCompanyApproved = async (email,countrieId) => {
    const userEncript = window.btoa(email);
    const res = await axiosWithToken.get(`${environment.baseUrl}/RegistrationCompanyRequest/api/RegistrationCompanyRequestApproved/${userEncript}?countrieId=${countrieId}`);
    if (res.data && res.data.Results) {
        return res.data.Results;
    }
    return [];
}
