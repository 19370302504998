import React from "react";
import { makeStyles } from "@material-ui/core/styles";
//form components

//Icons
import AddCommentIcon from '@material-ui/icons/AddComment';

import Button from "@material-ui/core/Button";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { TextValidator } from "react-material-ui-form-validator";
import {
  EMAIL_INPUT_MAX_MESSAGE_TEXTAREA,
  NORMAL_INPUT_MAX_TEXTAREA
} from "../../utils/input-rules";
import { formatDateTime } from "../../utils/helper";

const commentsStyles = makeStyles(theme => ({
  title: {
    fontSize: 21,
    color: theme.palette.primary.main,
    textAlign: "center"
  },
  containerList: {
    height: "20rem",
    border: "1px solid #f5f4f4",
    overflow: "scroll",
    padding: "1rem",
    borderRadius: "5px"
  },
  commentContainer: {
    backgroundColor: "#f4f4f5",
    padding: 10,
    border: "1px solid #dcdbdb",
    borderRadius: 5,
    marginTop: 20
  },
  commentName: {
    fontSize: 15,
    fontWeight: "bold",
    margin: 0
  },
  dateContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  dateText: {
    fontSize: 13,
    color: "#807d7d"
  },
  commentText: {
    fontSize: 15,
    color: "#807d7d",
    margin: 0
  },
  commentInputContainer: {
    marginTop: 40
  },
  commentInputTitle: {
    fontSize: 14,
    margin: 0,
    fontWeight: "bold"
  },
  commentInput: {
    width: "100%"
  },
  commentButtons: {
    marginTop: 20,
    display: "flex",
    justifyContent: "space-between"
  },
  commentButtonsNew: {
    display: "flex",
    justifyContent: "space-between"
  },
  commentaryButton: {
    textTransform: "none !important",
    color: theme.palette.primary.main,
  }
}));

export default function CommentsForm({
  commentaryList,
  dataForm,
  handleChangeDataForm,
  editMode,
  showBox,
  createCommentary,
  showCommentaries,
  update
}) {
  const classes = commentsStyles();

  const handleChange = event => {
    handleChangeDataForm(event.target.name, event.target.value);
  };

  // New function to handle the comment origin
  const getCommentOrigin = (origin) => {
    if (origin === "Externo") {
      return "Socio Comercial :";
    } else if (origin === "Tercero") {
      return "Empresa Tercera : ";
    } else {
      return "Asignado a:";
    }
  };

  return (
    <div>
{showCommentaries && (
  <div>
    <h3 className={classes.title}>Comentarios</h3>
    <div className={classes.containerList}>
      {commentaryList.map((comment) => (
        // For reference: Walmart.VisadoSeguridad.Api.Repository.Entities.VisaApplicationCommentary.Id
        <div className={classes.commentContainer} key={comment.Id}>
          <p className={classes.commentName}>
            {getCommentOrigin(comment.Origin)}{" "}
            {comment.UserName}
          </p>
          <div className={classes.dateContainer}>
            <p className={classes.dateText}>
              Fecha: {formatDateTime(comment.CreationDate)}
            </p>
          </div>
          <p className={classes.commentText}>{comment.Commentary}</p>
        </div>
      ))}
    </div>
  </div>
)}
      {showBox &&
        <div className={classes.commentInputContainer}>
          <p className={classes.commentInputTitle}>Comentarios</p>
          <TextareaAutosize
            className={classes.commentInput}
            value={dataForm.commentary}
            onChange={handleChange}
            name="commentary"
            aria-label="minimum height"
            minRows={5}
            placeholder="Escribe aqui tus comentarios"
          />
          <TextValidator
            value={dataForm.commentary}
            name="commentaryTwo"
            id="standard-basic"
            type="hidden"
            validators={update ? [] : NORMAL_INPUT_MAX_TEXTAREA}
            errorMessages={EMAIL_INPUT_MAX_MESSAGE_TEXTAREA}
          />
        </div>
      }
      {editMode &&
        <div className={classes.commentButtonsNew}>
          {/* <Button color="secondary">Eliminar</Button> */}
          <Button
            color="secondary"
            size="small"
            startIcon={<AddCommentIcon />}
            onClick={createCommentary}
            className={classes.commentaryButton}>
            Agregar comentario
          </Button>
        </div>
      }

    </div>
  );
}
