import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	container: {
		padding: 20,
		display: "flex",
		alignItems: "center",
		backgroundColor: "#FFC220",
		borderRadius: 5,
	},
	text: {
		margin: 0,
		marginLeft: 10,
	},
	buttonSave: {
		textTransform: "none !important"
	}
}));

export function AlertMessage({
	message = "Mantener esta información actualizada, evitará retrasos en sus solicitudes de visados y registro de empresa",
	clickPage = false,
}) {
	const classes = useStyles();
	const history = useHistory();

	const redirectPage = () =>  {
		history.push("/profile");
	}
	return (
		<div className={classes.container}>
			<div className={classes.iconContainer}>
				<ReportProblemOutlinedIcon />
			</div>
			<p className={classes.text}>
				{message}
				{clickPage && (
					<Button
						color="secondary"
						onClick={redirectPage}
						className={classes.buttonSave}
					>
						dar click aquí
					</Button>
				)}
			</p>
		</div>
	);
}
