

import { environment } from '../config/config';
import { axiosWithToken } from '../config/axiosConfig';
const config = {
    responseType: 'blob',
    headers: {
        'Content-type': 'multipart/form-data'
    },
};

export const getFile = (data) => {

    return new Promise((resolve, reject) => {
        axiosWithToken.post(`${environment.baseUrl}/auth/filevalidate`, data, config)
            .then(result => {
                const data = result.data;
                resolve(data);
            })
            .catch(err => {
                reject(err)
            });
    });
}
export const getColaboratorFiles = async (VisaRequestMemberId) => {
    const res = await axiosWithToken.get(`${environment.baseUrl}/File/api/GetFileByCollaborator/${VisaRequestMemberId}`);
    if (res.data && res.data.Results) {
        return res.data.Results;
    }
    return [];
}

export const createVisaFiles = ( visaApplicationId, data ) => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data'
        }
    }
    return new Promise((resolve, reject) => {
        axiosWithToken.post(`${environment.baseUrl}/VisaApplication/${visaApplicationId}/addFiles`, data, config)
            .then(result => {
                resolve({...result.data.Results , _fileData : data})
            })
            .catch(err => {
                reject(err)
            });
    });
}

export const creatVisaMemberFiles = ( visaApplicationId, ced, data ) => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data'
        }
    }
    return new Promise((resolve, reject) => {
        axiosWithToken.post(`${environment.baseUrl}/VisaRequestMembers/File/${visaApplicationId}/${ced}`, data, config)
            .then(result => {
                resolve({...result.data.Results , _fileData : data})
            })
            .catch(err => {
                reject(err)
            });
    });
}

export const updateVisaMemberFiles = ( visaApplicationId, data ) => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data'
        }
    }
    return new Promise((resolve, reject) => {
        axiosWithToken.put(`${environment.baseUrl}/VisaRequestMembers/${visaApplicationId}/updateFiles`, data, config)
            .then(result => {
                resolve({...result.data.Results , _fileData : data})
            })
            .catch(err => {
                reject(err)
            });
    });
}


export const getVisaAplicationFilesById = async (visaAplicationId) => {
	const res = await axiosWithToken.get(
		`${environment.baseUrl}/File/api/GetFileByVisaRequest/${visaAplicationId}`
	);
	if (res.data && res.data.Results) {
		return res.data.Results;
	}
	return [];
};

export const getVisaMemberFilesById = async (memberId) => {
	const res = await axiosWithToken.get(
		`${environment.baseUrl}/File/api/GetFileByCollaborator/${memberId}`
	);
	if (res.data && res.data.Results) {
		return res.data.Results;
	}
	return [];
};


export const getVisaCollabRequirementes = async (visaTypeId) => {

    const res = await axiosWithToken.get(`${environment.baseUrl}/RequirementByVisaType/${visaTypeId}?screenTypeId=2`);
    if (res.data && res.data.Results) {
        return res.data.Results;
    }
    return [];
}
