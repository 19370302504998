import  Typography  from "@material-ui/core/Typography";
import Tooltip  from "@material-ui/core/Tooltip"
import * as React from "react";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { useState } from "react";
export const Advice = (
    {
        text, 
        title,
        ...props
    }
) => {
    const [open, setOpen] = useState(false);
    return (
        <div  
            style={{
            backgroundColor: "#f5f5f9",
            color: "rgba(0, 0, 0, 0.87)",
            maxWidth: 220,
            border: "1px solid #dadde9",
        }}>
        <Tooltip
            enterTouchDelay={0}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onClick={() => setOpen(!open)}
            title={
                <React.Fragment>
                    {title && <Typography color="inherit">{title}</Typography>}
                    {text}
                </React.Fragment>
            }
        >
            <ErrorOutlineIcon />
        </Tooltip>
        </div>
    )
}