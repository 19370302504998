import React from "react";
//UI
import {
    InputLabel,
    MenuItem,
    TextField,
    Select,
} from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import es from "date-fns/locale/es"
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    selectInput: {
        minWidth: '90%',
        padding: 0,
    },
    inputLabel: {
        fontSize: 13,
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    inputNumeric: {
        minWidth: '90%',
        padding: 0,
        marginTop: 20
    },
}));

const Filter = (props) => {

    const {
        labelName,
        labelText,
        variant,
        value,
        handleChange,
        onKeyPress,
        variantFilter,
        data,
        size
    } = props

    const classes = useStyles();

    const handleChangeDate = (event) => {
        handleChange(labelName,event)
    }

    return (
        <div>
            {variantFilter === 'select' &&
                <div>
                    <InputLabel htmlFor="select" className={classes.inputLabel}>{labelText}</InputLabel>
                    <Select
                        defaultValue=""
                        labelId="filterSelected"
                        name={labelName}
                        onChange={handleChange}
                        label={labelName}
                        variant={variant}
                        data-testid={labelName}
                        className={classes.selectInput}
                        value={value}
                    >
                        {data?.map((item) => (
                            <MenuItem key={item.Id} value={item.Id}>
                                {item.Name}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            }
            {variantFilter === 'inputNumeric' &&
                <TextField
                    type={"number"}
                    label={labelText}
                    name={labelName}
                    data-testid={labelName}
                    variant={variant}
                    size={size}
                    onKeyPress={onKeyPress}
                    className={classes.inputNumeric}
                />
            }
            {variantFilter === 'inputString' &&
                <TextField
                    type={"search"}
                    label={labelText}
                    name={labelName}
                    data-testid={labelName}
                    variant={variant}
                    size={size}
                    onKeyPress={onKeyPress}
                    className={classes.inputNumeric}
                />
            }
            {variantFilter === 'datePicker' &&
                <div>
                    <InputLabel id="createDate" className={classes.labelInput}>
                        {labelText}
                    </InputLabel>
                    <MuiPickersUtilsProvider locale={es}  utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            data-testid={labelName}
                            variant="inline"
                            format="dd/MM/yyyy"
                            id="date-picker-from"
                            placeholder="Seleccionar Fecha"
                            name={labelName}
                            helperText=""
                            value={value}
                            autoOk={true}
                            onChange={handleChangeDate}
                            KeyboardButtonProps={{
                                "aria-label": "change date"
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            }
        </div>

    )

}


export default Filter


