import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import styleGlobal from "../../../global/styleGlobal";
import { Advice } from "../../../components/Advice/Advice";


export const OtpForm = (props) => {
    const classesGlobal = styleGlobal();

    const {
        dataForm,
        handleChange,
        handleSubmit,
        handleGenerateOTP
    } = props
    return (<Box component="form" noValidate sx={{ mt: 3 }} >
        <Typography component="h1" variant="h5" style={{ marginBottom: 35 }} className={classesGlobal.title}>
            <b>Bienvenido (a)</b>
        </Typography>
        <p className={classesGlobal.infoText}>
            <b>{dataForm?.email}</b>
            <Button variant="text" className={classesGlobal.buttonText} style={{ textTransform: "none" }} onClick={props.returnValidEmail}>
                <b>Cambiar</b>
            </Button>
        </p>
        <p className={classesGlobal.infoText}>
            Ingrese el código de verificación enviado a: {dataForm?.email}.
        </p>
        <br></br>
        <label className={classesGlobal.label}>Código de verificación</label>
        <TextField
            variant="outlined"
            required
            fullWidth
            value={dataForm?.otp ?? ""}
            id="otp"
            name="otp"
            autoComplete="otp"
            onChange={handleChange}
            autocomplete="off"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <Advice title={""} text={"Ingrese el código que se le envio a su correo el cual debe de tener un máximo de 10 caracteres."}></Advice>
                    </InputAdornment>
                ),
            }}
        />
        <div style={{ textAlign: "right", width: "100%" }}>
            <Button variant="text" style={{ textTransform: "none" }} className={classesGlobal.buttonText} onClick={handleGenerateOTP} >
                <b>Reenviar codigo</b>
            </Button>
        </div>
        <Button className={classesGlobal.button} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} style={{ textTransform: "none", marginTop: 10, marginBottom: 5 }} disabled={!dataForm?.otp} onClick={handleSubmit}  >
            Iniciar sesión
        </Button>
    </Box>)
}