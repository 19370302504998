import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
    showLoadingAction,
    hideLoadingAction,
} from "../../redux/actions/loading.actions";
import { setTitlePageAction } from "../../redux/actions/title.actions";
import { ValidatorForm } from "react-material-ui-form-validator";
import { showAlertAction } from "../../redux/actions/alert.actions";

//Services
import {
    getDangerTypesByVisadoType,
    getVisaAplicationByRequNumber,
    getVisaReguirimentsFile,
} from "../../services/visados.service";
import {
    createCommentaryByVisaAplication,
    getCommentsByVisaAplicationId,
} from "../../services/comments.service";
import {
    getVisaAplicationFilesById,
    getFile
} from "../../services/files.service";
import {
    getVisaDangerZonesSaved,
} from "../../services/dangerZones.service";
import { getCompanyRegisterISD } from "../../services/company.service";
//Style
import { useStyles } from "./useStyle";
//Components
import { VisadoInfo } from "./components/VisadoInfo";


//constants
import { INVALID_DETAIL_VISA, extraCompanyFileNicaragua, companyFiles } from '../../utils/constants'
import { getMaxLength } from "../../utils/helper";


export default function VisadoRequestDetail({ auth }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const urlParams = useParams();
    const classes = useStyles();

    const [userInfo, setUserInfo] = useState({})
    const [visaApplication, setVisaApplication] = useState({})
    const [registerISD, setRegisterISD] = useState({})
    const [visaFiles, setVisaFiles] = useState([])
    const [requirementsFiles, setRequirementsFiles] = useState([]);
    const [errorsForm, setErorrsForm] = useState({});
    const [dataForm, setDataForm] = useState({
        commentary: ""
    });
    const [dangerTypes, setDangerTypes] = useState([]);
    const [listCommentaries, setListCommentaries] = useState([]);



    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
        });
        initPage()
    }, [])

    const initPage = async () => {

        dispatch(setTitlePageAction({ text: "Solicitud de visado" }));
        dispatch(showLoadingAction());

        const user = auth.getUserInfo();
        setUserInfo(user);
        await getVisaApplicationInfo(urlParams.visaid, user.email)
        dispatch(hideLoadingAction());
    }

    const handleChangeDataForm = (name, state) => {
        setDataForm({ ...dataForm, [name]: state });
        if (errorsForm[name]) {
            const errors = { ...errorsForm, [name]: null };
            setErorrsForm(errors);
        }
    };

    const getVisaApplicationInfo = async (requestId, email) => {
        try {
            const res = await getVisaAplicationByRequNumber(requestId);
            if (INVALID_DETAIL_VISA.includes(res.Status)) {
                history.push(`/principal`);
                return
            }
            setVisaApplication(res)
            const registerIsd = await getCompanyRegisterISD(email, res.VendorId);
            setRegisterISD(registerIsd)
            await getVisaRequestFiles(res.ApplicationId)
            await getRequirementsFiles(res.VisaTypeId, res.Country, res.VisaType)
            const dangerTypes = await getDangerTypesByVisadoType(res.VisaTypeId);
            setDangerTypes(dangerTypes);
            await getDanverZonesSaved(dangerTypes, res.ApplicationId);
            await getCommentariesList(res.ApplicationId)
        }
        catch (err) {
            console.log(err)
        }

    }

    const getVisaRequestFiles = async (visaAplicationId) => {
        try {
            const res = await getVisaAplicationFilesById(visaAplicationId);
            if (Array.isArray(res) && res.length > 0) {
                const files = {};
                res.forEach((e) => {
                    files[e.NameRequirement.trim()] = e;
                });
                setVisaFiles(files)
            }
        } catch (err) {
            console.log("err files list", err);
        }
    };

    const downloadMethod = async (file) => {
        const fileName = file.RouteRequirement.split("/").pop();

        dispatch(showLoadingAction());
        try {
            const formDataS = new FormData();
            formDataS.append("fileName", fileName);

            const res = await getFile(formDataS);
            let blob = new Blob([res], { type: "application/octet-stream" });
            let link = document.createElement("A");
            link.href = URL.createObjectURL(blob); // SET LINK ELEMENTS CONTENTS
            link.setAttribute("download", fileName); // SET ELEMENT CREATED 'ATTRIBUTE' TO DOWNLOAD, FILENAME PARAM AUTOMATICALLY
            link.click(); // TRIGGER ELEMENT TO DOWNLOAD
            link.remove();
            dispatch(hideLoadingAction());
        } catch (error) {
            dispatch(hideLoadingAction());
        }
    };

    const getRequirementsFiles = async (visaTypeId, country, visaType) => {
        try {
            let res = []
            if (visaType.includes("Anual")) {
                const files = companyFiles.map(e => {
                    return e
                })
                if (country.includes("Nicaragua")) {
                    if(files.find(x => x.name === extraCompanyFileNicaragua[0].name) === undefined) {
                        files.push(extraCompanyFileNicaragua[0])
                    }
                }
                setRequirementsFiles(files)

            } else if (visaType.includes("Temporal")) {
                res = await getVisaReguirimentsFile(visaTypeId);
                const requr = res.map((e) => {
                    return {
                        ...e,
                        name: e.RequirementName,
                        nameInput: e.RequirementName,
                        nameHiddenInput: e.RequirementName + "hidden",
                        key: e.Id,
                    };
                });

                setRequirementsFiles(requr);
            }


        } catch (err) {
            console.log("err ", err);
        }
    };

    const getDanverZonesSaved = async (dangerList, visaAplicationId) => {
        try {
            const dangerZonesTypesCopy = [...dangerList];
            const res = await getVisaDangerZonesSaved(visaAplicationId);
            if (res && res.length > 0) {
                for (let i = 0; i < getMaxLength(dangerZonesTypesCopy.length); i++) {
                    switch (dangerZonesTypesCopy[i].VisaDangerTypeID) {
                        case 1: {
                            dangerZonesTypesCopy[i].selected = res[0].DangerType1 > 0;
                            break;
                        }
                        case 2: {
                            dangerZonesTypesCopy[i].selected = res[0].DangerType2 > 0;
                            break;
                        }
                        case 3: {
                            dangerZonesTypesCopy[i].selected = res[0].DangerType3 > 0;
                            break;
                        }
                        case 4: {
                            dangerZonesTypesCopy[i].selected = res[0].DangerType4 > 0;
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                }
            }
            setDangerTypes(dangerZonesTypesCopy);
            existDangerZoneSelected();
        } catch (err) { }
    };

    const existDangerZoneSelected = () => {
        let exist = "";
        for (let i = 0; i < getMaxLength(dangerTypes.length); i++) {
            if (dangerTypes[i].selected) {
                exist = true;
            }
        }
        setDataForm((oldState) => ({ ...oldState, dangerZone: exist }));
    };

    const getCommentariesList = async (visaAplicationId) => {
        try {
            const res = await getCommentsByVisaAplicationId(visaAplicationId);
            setListCommentaries(res);
        } catch (err) {
            console.log("err comentarios list", err);
        }
    };

    const createCommentary = async () => {
        if (!dataForm.commentary) {
            dispatch(
                showAlertAction({
                    show: true,
                    variant: "warning",
                    message: "Agregue un comentario",
                })
            );
            return;
        }
        if (dataForm.commentary.length > 125) {
            dispatch(
                showAlertAction({
                    show: true,
                    variant: "warning",
                    message: "El comentario no debe exceder el tamaño de 125 caractres.",
                })
            );
            return;
        }
        try {
            dispatch(showLoadingAction());
            const body = {
                VisaApplicationId: visaApplication.ApplicationId,
                Commentary: dataForm.commentary,
                UserId: userInfo.email,
                UserName: registerISD.BusinessName,
                CreatedBy: userInfo.email,
                Origin: "Tercero",
            };
            await createCommentaryByVisaAplication(body);
            setDataForm((oldState) => ({ ...oldState, commentary: "" }));
            dispatch(
                showAlertAction({
                    show: true,
                    variant: "success",
                    message: "Comentario agregado correctamente.",
                })
            );
            await getCommentariesList(visaApplication.ApplicationId);
            dispatch(hideLoadingAction());
        } catch (err) {
            console.log("err", err);
            dispatch(hideLoadingAction());
        }
    };

    const handleRedirectColaborator = ( ) => {
        history.push(`/colaborator-detail/${urlParams.visaid}`)
    }

    const handleRedirectList= ( ) => {
        history.push(`/visa-list?country=${visaApplication.CountryId}&visaType=solicitudes`)
    }

    return (
        <div>
            <ValidatorForm
                className={classes.root}
                autoComplete="off"
                onSubmit={() => {}}
                onError={()=>{}}
            >
                <VisadoInfo
                    data={visaApplication}
                    dangerTypes={dangerTypes}
                    classes={classes}
                    registerISD={registerISD}
                    visaFiles={visaFiles}
                    requirementsFiles={requirementsFiles}
                    errorsForm={errorsForm}
                    handleChangeDataForm={handleChangeDataForm}
                    dataForm={dataForm}
                    downloadMethod={downloadMethod}
                    listCommentaries={listCommentaries ? listCommentaries : []}
                    createCommentary={createCommentary}
                    handleRedirectColaborator={handleRedirectColaborator}
                    handleRedirectList={handleRedirectList}
                />
            </ValidatorForm>
        </div>
    )
}
