import { TITLE_ACTIONS } from "../actionsTypes/title.types";

const INITIAL_STATE = {
	text: "Inicio",
};

const alertReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case TITLE_ACTIONS.SET_TITLE: {
			return { ...state, ...action.payload };
		}

		default:
			return state;
	}
};

export default alertReducer;
