import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: "20px 30px",
	},
	item: {
		marginTop: 20
	},
	labelInput: {
		color: theme.palette.primary.main,
		fontSize: '1.3rem',
		fontWeight: 'bold'

	},
	labelInputCheckbox: {
		width: '100%'
	},
	dangerZoneImage: {
		width: "30px",
		marginLeft: "10px"
	},
	inputCheckbox: {
		marginRight: "10px"
	},
	labelCheckbox: {
		marginTop: "0.5rem",
		color: theme.palette.primary.main,
		fontSize: "1.1rem"
	},
	title: {
		fontSize: 21,
		color: theme.palette.primary.main,
		textAlign: "center",
		marginTop: 30,
		marginBottom: 30
	},
	buttonBack: {
		marginLeft: 50
	}

}));
