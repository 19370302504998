import { makeStyles } from "@material-ui/core/styles";


export default makeStyles((theme) => ({
    toolbar: {
        justifyContent: 'center',
        backgroundColor: '#0071dc',
        padding: '10px'
    },
    logo: {
        maxHeight: '50px'
    }
}));
