import React from "react";
//UI
import {
    Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//Components
import Filter from './Filter'

const useStyles = makeStyles((theme) => ({
    container: {
        marginLeft: 25,
        marginTop: 50,
        width: "97%"
    }

}));

const FilterForm = (props) => {
    const {
        handleChangeFilterSelected,
        filterSelected,
        filterData,
        handleChangeDataFilter,
        handleOnKeyPressDataFilter,
        visadosTypeList,
        dataFilter,
        statusTypeList,
        handleChangeDataDateFilter,
        vendorsList,
        visaDurations
    } = props

    const classes = useStyles();
    return (
        <Grid container className={classes.container} spacing={0}>
            <Grid item xs={12} md={4}>
                <Filter
                    labelText={"Filtrar por"}
                    labelName={"filterSelected"}
                    variant={"outlined"}
                    value={filterSelected}
                    handleChange={handleChangeFilterSelected}
                    data={filterData}
                    variantFilter={'select'}
                />
            </Grid>
            {filterSelected === 'VisaType' &&
                <Grid item xs={12} md={4}>
                    <Filter
                        labelText={"Tipo de Visado"}
                        labelName={"visaTypeSelected"}
                        variant={"outlined"}
                        value={dataFilter.visaTypeSelected}
                        handleChange={handleChangeDataFilter}
                        data={visadosTypeList}
                        variantFilter={'select'}
                    />
                </Grid>
            }
            {filterSelected === 'Status' &&
                <Grid item xs={12} md={4}>
                    <Filter
                        labelText={"Estado"}
                        labelName={"status"}
                        variant={"outlined"}
                        value={dataFilter.status}
                        handleChange={handleChangeDataFilter}
                        data={statusTypeList}
                        variantFilter={'select'}
                    />
                </Grid>
            }
            {filterSelected === 'RequestNumber' &&
                <Grid item xs={12} md={4}>
                    <Filter
                        labelText={"Número de solicitud"}
                        labelName={"requestNumber"}
                        variant={"outlined"}
                        value={dataFilter.requestNumber}
                        onKeyPress={handleOnKeyPressDataFilter}
                        variantFilter={'inputNumeric'}
                        size={"small"}
                    />
                </Grid>
            }
            {filterSelected === 'date' &&
                <Grid item xs={12} md={4}>
                    <Filter
                        labelText={"Fecha de solicitud"}
                        labelName={"date"}
                        variant={"outlined"}
                        value={dataFilter.date}
                        handleChange={handleChangeDataDateFilter}
                        variantFilter={'datePicker'}
                    />
                </Grid>
            }
            {filterSelected === 'Vendor' &&
                <Grid item xs={12} md={4}>
                    <Filter
                        labelText={"Socio Comercial"}
                        labelName={"vendorSelected"}
                        variant={"outlined"}
                        value={dataFilter.vendorSelected}
                        handleChange={handleChangeDataFilter}
                        data={vendorsList}
                        variantFilter={'select'}
                    />
                </Grid>
            }
            {filterSelected === 'name' &&
                <Grid item xs={12} md={4}>
                    <Filter
                        labelText={"Nombre o apellido"}
                        labelName={"name"}
                        variant={"outlined"}
                        value={dataFilter.name}
                        onKeyPress={handleOnKeyPressDataFilter}
                        variantFilter={'inputString'}
                        size={"small"}
                    />
                </Grid>
            }
            {filterSelected === 'ced' &&
                <Grid item xs={12} md={4}>
                    <Filter
                        labelText={"Cédula"}
                        labelName={"ced"}
                        variant={"outlined"}
                        value={dataFilter.ced}
                        onKeyPress={handleOnKeyPressDataFilter}
                        variantFilter={'inputString'}
                        size={"small"}
                    />
                </Grid>
            }
            {filterSelected === 'expirationDate' &&
                <Grid item xs={12} md={4}>
                    <Filter
                        labelText={"Fecha de vencimiento"}
                        labelName={"expirationDate"}
                        variant={"outlined"}
                        value={dataFilter.expirationDate}
                        handleChange={handleChangeDataDateFilter}
                        variantFilter={'datePicker'}
                    />
                </Grid>
            }
            {filterSelected === 'duration' &&
                <Grid item xs={12} md={4}>
                    <Filter
                        labelText={"Duración"}
                        labelName={"duration"}
                        variant={"outlined"}
                        value={dataFilter.duration}
                        handleChange={handleChangeDataFilter}
                        data={visaDurations}
                        variantFilter={'select'}
                    />
                </Grid>
            }
        </Grid>
    )
}

export default FilterForm
