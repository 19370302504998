import moment from 'moment';

export const formatDate = date => {
    if(date) {
        const dateMoment = moment(date).utc();
        return dateMoment.format('DD/MM/YYYY');
    } else {
        return ' - '
    }
}

export const formatDateTime = date => {
    if(date) {
        const dateMoment = moment(date);
        return dateMoment.format('DD/MM/YYYY hh:mm:ss a');
    } else {
        return ' - '
    }
}

export const validateVisaRequest = (vendors, visaRequest) => {
    const vendor = vendors.findIndex(vendor => Number(vendor.vendorId) === Number(visaRequest.VendorHost));
    const creationDate = formatDate(visaRequest.CreationDate);
    const daysLimit = moment().diff(moment(creationDate, "DD/MM/YYYY"), 'days');
    return vendor !== -1 && (visaRequest.Status === 'Borrador' || (visaRequest.Status === 'Rechazado' && daysLimit < 19));
}

export const validateVisaRequestOnlyVendors = (vendors, VendorHost) => {
    const vendor = vendors.findIndex(vendor => Number(vendor.vendorId) === Number(VendorHost));
    return vendor !== -1;
}


export const validateDateBusinessRegister = (register) => {
    const expiration = formatDate(register.Expiration);
    const yearsLimit = moment().diff(moment(expiration, "DD/MM/YYYY"), 'days');
    return yearsLimit > 0;
}
