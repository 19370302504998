import React, { useEffect, useState } from "react";
//form components

import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {
	TextValidator,
	SelectValidator,
	ValidatorForm
} from "react-material-ui-form-validator";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import {
	NORMAL_INPUT,
	NORMAL_INPUT_MESSAGE,
	NORMAL_INPUT_MAX,
	EMAIL_INPUT_MAX_MESSAGE_TEXTAREA,
	NORMAL_INPUT_MAX_TEXTAREA,
} from "../../utils/input-rules";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {
	getUserDetailByEmail,
} from "../../services/profile.service";
//Components
import AlertDialog from "../../components/AlertDialog/AlertDialog";
import FilesForm from "../../components/FilesForm/FilesForm";
import { AlertMessage } from "../../components/AlertMessageForm/AlertMessage";
import CommentsForm from "../../components/CommentsForm/CommentsForm";

import { useDispatch } from "react-redux";
import {
	showLoadingAction,
	hideLoadingAction,
} from "./../../redux/actions/loading.actions";
import { getFile } from "../../services/files.service";
import { getVendorsByEmailAndContrieId } from "../../services/vendors.service";
import {
	getCountriesListByEmail,
} from "../../services/countries.service";
import {
	createThirdCompany,
	getRegistrationCompanyById,
	getRegistrationCompanyCommentariesById,
	AddCommentary,
	UpdateThirdCompany,
	getVendorValidator,
	ReSendRegistrationCompanyRequest,
	DeleteRegistrationCompanyCommentaries
} from "../../services/company.service";
import { useHistory, useParams } from "react-router-dom";

import moment from 'moment';


//Reducers
import { setTitlePageAction } from "../../redux/actions/title.actions";
import { showAlertAction } from "../../redux/actions/alert.actions";

//Utils
import { VALID_VENDORS, EXPIRE_DAYS } from "../../utils/constants";

//constants
import { FILE_PREFIX } from '../../constants/api_proxy.constant'


const filesToUPload = [
	{
		name: "Nombramiento de representante legal o personería jurídica",
		nameInput: "nombramientoRepresentanteLegal",
		nameHiddenInput: "nombramientoRepresentanteLegalHidden",
		key: 1,
	},
	{
		name: "Declaración jurada emitida ante un abogado",
		nameInput: "personariaJuridica",
		nameHiddenInput: "personariaJuridicaHidden",
		key: 2,
	},
];

const filesToUPloadSecond = [
	{
		name: "Documento de identificación personal o cédula de RL",
		nameInput: "documentoIndentificacion",
		nameHiddenInput: "documentoIndentificacion Hidden",
		key: 3,
	},
];

const anexoFiles = [
	{
		name: "Anexo 1 información de empresa proveedora",
		nameInput: "anexoDientification",
		nameSendForm: "Anexo 1",
		nameHiddenInput: "	",
		key: 4,
	},
];

const inputCss = {
	style: {
		padding: 10,
	},
};

const useStyles = makeStyles((theme) => ({
	root: {
		padding: "20px 30px",
	},
	container: {},
	containerCountries: {
		marginBottom: "20px",
	},
	containerPaper: {
		padding: "1rem",
	},
	containerPaperFiles: {
		padding: "1rem 0",
	},
	formControl: {
		minWidth: "90%",
	},
	inputText: {
		minWidth: "90%",
	},
	title: {
		fontSize: 21,
		color: theme.palette.primary.main,
		textAlign: "center",
	},
	titlePage: {
		fontSize: 25,
		color: theme.palette.primary.main,
		textAlign: "center",
		margin: 0,
		marginTop: "2rem",
	},
	containerInput: {
		marginTop: 20,
	},
	labelInput: {
		marginTop: 30,
		color: theme.palette.primary.main,
	},
	selectInput: {
		minWidth: "90%",
		padding: 0,
	},
	inputFile: {
		display: "none",
	},
	buttonAttach: {
		color: theme.palette.secondary.main,
		borderRadius: "0 5px 5px 0",
		boxShadow: "none",
		margin: 0,
		textTransform: "inherit",
	},
	buttonAttachLabel: {
		margin: 0,
	},
	containerButtonAttach: {
		display: "flex",
		alignItems: "center",
		width: "60%",
		flex: 1,
	},
	textButtonAttach: {
		margin: 0,
		backgroundColor: "#e0e0e0",
		fontSize: 14,
		padding: "6px 16px",
		color: "#777777",
		borderRight: "1px solid #b3b3b3",
		lineHeight: "1.75",
		borderRadius: "5px 0 0 5px",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	containerButtonAttachItem: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		borderBottom: "1px solid #e0e0e0",
		marginTop: 20,
		paddingBottom: 20,
		padding: "0 10px",
	},
	containerButtonAttachText: {
		flex: 1,
	},
	textButtonAttachItem: {
		margin: 0,
		color: "#777777",
	},
	textError: {
		color: "#f44336",
		fontSize: ".8rem",
	},
	buttonContainer: {
		marginTop: 40,
		textTransform: "none !important",
	},
	buttonSave: {
		textTransform: "none !important",
	},
	commentInput: {
		width: "100%",
	},
}));

export function CompanyRegister({ auth }) {
	const [dataForm, setDataForm] = useState({
		bussinessName: "",
		legalId: "",
		legalRepresentative: "",
		contactName: "",
		contactPhone: "",
		contactEmail: "",
		createdBy: "",
		nombramientoRepresentanteLegalHidden: "",
		personariaJuridicaHidden: "",
		documentoIndentificacionHidden: "",
		country: "",
		vendor: "",
		commentary: "",
		commentaryTwo: "",
		anexoDientification: "",
	});
	const [openDialogSuccess, setOpenDialogSucess] = useState(false);
	const [validateVendor, setValidateVendor] = useState(false);
	const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
	const [openDialogReSend, setOpenDialogReSend] = useState(false);
	const [countries, setCountries] = useState([]);
	const [, setDataDetail] = useState({});
	const [savedFiles, setSavedFles] = useState({});
	const [vendors, setVendors] = useState([]);
	const [errorsForm, setErorrsForm] = useState({});
	const [filesForm, setFilesForm] = useState([]);
	const [userInfo, setUserInfo] = useState({});
	const [listCommentaries, setListCommentaries] = useState([]);
	//Update Form
	const [registrationInfo, setRegistrationInfo] = useState(null);
	const [updateButton, setUpdateButton] = useState({
		mensaje: "Actualizar",
		dias: 0
	})
	const [openDialogAboutToExpire, setOpenDialogAboutToExpire] = useState(false);

	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();

	const { id } = useParams();

	const diffDays = (date, otherDate) => otherDate.diff(date, 'days') + 1

	const handleChange = async (event) => {
		setDataForm({ ...dataForm, [event.target.name]: event.target.value });
		if (event.target.name === "vendor") {
			venndorValidate(event.target.value);
		}
		else if (event.target.name === "country") {
			await getVendors(userInfo.email, event.target.value)
		}
	};

	const venndorValidate = async (vendorId) => {
		try {
			dispatch(showLoadingAction());

			const res = await getVendorValidator(userInfo.email, vendorId);
			if (!VALID_VENDORS.includes(res)) {
				setValidateVendor(true);
				dispatch(
					showAlertAction({
						show: true,
						variant: "warning",
						message:
							`No se puede crear una nueva solicitud, tienes una solicitud en estado: ${res}.`,
					})
				);
			} else {
				setValidateVendor(false);
			}
			dispatch(hideLoadingAction());
		} catch (err) {
			dispatch(hideLoadingAction());
			console.log("err validate", err);
		}
	};

	useEffect(() => {
		initPage();
	}, []);

	const initPage = async () => {
		dispatch(setTitlePageAction({ text: "Registro de empresa" }));
		dispatch(showLoadingAction());
		const user = auth.getUserInfo();
		setUserInfo(user);
		if (id) {
			await getRegistrationDetail(id);
			dispatch(hideLoadingAction());
		}
		else {
			setRegistrationInfo(null);
			await getDetail(user.email);
			await getCountries(user.email);
			dispatch(hideLoadingAction());
		}

	};

	const getDetail = async (userEmail) => {
		try {
			const res = await getUserDetailByEmail(userEmail);
			console.log(res)
			setDataForm({
				bussinessName: res.BusinessName,
				legalId: res.LegalId,
				legalRepresentative: res.LegalRepresentative,
				contactName: res.ContactName,
				contactPhone: res.ContactPhone,
				contactEmail: res.ContactEmail,
				createdBy: userInfo.Emai,
				nombramientoRepresentanteLegalHidden: "",
				personariaJuridicaHidden: "",
				documentoIndentificacionHidden: "",
				commentary: "",
				commentaryTwo: "",
				anexoDientification: "",
			});
			if (Array.isArray(res.UploadedFiles) && res.UploadedFiles?.length > 0) {
				const files = {};
				res.UploadedFiles.forEach((e) => {
					files[e.NameRequirement] = e;
				});
				setSavedFles(files);
			}
			setDataDetail(res);
		} catch (err) {
			console.log("res detail", err);
		}
	};

	const getVendors = async (userEmail, countrieId) => {
		try {
			const res = await getVendorsByEmailAndContrieId(userEmail, countrieId);
			setVendors(res);
		} catch (err) {
			console.log("err", err);
		}
	};

	const getCountries = async (userEmail) => {
		try {
			let res = await getCountriesListByEmail(userEmail);
			res = res.filter(x => x.Name !== 'Regional')
			setCountries(res);
		} catch (err) {
			console.log("err", err);
		}
	};

	const handleSubmitError = (errors) => {
		const errorsName = {};
		errors.forEach((element) => {
			errorsName[element.props.name] = "error";
		});
		setErorrsForm(errorsName);
	};

	const updateFile = (file) => {
		setSavedFles({
			...savedFiles,
			[file.NameRequirement.trim() === "Anexo 1"
				? "Anexo 1 información de empresa proveedora"
				: file.NameRequirement.trim()]: {},
		});
	};

	const getRegistrationDetail = async (id) => {
		try {
			const res = await getRegistrationCompanyById(id);
			setDataForm({
				bussinessName: res.BusinessName,
				legalId: res.LegalId,
				legalRepresentative: res.LegalRepresentative,
				contactName: res.ContactName,
				contactPhone: res.ContactPhone,
				contactEmail: res.ContactEmail,
				createdBy: userInfo.Emai,
				nombramientoRepresentanteLegalHidden: "",
				personariaJuridicaHidden: "",
				documentoIndentificacionHidden: "",
				commentary: "",
				commentaryTwo: "",
				anexoDientification: "",
				vendor: res.VendorName,
				country: res.Countrie,
			});
			setRegistrationInfo(res);

			if (Array.isArray(res.UploadedFiles) && res.UploadedFiles?.length > 0) {
				const files = {};
				res.UploadedFiles.forEach((e) => {
					files[
						e.NameRequirement.trim() === "Anexo 1"
							? "Anexo 1 información de empresa proveedora"
							: e.NameRequirement.trim()
					] = e;
				});
				setSavedFles(files);
			}
			setDataDetail(res);
			const commentaries = await getRegistrationCompanyCommentariesById(id);
			setListCommentaries(commentaries);

			if (res.Statusname === 'Aprobado') {
				const dias = diffDays(moment(), moment(res.Expiration))
				if (dias <= EXPIRE_DAYS || dias <= 0) {
					setUpdateButton({
						mensaje: "Renovar",
						dias: 0
					})
				}
			} else if (res.Statusname === 'Rechazado') {
				setUpdateButton({
					mensaje: "Reenviar",
					dias: 0
				})
			}
		} catch (err) { }
	};

	const createCommentary = async () => {
		if (!dataForm.commentary) {
			dispatch(
				showAlertAction({
					show: true,
					variant: "warning",
					message:
						`Agregue un comentario`,
				})
			);
			return;
		}
		if (dataForm.commentary?.length > 125) {
			dispatch(
				showAlertAction({
					show: true,
					variant: "warning",
					message:
						`El maximo de caracteres es de 125`,
				})
			);
			return;
		}
		try {
			dispatch(showLoadingAction());
			const body = {
				RegistrationCompanyRequestId: id,
				Commentary: dataForm.commentary,
				UserId: userInfo.email,
				UserName: dataForm.bussinessName,
				CreatedBy: userInfo.email,
				Origin: "Tercero",
			};
			await AddCommentary(body);
			setDataForm({ ...dataForm, commentary: "" });
			dispatch(hideLoadingAction());
			const commentaries = await getRegistrationCompanyCommentariesById(id);
			setListCommentaries(commentaries);
			dispatch(
				showAlertAction({
					show: true,
					variant: "success",
					message:
						`Comentario agregado con exito`,
				})
			);
		} catch (err) {
			console.log("err", err);
			dispatch(
				showAlertAction({
					show: true,
					variant: "error",
					message:
						`Ha ocurrido un error al agregar el comentario`,
				})
			);
			dispatch(hideLoadingAction());
		}
	};

	const handleSubmitForm = async () => {
		const metadata = {
			VendorId: dataForm.vendor,
			// CountryId: dataForm.country,
			Commentary: dataForm.commentary,
			CreatedBy: userInfo.email,
		};

		const dataSend = new FormData();
		dataSend.append("metadata", JSON.stringify(metadata));
		dataSend.append("email", window.btoa(userInfo.email));
		filesForm.forEach((e) => {
			dataSend.append(`${FILE_PREFIX}Anexo 1 información de empresa proveedora`, e.file);
		});
		try {
			dispatch(showLoadingAction());
			const res = await createThirdCompany(dataSend);
			const body = {
				RegistrationCompanyRequestId: res.Id,
				Commentary: dataForm.commentary,
				UserId: userInfo.email,
				UserName: dataForm.bussinessName,
				CreatedBy: userInfo.email,
				Origin: "Tercero",
			};
			await AddCommentary(body);

			dispatch(hideLoadingAction());
			dispatch(
				showAlertAction({
					show: true,
					variant: "success",
					message:
						`Se ha creado el registro de empresa, exitosamente`,
				})
			);
			history.push("/company-register-list");
		} catch (err) {
			dispatch(hideLoadingAction());
			dispatch(
				showAlertAction({
					show: true,
					variant: "error",
					message:
						`Ha ocurrido un error al crear el registro de empresa`,
				})
			);
			console.log("err", err);
		}
	};

	const handleUpdateForm = async () => {
		const metadata = {
			Id: id,
			Commentary: dataForm.commentary,
			ModifiedBy: userInfo.email,
		};

		const dataSend = new FormData();
		dataSend.append("metadata", JSON.stringify(metadata));
		dataSend.append("email", window.btoa(userInfo.email));
		filesForm.forEach((e) => {
			dataSend.append(`${FILE_PREFIX}Anexo 1 información de empresa proveedora`, e.file);
		});
		try {
			dispatch(showLoadingAction());
			await UpdateThirdCompany(dataSend);

			dispatch(hideLoadingAction());
			dispatch(
				showAlertAction({
					show: true,
					variant: "success",
					message:
						`Registro de empresa actualizado exitosamente`,
				})
			);
			history.push("/company-register-list");
		} catch (err) {
			dispatch(hideLoadingAction());
			dispatch(
				showAlertAction({
					show: true,
					variant: "error",
					message:
						`Ha ocurrido un error al actualizar el registro de empresa`,
				})
			);
			console.log("err", err);
		}
	};


	const handleAboutToExpireForm = async () => {
		const metadata = {
			Id: registrationInfo.Id,
			ModifiedBy: userInfo.email,
		};

		const dataSend = new FormData();
		dataSend.append("metadata", JSON.stringify(metadata));
		dataSend.append("email", window.btoa(userInfo.email));
		filesForm.forEach((e) => {
			dataSend.append(`${FILE_PREFIX}Anexo 1 información de empresa proveedora`, e.file);
		});
		try {
			dispatch(showLoadingAction());
			await ReSendRegistrationCompanyRequest(dataSend);
			await DeleteRegistrationCompanyCommentaries(registrationInfo.Id)
			const body = {
				RegistrationCompanyRequestId: registrationInfo.Id,
				Commentary: "Mensaje Autogenerado, la renovación del registro de empresa elimino todos los comentarios anteriores",
				UserId: "Visado Seguridad",
				UserName: "Visado Seguridad",
				CreatedBy: userInfo.email,
				Origin: "Tercero",
			};
			await AddCommentary(body);

			dispatch(hideLoadingAction());
			dispatch(
				showAlertAction({
					show: true,
					variant: "success",
					message:
						`Se ha enviado la solicitud para la renovación registro de empresa, exitosamente`,
				})
			);
			history.push("/company-register-list");
		} catch (err) {
			dispatch(hideLoadingAction());
			dispatch(
				showAlertAction({
					show: true,
					variant: "error",
					message:
						`Ha ocurrido un error al realizar la renovación del registro de empresa`,
				})
			);
			console.log("err", err);
		}
	}

	const handleReSendForm = async () => {
		const metadata = {
			Id: registrationInfo.Id,
			ModifiedBy: userInfo.email,
		};

		const dataSend = new FormData();
		dataSend.append("metadata", JSON.stringify(metadata));
		dataSend.append("email", window.btoa(userInfo.email));
		filesForm.forEach((e) => {
			dataSend.append(`${FILE_PREFIX}Anexo 1 información de empresa proveedora`, e.file);
		});
		try {
			dispatch(showLoadingAction());
			await ReSendRegistrationCompanyRequest(dataSend);
			const body = {
				RegistrationCompanyRequestId: registrationInfo.Id,
				Commentary: "Reenvio de solicitud de registro de empresa",
				UserId: userInfo.email,
				UserName: dataForm.bussinessName,
				CreatedBy: userInfo.email,
				Origin: "Tercero",
			};
			await AddCommentary(body);

			dispatch(hideLoadingAction());
			dispatch(
				showAlertAction({
					show: true,
					variant: "success",
					message:
						`Se reenvio la solicitud de registro de empresa, exitosamente`,
				})
			);
			history.push("/company-register-list");
		} catch (err) {
			dispatch(hideLoadingAction());
			dispatch(
				showAlertAction({
					show: true,
					variant: "error",
					message:
						`Ha ocurrido un error al realizar el reenvio de la solicitud de registro de empresa`,
				})
			);
			console.log("err", err);
		}
	}

	const onCloseAboutExpireSuccess = () => {
		setOpenDialogAboutToExpire(false)
		handleAboutToExpireForm()
	}

	const handleChangeFile = (nameInput, file) => {
		const filesExists = filesForm.filter((e) => e.name !== nameInput);

		setFilesForm([...filesExists, { name: nameInput, file: file }]);
	};

	const handleChangeDataForm = (name, state) => {
		setDataForm({ ...dataForm, [name]: state });
		if (errorsForm[name]) {
			const errors = { ...errorsForm, [name]: null };
			setErorrsForm(errors);
		}
	};

	const onCloseAlertSuccess = () => {
		setOpenDialogSucess(false);
		handleSubmitForm();
	};

	const onCloseAlertUpdate = () => {
		setOpenDialogUpdate(false);
		handleUpdateForm();
	};

	const onCloseDialogReSend = () => {
		setOpenDialogReSend(false);
		handleReSendForm()
	}



	const showConfirmAlert = () => {

		if (registrationInfo) {
			// Get Sub Status
			if (updateButton.mensaje === 'Renovar') {
				setOpenDialogAboutToExpire(true)
			} else if (updateButton.mensaje === 'Reenviar') {
				setOpenDialogReSend(true);
			} else {
				setOpenDialogUpdate(true);
			}
		} else {
			setOpenDialogSucess(true);
		}
	};
	const onCloseSubmitAlert = () => {
		setOpenDialogSucess(false);
		setOpenDialogUpdate(false);

	};

	const onCloseAlertCommentaryEmpty = () => {
		setOpenDialogAboutToExpire(false)
		setOpenDialogReSend(false);
	};


	const downloadMethod = async (file) => {
		const fileName = file.RouteRequirement.split("/").pop();

		dispatch(showLoadingAction());
		try {
			const formDataS = new FormData();
			formDataS.append("fileName", fileName);

			const res = await getFile(formDataS);
			// Use const instead of var
			const blob = new Blob([res], { type: "application/octet-stream" });
			// Use const instead of var
			const link = document.createElement("A");
			link.href = URL.createObjectURL(blob); // SET LINK ELEMENTS CONTENTS
			link.setAttribute("download", fileName); // SET ELEMENT CREATED 'ATTRIBUTE' TO DOWNLOAD, FILENAME PARAM AUTOMATICALLY
			link.click(); // TRIGGER ELEMENT TO DOWNLOAD
			link.remove();
			dispatch(hideLoadingAction());
		} catch (error) {
			dispatch(hideLoadingAction());
		}
	};

	return (
		<div>
			<ValidatorForm
				className={classes.root}
				autoComplete="off"
				onSubmit={showConfirmAlert}
				onError={handleSubmitError}
			>
				<Grid container className={classes.containerCountries} spacing={3}>
					<Grid item xs={6}>
						<div className={classes.containerInput}>
							<InputLabel id="country" className={classes.labelInput}>
								* País
							</InputLabel>
							{registrationInfo != null ? (
								<TextValidator
									className={classes.inputText}
									inputProps={inputCss}
									value={dataForm.country}
									disabled
									onChange={handleChange}
									name="country"
									id="standard-basic"
									variant="outlined"
								/>
							) : (
								<SelectValidator
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={dataForm.country}
									name="country"
									onChange={handleChange}
									variant="outlined"
									validators={NORMAL_INPUT}
									errorMessages={NORMAL_INPUT_MESSAGE}
									className={classes.selectInput}
								>
									{countries.map((item) => (
										<MenuItem key={item.Id} value={item.Id}>
											{item.Name}
										</MenuItem>
									))}
								</SelectValidator>
							)}
						</div>
					</Grid>
					<Grid item xs={6}>
						<div className={classes.containerInput}>
							<InputLabel id="country" className={classes.labelInput}>
								* Vendor
							</InputLabel>
							{registrationInfo != null ? (
								<TextValidator
									className={classes.inputText}
									inputProps={inputCss}
									value={dataForm.vendor}
									disabled
									onChange={handleChange}
									name="vendor"
									id="standard-basic"
									variant="outlined"
								/>
							) : (
								<SelectValidator
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									defaultValue={dataForm.vendor}
									value={dataForm.vendor}
									name="vendor"
									onChange={handleChange}
									variant="outlined"
									validators={NORMAL_INPUT}
									errorMessages={NORMAL_INPUT_MESSAGE}
									className={classes.selectInput}
								>
									{vendors.map((item) => (
										<MenuItem key={item.Id} value={item.Id}>
											{item.Name}
										</MenuItem>
									))}
								</SelectValidator>
							)}
						</div>
					</Grid>
					<Grid item xs={6}>
						<FilesForm
							handleChangeDataForm={handleChangeDataForm}
							dataForm={dataForm}
							filesToUPload={anexoFiles}
							errorsForm={errorsForm}
							showDialogCustom={() => {}}
							handleChangeFile={handleChangeFile}
							savedFiles={savedFiles}
							updateFile={updateFile}
							downloadMethod={downloadMethod}
							editMode={registrationInfo ? false : true}
						/>
					</Grid>
				</Grid>
				<AlertMessage
					clickPage
					message="Esta información es tomada de su perfil, para actualizar la información"
				/>
				<Grid container className={classes.container} spacing={3}>
					<Grid item xs={6}>
						<div className={classes.containerInput}>
							<InputLabel id="legalId" className={classes.labelInput}>
								* Cedula jurídica
							</InputLabel>
							<TextValidator
								className={classes.inputText}
								inputProps={inputCss}
								value={dataForm.legalId}
								disabled
								onChange={handleChange}
								name="legalId"
								id="standard-basic"
								variant="outlined"
								validators={NORMAL_INPUT_MAX}
								errorMessages={EMAIL_INPUT_MAX_MESSAGE_TEXTAREA}
							/>
						</div>
						<div className={classes.containerInput}>
							<InputLabel id="bussinessName" className={classes.labelInput}>
								* Nombre razón social
							</InputLabel>
							<TextValidator
								className={classes.inputText}
								inputProps={inputCss}
								disabled
								value={dataForm.bussinessName}
								onChange={handleChange}
								name="bussinessName"
								id="standard-basic"
								variant="outlined"
								validators={NORMAL_INPUT_MAX}
								errorMessages={EMAIL_INPUT_MAX_MESSAGE_TEXTAREA}
							/>
						</div>
					</Grid>
					<Grid item xs={6}>
						<div className={classes.containerInput}>
							<InputLabel
								id="legalRepresentative"
								className={classes.labelInput}
							>
								* Nombre representante legal
							</InputLabel>
							<TextValidator
								className={classes.inputText}
								inputProps={inputCss}
								value={dataForm.legalRepresentative}
								disabled
								onChange={handleChange}
								name="legalRepresentative"
								id="standard-basic"
								variant="outlined"
								validators={NORMAL_INPUT_MAX}
								errorMessages={EMAIL_INPUT_MAX_MESSAGE_TEXTAREA}
							/>
						</div>
					</Grid>
					<Grid item xs={4}>
						<div className={classes.containerInput}>
							<InputLabel id="contactName" className={classes.labelInput}>
								* Nombre persona contacto
							</InputLabel>
							<TextValidator
								className={classes.inputText}
								inputProps={inputCss}
								value={dataForm.contactName}
								onChange={handleChange}
								disabled
								name="contactName"
								id="standard-basic"
								variant="outlined"
								validators={NORMAL_INPUT_MAX}
								errorMessages={EMAIL_INPUT_MAX_MESSAGE_TEXTAREA}
							/>
						</div>
					</Grid>
					<Grid item xs={4}>
						<div className={classes.containerInput}>
							<InputLabel id="contactPhone" className={classes.labelInput}>
								* Teléfono persona contacto
							</InputLabel>
							<TextValidator
								className={classes.inputText}
								inputProps={inputCss}
								value={dataForm.contactPhone}
								onChange={handleChange}
								name="contactPhone"
								disabled
								id="standard-basic"
								variant="outlined"
								validators={NORMAL_INPUT_MAX}
								errorMessages={EMAIL_INPUT_MAX_MESSAGE_TEXTAREA}
							/>
						</div>
					</Grid>
					<Grid item xs={4}>
						<div className={classes.containerInput}>
							<InputLabel id="contactEmail" className={classes.labelInput}>
								* Correo persona contacto
							</InputLabel>
							<TextValidator
								className={classes.inputText}
								inputProps={inputCss}
								value={dataForm.contactEmail}
								disabled
								onChange={handleChange}
								name="contactEmail"
								id="standard-basic"
								variant="outlined"
								validators={NORMAL_INPUT_MAX}
								errorMessages={EMAIL_INPUT_MAX_MESSAGE_TEXTAREA}
							/>
						</div>
					</Grid>
					<Grid item xs={6}>
						<FilesForm
							handleChangeDataForm={handleChangeDataForm}
							dataForm={dataForm}
							filesToUPload={filesToUPload}
							errorsForm={errorsForm}
							showDialogCustom={() => {}}
							handleChangeFile={handleChangeFile}
							savedFiles={savedFiles}
							downloadMethod={downloadMethod}
							editMode={true}
						/>
					</Grid>
					<Grid item xs={6}>
						<FilesForm
							handleChangeDataForm={handleChangeDataForm}
							dataForm={dataForm}
							filesToUPload={filesToUPloadSecond}
							errorsForm={errorsForm}
							handleChangeFile={handleChangeFile}
							savedFiles={savedFiles}
							downloadMethod={downloadMethod}
							editMode={true}
						/>
					</Grid>
					<Grid item xs={12}>
						{registrationInfo ? (
							<Grid item xs={12} md={12}>
								<ValidatorForm
									autoComplete="off"
									onSubmit={() => {}}
								>
									<CommentsForm
										commentaryList={listCommentaries}
										dataForm={dataForm}
										editMode={true}
										showBox={true}
										createCommentary={createCommentary}
										handleChangeDataForm={handleChangeDataForm}
										showCommentaries={true}
									/>
								</ValidatorForm>
							</Grid>
						) : (
							<div className={classes.commentInputContainer}>
								<p className={classes.commentInputTitle}>Comentarios</p>
								<TextareaAutosize
									className={classes.commentInput}
									value={dataForm.commentary}
									onChange={handleChange}
									name="commentary"
									aria-label="minimum height"
									minRows={5}
									placeholder="Escribe aqui tus comentarios"
								/>
								<TextValidator
									value={dataForm.commentary}
									name="commentaryTwo"
									id="standard-basic"
									type="hidden"
									validators={NORMAL_INPUT_MAX_TEXTAREA}
									errorMessages={EMAIL_INPUT_MAX_MESSAGE_TEXTAREA}
								/>
							</div>
						)}
					</Grid>
				</Grid>
				<AlertDialog
					open={openDialogSuccess}
					onClose={onCloseSubmitAlert}
					showWarningIcon={true}
					message={"Confirmación de registro de empresa"}
					onAcept={onCloseAlertSuccess}
				/>
				<AlertDialog
					open={openDialogUpdate}
					onClose={onCloseSubmitAlert}
					showWarningIcon={true}
					message={"Confirmación de actualización de registro de empresa"}
					onAcept={onCloseAlertUpdate}
				/>
				<AlertDialog
					open={openDialogReSend}
					onClose={onCloseAlertCommentaryEmpty}
					showWarningIcon={true}
					message={"Confirmación de reenvío de solicitud de registro de empresa con las correcciones realizadas"}
					onAcept={onCloseDialogReSend}
				/>
				<AlertDialog
					open={openDialogAboutToExpire}
					onClose={onCloseAlertCommentaryEmpty}
					showWarningIcon={true}
					message={"No podrá solicitar nuevos visados hasta que su solicitud sea aprobada (máximo 3 días)."}
					onAcept={onCloseAboutExpireSuccess}
				/>
				{registrationInfo != null ? (
					<div className={classes.buttonContainer}>
						<Button
							type="submit"
							variant="contained"
							color="secondary"
							className={classes.buttonSave}
						>
							{updateButton.mensaje}
						</Button>
					</div>
				) : (
					<div className={classes.buttonContainer}>
						<Button
							type="submit"
							variant="contained"
							color="secondary"
							disabled={validateVendor}
							className={classes.buttonSave}
						>
							Registrar
						</Button>
					</div>
				)}
			</ValidatorForm>
		</div>
	);
}
