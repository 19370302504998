
import moment from 'moment';
import { MAX_ITERATIONS } from './constants';

export const formatDateTime = date => {
    if(date) {
        const dateMoment = moment(date);
        return dateMoment.format('DD/MM/YYYY hh:mm:ss a');
    } else {
        return ' - '
    }
}

export const getMaxLength = (current) => {
    return MAX_ITERATIONS > current ? MAX_ITERATIONS : current;
}

export const sanitize = (value) => {
  const charactersNotAllowed = /[&<>"'/]/g;
  const labelsNotAllowed = /<\/?[\w\s="/.':;#-/?]+>/g;
  
  var newText = value.replace(charactersNotAllowed, '')
                             .replace(labelsNotAllowed, '');
  
  return newText;
}
