import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {
	TextValidator,
	SelectValidator,
} from "react-material-ui-form-validator";
import FilesForm from "../../../components/FilesForm/FilesForm";

import CommentsForm from "../../../components/CommentsForm/CommentsForm";


//Assets
import Electrico from '../../../assets/danger-types/electrico.png';
import Altura from '../../../assets/danger-types/en-altura.png';
import Espacio from '../../../assets/danger-types/en-espacio-confinado.png';
import Caliente from '../../../assets/danger-types/en-caliente.png';

import {
	NORMAL_INPUT,
	NORMAL_INPUT_MESSAGE,
	EMAIL_INPUT,
	EMAIL_INPUT_MESSAGE,
	NORMAL_INPUT_MAX,
	EMAIL_INPUT_MAX_MESSAGE_TEXTAREA,
	CHECKBOX_INPUT_MESSAGE,
	CHECKBOX_INPUT,
} from "../../../utils/input-rules";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
const inputCss = {
	style: {
		padding: 10,
	},
};


export default function InfoForm({
	handleChange,
	vendors,
	countries,
	visadosType,
	classes,
	dataForm,
	handleChangeDataForm,
	listCommentaries,
	createCommentary,
	handleCancel,
	dangerTypes,
	errorsForm,
	companyFiles,
	downloadMethod,
	savedFiles,
	isAnual,
	handleChangeDangerTypes,
	requirementsFiles,
	handleChangeFile,
	editMode,
	savedTemporalFiles,
	setUpladeFile,
	extraCompanyFiles,
	sendButton
}) {
	return (
		<div>
			<Grid container className={classes.containerCountries} spacing={1}>
				<Grid item xs={6}>
					<div className={classes.containerInput}>
						<InputLabel id="country" className={classes.labelInput}>
							* País
						</InputLabel>
						<SelectValidator
							id="demo-simple-select"
							value={dataForm.country}
							name="country"
							onChange={handleChange}
							variant="outlined"
							data-testid="country"
							disabled={editMode}
							validators={NORMAL_INPUT}
							errorMessages={NORMAL_INPUT_MESSAGE}
							className={classes.selectInput}
						>
							{countries.map((item) => (
								<MenuItem key={item.Id} value={item.Id}>
									{item.Name}
								</MenuItem>
							))}
						</SelectValidator>
					</div>
				</Grid>
			</Grid>

			<Grid container className={classes.containerCountries} spacing={2}>
				<Grid item xs={6}>
					<div className={classes.containerInput}>
						<InputLabel id="vendor" className={classes.labelInput}>
							* Vendor
						</InputLabel>
						<SelectValidator
							id="demo-simple-select"
							defaultValue={dataForm.vendor}
							value={dataForm.vendor}
							name="vendor"
							onChange={handleChange}
							disabled={editMode}
							data-testid="vendor"
							variant="outlined"
							validators={NORMAL_INPUT}
							errorMessages={NORMAL_INPUT_MESSAGE}
							className={classes.selectInput}
						>
							{vendors.map((item) => (
								<MenuItem key={item.Id} value={item.Id}>
									{item.Name}
								</MenuItem>
							))}
						</SelectValidator>
					</div>
				</Grid>
				<Grid item xs={6}>
					<div className={classes.containerInput}>
						<InputLabel id="visadoType" className={classes.labelInput}>
							* Tipo de visado
						</InputLabel>
						<SelectValidator
							id="demo-simple-select"
							defaultValue={dataForm.visadoType}
							value={dataForm.visadoType}
							name="visadoType"
							data-testid="visadoType"
							onChange={handleChange}
							disabled={editMode}
							variant="outlined"
							validators={NORMAL_INPUT}
							errorMessages={NORMAL_INPUT_MESSAGE}
							className={classes.selectInput}
						>
							{visadosType.map((item) => (
								<MenuItem key={item.ID} value={item.ID}>
									{item.Name}
								</MenuItem>
							))}
						</SelectValidator>
					</div>
				</Grid>

				{dangerTypes.length > 0 && sendButton && (
					<Grid item xs={12}>
						{dangerTypes.map((dangerType) => {
							// Extract nested ternary into an independent function
							const renderDangerTypeImage = () => {
								if (dangerType.VisaDangerTypeName.includes("Eléctrico")) {
									return <img src={Electrico} className={classes.dangerZoneImage} />;
								}
								if (dangerType.VisaDangerTypeName.includes("Altura")) {
									return <img src={Altura} className={classes.dangerZoneImage} />;
								}
								if (dangerType.VisaDangerTypeName.includes("Espacio")) {
									return <img src={Espacio} className={classes.dangerZoneImage} />;
								}
								if (dangerType.VisaDangerTypeName.includes("Caliente")) {
									return <img src={Caliente} className={classes.dangerZoneImage} />;
								}
								return null;
							};

							return (
								// See Walmart.VisadoSeguridad.Api.Repository.Entities.VisaDangerTypesByVisaType for reference
								<div key={dangerType.ID}>
									<input
										type="checkbox"
										id={dangerType.VisaDangerTypeName}
										checked={dangerType.selected}
										onChange={handleChangeDangerTypes}
										name={dangerType.VisaDangerTypeName}
										className={classes.inputCheckbox}
									/>
									<label
										for={dangerType.VisaDangerTypeName}
										className={classes.labelCheckbox}
									>
										{dangerType.VisaDangerTypeName}
									</label>
									{renderDangerTypeImage()}
								</div>
							);
						})}
						<TextValidator
							value={dataForm.dangerZone}
							name="dangerZone"
							id="standard-basic"
							type="hidden"
							validators={editMode ? [] : CHECKBOX_INPUT}
							errorMessages={CHECKBOX_INPUT_MESSAGE}
						/>
					</Grid>
				)}
			</Grid>
			{sendButton && (
				<>
					{isAnual === false && (
						<Grid container className={classes.containerCountries} spacing={2}>
							<Grid item xs={6}>
								<div className={classes.containerInput}>
									<InputLabel id="legalName" className={classes.labelInput}>
										* Nombre empresa socio (Razón social)
									</InputLabel>
									<TextValidator
										className={classes.inputText}
										inputProps={inputCss}
										value={dataForm.legalName}
										onChange={handleChange}
										disabled
										name="legalName"
										id="standard-basic"
										variant="outlined"
										validators={NORMAL_INPUT_MAX}
										errorMessages={EMAIL_INPUT_MAX_MESSAGE_TEXTAREA}
									/>
								</div>
							</Grid>
							<Grid item xs={6}>
								<div className={classes.containerInput}>
									<InputLabel id="legalCompanyId" className={classes.labelInput}>
										* Cédula jurídica empresa socio
									</InputLabel>
									<TextValidator
										className={classes.inputText}
										inputProps={inputCss}
										value={dataForm.legalCompanyId}
										onChange={handleChange}
										disabled
										name="legalCompanyId"
										id="standard-basic"
										variant="outlined"
										validators={NORMAL_INPUT_MAX}
										errorMessages={EMAIL_INPUT_MAX_MESSAGE_TEXTAREA}
									/>
								</div>
							</Grid>
							<Grid item xs={6}>
								<div className={classes.containerInput}>
									<InputLabel id="country" className={classes.labelInput}>
										* Nombre representante legal
									</InputLabel>
									<TextValidator
										className={classes.inputText}
										inputProps={inputCss}
										value={dataForm.legalNameRep}
										onChange={handleChange}
										disabled
										name="legalNameRep"
										id="standard-basic"
										variant="outlined"
										validators={NORMAL_INPUT_MAX}
										errorMessages={EMAIL_INPUT_MAX_MESSAGE_TEXTAREA}
									/>
								</div>
							</Grid>

							<Grid item xs={6}>
								<div className={classes.containerInput}>
									<InputLabel id="country" className={classes.labelInput}>
										* Correo electrónico para notificaciones
									</InputLabel>
									<TextValidator
										className={classes.inputText}
										inputProps={inputCss}
										value={dataForm.notifyEmail}
										onChange={handleChange}
										name="notifyEmail"
										id="standard-basic"
										variant="outlined"
										validators={EMAIL_INPUT}
										errorMessages={EMAIL_INPUT_MESSAGE}
									/>
								</div>
							</Grid>
							<Grid item xs={6}>
								<FilesForm
									handleChangeDataForm={handleChangeDataForm}
									dataForm={dataForm}
									filesToUPload={requirementsFiles}
									errorsForm={errorsForm}
									// showDialogCustom={showDialogCustom}
									handleChangeFile={handleChangeFile}
									savedFiles={savedTemporalFiles}
									updateFile={setUpladeFile}
									downloadMethod={downloadMethod}
									editMode={false}
								/>
							</Grid>
						</Grid>
					)}

					{isAnual === true && (
						<Grid container className={classes.containerCountries} spacing={2}>
							<Grid item xs={6}>
								<div className={classes.containerInput}>
									<InputLabel id="country" className={classes.labelInput}>
										* Correo electrónico para notificaciones
									</InputLabel>
									<TextValidator
										className={classes.inputText}
										inputProps={inputCss}
										value={dataForm.notifyEmail}
										onChange={handleChange}
										name="notifyEmail"
										id="standard-basic"
										variant="outlined"
										validators={EMAIL_INPUT}
										errorMessages={EMAIL_INPUT_MESSAGE}
									/>
								</div>
							</Grid>
							<Grid item xs={8}>
								<FilesForm
									handleChangeDataForm={handleChangeDataForm}
									dataForm={dataForm}
									filesToUPload={companyFiles}
									errorsForm={errorsForm}
									// showDialogCustom={showDialogCustom}
									handleChangeFile={handleChangeFile}
									savedFiles={savedFiles}
									updateFile={setUpladeFile}
									downloadMethod={downloadMethod}
									editMode={true}
								/>
								{extraCompanyFiles.length > 0 && (
									<FilesForm
										handleChangeDataForm={handleChangeDataForm}
										dataForm={dataForm}
										filesToUPload={extraCompanyFiles}
										errorsForm={errorsForm}
										handleChangeFile={handleChangeFile}
										savedFiles={savedTemporalFiles}
										updateFile={setUpladeFile}
										downloadMethod={downloadMethod}
										editMode={false}
									/>

								)}
							</Grid>
						</Grid>
					)}
					<Grid item xs={12}>
						{dataForm.visadoType && (
							<CommentsForm
								commentaryList={listCommentaries}
								dataForm={dataForm}
								showBox={true}
								editMode={editMode}
								update={editMode}
								createCommentary={createCommentary}
								handleChangeDataForm={handleChangeDataForm}
								showCommentaries={editMode}
							/>
						)}
					</Grid>
				</>
			)}
			<Grid item xs={6}>
				<Button
					type="submit"
					color="secondary"
					variant="contained"
					disabled={!sendButton}
					className={classes.buttonSave}
				>
					{editMode ? 'Actualizar' : 'Guardar'} y agregar colaboradores
				</Button>
				<Button
					color="secondary"
					className={classes.buttonCancel}
					onClick={handleCancel}
				>
					Cancelar
				</Button>
			</Grid>

		</div>
	);
}
