import { environment } from "../config/config";
import { axiosWithToken } from "../config/axiosConfig";

export const getCommentsByVisaAplicationId = async (visaAplicationId) => {
	const res = await axiosWithToken.get(
		`${environment.baseUrl}/VisaApplication/CommentariesByApplicationId/${visaAplicationId}`
	);
	if (res.data && res.data.Results) {
		return res.data.Results;
	}
	return [];
};


export const createCommentaryByVisaAplication = async (data) => {
	return new Promise((resolve, reject) => {
		axiosWithToken
			.post(
				`${environment.baseUrl}/VisaApplication/AddCommentary`,
				data
			)
			.then((result) => {
				resolve(result.data.Results);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
