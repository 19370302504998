import { Route } from 'react-router-dom'
import React from "react";
export const ProtectedRoute = ({ component: Component, path, ...rest }) => {
    return (
        <Route {...rest}
            render={
                (props) => {
                    return <Component {...props} />
                }
            } />
    )
}
