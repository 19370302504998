import React, { useEffect, useState } from "react";
//form components

import InputLabel from "@material-ui/core/InputLabel";
import {
	TextValidator,
	ValidatorForm
} from "react-material-ui-form-validator";

import {
	EMAIL_INPUT,
	EMAIL_INPUT_MESSAGE,
	NORMAL_INPUT_MAX,
	EMAIL_INPUT_MAX_MESSAGE_TEXTAREA,
} from "../../utils/input-rules";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { AlertMessage } from "../../components/AlertMessageForm/AlertMessage";
import FilesForm from "../../components/FilesForm/FilesForm";
import Button from "@material-ui/core/Button";
import {
	getUserDetailByEmail,
	updateUserInfo,
} from "../../services/profile.service";
import AlertDialog from "../../components/AlertDialog/AlertDialog";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	showLoadingAction,
	hideLoadingAction,
} from "../../redux/actions/loading.actions";
import { getFile } from "../../services/files.service";
import { setTitlePageAction } from "../../redux/actions/title.actions";

//constants
import {FILE_PREFIX} from '../../constants/api_proxy.constant'

const filesToUPload = [
	{
		name: "Nombramiento de representante legal o personería jurídica",
		nameInput: "nombramientoRepresentanteLegal",
		nameHiddenInput: "nombramientoRepresentanteLegalHidden",
		key: 1,
	},
	{
		name: "Declaración jurada emitida ante un abogado",
		nameInput: "personariaJuridica",
		nameHiddenInput: "personariaJuridicaHidden",
		key: 2,
	},
];

const filesToUPloadSecond = [
	{
		name: "Documento de identificación personal o cédula de RL",
		nameInput: "documentoIndentificacion",
		nameHiddenInput: "documentoIndentificacionHidden",
		key: 3,
	},
];

const inputCss = {
	style: {
		padding: 10,
	},
};

const useStyles = makeStyles((theme) => ({
	root: {
		padding: "20px 30px",
	},
	container: {},
	containerPaper: {
		padding: "1rem",
	},
	containerPaperFiles: {
		padding: "1rem 0",
	},
	formControl: {
		minWidth: "90%",
	},
	inputText: {
		minWidth: "90%",
	},
	title: {
		fontSize: 21,
		color: theme.palette.primary.main,
		textAlign: "center",
	},
	containerInput: {
		marginTop: 20,
	},
	labelInput: {
		marginTop: 30,
		color: theme.palette.primary.main,
		fontWeight: "bold",
	},
	selectInput: {
		minWidth: "100%",
		padding: 0,
	},
	inputFile: {
		display: "none",
	},
	buttonAttach: {
		color: theme.palette.secondary.main,
		borderRadius: "0 5px 5px 0",
		boxShadow: "none",
		margin: 0,
		textTransform: "inherit",
	},
	buttonAttachLabel: {
		margin: 0,
	},
	containerButtonAttach: {
		display: "flex",
		alignItems: "center",
		width: "60%",
		flex: 1,
	},
	textButtonAttach: {
		margin: 0,
		backgroundColor: "#e0e0e0",
		fontSize: 14,
		padding: "6px 16px",
		color: "#777777",
		borderRight: "1px solid #b3b3b3",
		lineHeight: "1.75",
		borderRadius: "5px 0 0 5px",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	containerButtonAttachItem: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		borderBottom: "1px solid #e0e0e0",
		marginTop: 20,
		paddingBottom: 20,
		padding: "0 10px",
	},
	containerButtonAttachText: {
		flex: 1,
	},
	textButtonAttachItem: {
		margin: 0,
		color: "#777777",
	},
	textError: {
		color: "#f44336",
		fontSize: ".8rem",
	},
	buttonContainer: {
		marginTop: 40,
	},
	buttonSave: {
		textTransform: "none !important",
	},
	titlePage: {
		fontSize: "1.3rem",
		margin: 0,
		fontWeight: "bold",
		marginTop: "1rem",
	},
}));

export function Profile({ auth }) {
	const [dataForm, setDataForm] = useState({
		bussinessName: "",
		legalId: "",
		legalRepresentative: "",
		contactName: "",
		contactPhone: "",
		contactEmail: "",
		createdBy: "",
		nombramientoRepresentanteLegalHidden: "",
		personariaJuridicaHidden: "",
		documentoIndentificacionHidden: "",
	});
	const [openDialog, setOpenDialog] = useState(false);
	const [openDialogSubmit, setOpenDialogSubmit] = useState(false);
	const [dataDetail, setDataDetail] = useState({});
	const [savedFiles, setSavedFles] = useState({});
	const [errorsForm, setErorrsForm] = useState({});
	const [filesForm, setFilesForm] = useState([]);
	const [userInfo, setUserInfo] = useState({});
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	useSelector(({ alertReducer }) => alertReducer);

	const handleChange = (event) => {
		setDataForm({ ...dataForm, [event.target.name]: event.target.value });
	};

	useEffect(() => {
		const user = auth.getUserInfo();
		dispatch(setTitlePageAction({ text: "Configuración de perfil" }));
		dispatch(showLoadingAction());
		setUserInfo(user);
		getDetail(user.email);
		dispatch(hideLoadingAction());
	}, []);

	const getDetail = async (userEmail) => {
		try {
			const res = await getUserDetailByEmail(userEmail);
			setDataForm({
				bussinessName: res.BusinessName ? res.BusinessName : "",
				legalId: res.LegalId ? res.LegalId : "",
				legalRepresentative: res.LegalRepresentative ? res.LegalRepresentative :"",
				contactName: res.ContactName ? res.ContactName : "",
				contactPhone: res.ContactPhone ? res.ContactPhone: "",
				contactEmail: res.ContactEmail ? res.ContactEmail: "",
				createdBy: userInfo.Email ? userInfo.Email : "",
				nombramientoRepresentanteLegalHidden: "",
				personariaJuridicaHidden: "",
				documentoIndentificacionHidden: "",
			});
			if (Array.isArray(res.UploadedFiles) && res.UploadedFiles.length > 0) {
				const files = {};
				res.UploadedFiles.forEach((e) => {
					files[e.NameRequirement] = e;
				});
				setSavedFles(files);
			}
			setDataDetail(res);
		} catch (err) {
			console.log("res detail", err);
		}
	};
	const handleSubmitError = (errors) => {
		const errorsName = {};
		errors.forEach((element) => {
			errorsName[element.props.name] = "error";
		});
		setErorrsForm(errorsName);
	};

	const handleSubmitForm = async () => {
		setOpenDialogSubmit(false);
		const metadata = {
			Id: dataDetail.Id,
			BusinessName: dataForm.bussinessName,
			LegalId: dataForm.legalId,
			LegalRepresentative: dataForm.legalRepresentative,
			ContactName: dataForm.contactName,
			ContactPhone: dataForm.contactPhone,
			ContactEmail: dataForm.contactEmail,
			Active: "true",
			ModifiedBy: userInfo.email,
		};

		const dataSend = new FormData();
		dataSend.append("metadata", JSON.stringify(metadata));
		filesForm.forEach((e) => {
			dataSend.append(`${FILE_PREFIX}${e.name}`, e.file);
		});
		try {
			dispatch(showLoadingAction());
			await updateUserInfo(dataSend);
			setOpenDialog(true);
			dispatch(hideLoadingAction());
		} catch (err) {
			dispatch(hideLoadingAction());
			console.log("err", err);
		}
	};

	const handleChangeFile = (nameInput, file) => {
		const filesExists = filesForm.filter((e) => e.name !== nameInput);

		setFilesForm([...filesExists, { name: nameInput, file: file }]);
	};

	const handleChangeDataForm = (name, state) => {
		setDataForm({ ...dataForm, [name]: state });
		if (errorsForm[name]) {
			const errors = { ...errorsForm, [name]: null };
			setErorrsForm(errors);
		}
	};

	const onCloseAlertSuccess = () => {
		setOpenDialog(false);
		history.push("/principal");
	};

	const onCloseSubmitAlert = () => {
		setOpenDialogSubmit(false);
	};

	const onAceptSubmitAlert = () => {
		handleSubmitForm();
	};

	const onShowAceptAletSubmit = () => {
		setOpenDialogSubmit(true);
	};

	const updateFile = (file) => {
		setSavedFles({ ...savedFiles, [file.NameRequirement]: {} });
	};

	const downloadMethod = async (file) => {
		const fileName = file.RouteRequirement.split("/").pop();

		dispatch(showLoadingAction());
		try {
			const formDataS = new FormData();
			formDataS.append("fileName", fileName);

			const res = await getFile(formDataS);
			let blob = new Blob([res], { type: "application/octet-stream" });
			let link = document.createElement("A");
			link.href = URL.createObjectURL(blob); // SET LINK ELEMENTS CONTENTS
			link.setAttribute("download", fileName); // SET ELEMENT CREATED 'ATTRIBUTE' TO DOWNLOAD, FILENAME PARAM AUTOMATICALLY
			link.click(); // TRIGGER ELEMENT TO DOWNLOAD
			link.remove();
			dispatch(hideLoadingAction());
		} catch (error) {
			dispatch(hideLoadingAction());
		}
	};

	return (
		<div>
			<ValidatorForm
				className={classes.root}
				autoComplete="off"
				onSubmit={onShowAceptAletSubmit}
				onError={handleSubmitError}
			>
				<AlertMessage />
				<h4 className={classes.titlePage}>REGISTRAR EMPRESA</h4>
				<Grid container className={classes.container} spacing={3}>
					<Grid item xs={6}>
						<div className={classes.containerInput}>
							<InputLabel id="legalId" className={classes.labelInput}>
								* Cédula jurídica
							</InputLabel>
							<TextValidator
								className={classes.inputText}
								inputProps={inputCss}
								value={dataForm.legalId}
								onChange={handleChange}
								name="legalId"
								id="standard-basic"
								variant="outlined"
								validators={NORMAL_INPUT_MAX}
								errorMessages={EMAIL_INPUT_MAX_MESSAGE_TEXTAREA}
							/>
						</div>
						<div className={classes.containerInput}>
							<InputLabel id="bussinessName" className={classes.labelInput}>
								* Nombre razón social
							</InputLabel>
							<TextValidator
								className={classes.inputText}
								inputProps={inputCss}
								value={dataForm.bussinessName}
								onChange={handleChange}
								name="bussinessName"
								id="standard-basic"
								variant="outlined"
								validators={NORMAL_INPUT_MAX}
								errorMessages={EMAIL_INPUT_MAX_MESSAGE_TEXTAREA}
							/>
						</div>
						<div className={classes.containerInput}>
							<InputLabel
								id="legalRepresentative"
								className={classes.labelInput}
							>
								* Nombre representante legal
							</InputLabel>
							<TextValidator
								className={classes.inputText}
								inputProps={inputCss}
								value={dataForm.legalRepresentative}
								onChange={handleChange}
								name="legalRepresentative"
								id="standard-basic"
								variant="outlined"
								validators={NORMAL_INPUT_MAX}
								errorMessages={EMAIL_INPUT_MAX_MESSAGE_TEXTAREA}
							/>
						</div>
						<FilesForm
							handleChangeDataForm={handleChangeDataForm}
							savedFiles={savedFiles}
							dataForm={dataForm}
							filesToUPload={filesToUPload}
							errorsForm={errorsForm}
							handleChangeFile={handleChangeFile}
							showDialogCustom={() => {}}
							updateFile={updateFile}
							downloadMethod={downloadMethod}
						/>
					</Grid>
					<Grid item xs={6}>
						<div className={classes.containerInput}>
							<InputLabel id="contactName" className={classes.labelInput}>
								* Nombre persona contacto
							</InputLabel>
							<TextValidator
								className={classes.inputText}
								inputProps={inputCss}
								value={dataForm.contactName}
								onChange={handleChange}
								name="contactName"
								id="standard-basic"
								variant="outlined"
								validators={NORMAL_INPUT_MAX}
								errorMessages={EMAIL_INPUT_MAX_MESSAGE_TEXTAREA}
							/>
						</div>
						<div className={classes.containerInput}>
							<InputLabel id="contactPhone" className={classes.labelInput}>
								* Teléfono persona contacto
							</InputLabel>
							<TextValidator
								className={classes.inputText}
								inputProps={inputCss}
								value={dataForm.contactPhone}
								onChange={handleChange}
								name="contactPhone"
								id="standard-basic"
								variant="outlined"
								validators={NORMAL_INPUT_MAX}
								errorMessages={EMAIL_INPUT_MAX_MESSAGE_TEXTAREA}
							/>
						</div>
						<div className={classes.containerInput}>
							<InputLabel id="contactEmail" className={classes.labelInput}>
								* Correo persona contacto
							</InputLabel>
							<TextValidator
								className={classes.inputText}
								inputProps={inputCss}
								value={dataForm.contactEmail}
								onChange={handleChange}
								name="contactEmail"
								id="standard-basic"
								variant="outlined"
								validators={EMAIL_INPUT}
								errorMessages={EMAIL_INPUT_MESSAGE}
							/>
						</div>
						<FilesForm
							handleChangeDataForm={handleChangeDataForm}
							dataForm={dataForm}
							savedFiles={savedFiles}
							filesToUPload={filesToUPloadSecond}
							errorsForm={errorsForm}
							showDialogCustom={() => {}}
							handleChangeFile={handleChangeFile}
							updateFile={updateFile}
							downloadMethod={downloadMethod}
						/>
					</Grid>
				</Grid>
				<div className={classes.buttonContainer}>
					<Button
						type="submit"
						variant="contained"
						color="secondary"
						className={classes.buttonSave}
					>
						Guardar cambios
					</Button>
				</div>
			</ValidatorForm>
			<AlertDialog
				open={openDialog}
				onClose={() => 1}
				hiddeCancel={true}
				message={"Se ha guardado correctamente la configuración de su perfil"}
				onAcept={onCloseAlertSuccess}
			/>
			<AlertDialog
				open={openDialogSubmit}
				onClose={onCloseSubmitAlert}
				showWarningIcon={true}
				message={
					"Antes de actualizar su configuración de perfil, debe saber que se actualizara esta información en todos los registros de empresa que tenga asociados."
				}
				onAcept={onAceptSubmitAlert}
			/>
		</div>
	);
}
