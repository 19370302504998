import { makeStyles } from "@material-ui/core/styles";


export default makeStyles((theme) => ({
    title: {
        textAlign: "center",
        font: "normal normal bold 26px/36px Bogle",
        color: "#525252"
    },
    infoText: {
        color: "#525252"
    },
    label: {
        textAlign: "left",
        font: "normal normal normal 13px/20px Bogle",
        letterSpacing: '0px',
        color: "#777B88"
    },
    button: {
        backgroundColor: "#0071dc",
        color: "#fff",
        "&:hover": {
            backgroundColor: 'rgb(17, 82, 147)'
        }
    },
    buttonText: {
        color: '#0071dc'
    }
}));
