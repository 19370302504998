import React from 'react'
import { WALMART_Loading } from '../assets/assets';
import CalculatedIcon from '../utils/CalculatedIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    div: {
      marginLeft: '43%',
      marginTop: '15%',
    },
  }));
const Loading = () => {

    const classes = useStyles();

    return (
      <>
      <div className={classes.div}>
    <CalculatedIcon  {...WALMART_Loading} />
    </div>
</>
    )
}

export default Loading
