import React from "react";

//authentication
import Auth from "./config/Auth";

import Callback from "./components/Callback";
import Checklog from "./components/Checklog";
import Unauthorized from "./screens/Unauthorized/index";
import Health from './screens/Health'
import {
	createTheme,
	ThemeProvider,
} from "@material-ui/core/styles";

import { Provider } from "react-redux";

import store from "./utils/store";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import Loading from "./components/Loading";
import { SnackBarAlert } from "./components/SnackBarAlert/SnackBarAlert";
import PrivateRoutes from "./routers/PrivateRouters";
import LoadingModal from "./components/LoadingModal/LoadingModal";
import { Login } from "./screens/Login/Login";
import { LoginContainer } from "./layouts/LoginContainer/LoginContainer";

const theme = createTheme({
	palette: {
		primary: {
			light: "#364b67",
			main: "#17304F",
			dark: "#02152e",
			contrastText: "#fff",
		},
		secondary: {
			light: "#338ed7",
			main: "#0072ce",
			dark: "#004f90",
			contrastText: "#fff",
		},
		text:{
			primary: "#364b67",
			custom: "#0071CE"
		}
	},
	overrides: {
		MuiSelect: {
			select: {
				padding: "10px !important",
			},
		},
	},
});

function App() {
	return (
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<BrowserRouter>
					<LoadingModal />
					<SnackBarAlert/>
					<main className="main-content">
						<div spacing={6} justify="center">
							{/* :grant_type/:grant_type/:client_id/:code/:state */}
							<div xs={11}>
								<Switch>
									<Route
										exact
										path="/checklog"
										// TODO: Do not define components during render. React will see a new component type on every render and destroy the entire subtree’s DOM nodes and state. Instead, move this component definition out of the parent component “App” and pass data as props. If you want to allow component creation in props, set allowAsProps option to true.
										// tags: jsx, performance, react
										// Reason to ignore: possible functionality break if changed
										component={() => <Checklog auth={Auth}></Checklog>}
									/>
									<Route
										path="/callback"
										render={(props) => <Callback {...props} auth={Auth} />}
									/>
									<Route
										path="/loading"
										render={(props) => <Loading {...props} auth={Auth} />}
									/>
									<Route
										path="/error"
										render={(props) => <Unauthorized {...props} auth={Auth} />}
									/>
									<Route
										path="/health"
										render={(props) => <Health />}
									/>
									<Route
										path="/camvisadoseguridad/api/v1/Health"
										render={(props) => <Health />}
									/>
									<Route path="/login" render={(props) => <LoginContainer><Login {...props}/></LoginContainer>}/>
									<PrivateRoutes></PrivateRoutes>
								</Switch>
							</div>
						</div>
					</main>
				</BrowserRouter>
			</ThemeProvider>
		</Provider>
	);
}
export default App;
