import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import checkIcon from '../../assets/status/check.png';
import warningIcon from '../../assets/imagen/warning.png';

const styles = {
  img: {
    width: '3rem'
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  textCenter: {
    textAlign: 'center'
  }
}
export default function AlertDialog({
  open,
  onClose,
  onAcept,
  message = "",
  title = "",
  hiddeCancel,
  showSuccessIcon,
  showWarningIcon
}) {

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {showSuccessIcon &&
            <div style={styles.imgContainer}>
              <img alt="successIcon" style={styles.img} src={checkIcon}/>
            </div>}
          {showWarningIcon &&
            <div style={styles.imgContainer}>
              <img alt="warningIcon" style={styles.img} src={warningIcon}/>
            </div>}
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={styles.textCenter}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!hiddeCancel ? (
            <Button onClick={onClose} color="primary">
              Cancelar
            </Button>
          ) : null}

          <Button onClick={onAcept} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
