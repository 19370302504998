import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { loginUser, createUser, generateOTP, validateOTP, sendCode, resetPP } from "../../services/auth.service";

import { ACCOUNT_FORM, LOGIN_FORM, OTP_FORM, PASS_FORM, USER_LOCKED, USER_NOT_FOUND, USER_PASSWORD_WRONG, VERIFY_EMAIL } from "../../constants/login.constant";
import {
    showLoadingAction,
    hideLoadingAction,
} from "../../redux/actions/loading.actions";
import { showAlertAction } from "../../redux/actions/alert.actions";

import { View } from "./view"
import Cookies from 'js-cookie'

//Services
import { getUserDetailByEmail } from "../../services/profile.service";

export const Login = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [disabled, setDisabled] = useState(true)
    const [stateForm, setStateForm] = useState(LOGIN_FORM)
    const [dataForm, setDataForm] = useState({ option: 'pass' })
    const [showPassword, setShowPassword] = useState(false)

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(email);
        return isValid;
    };

    const accountForm = () => {
        setStateForm(ACCOUNT_FORM)
        return;
    }

    const handleChange = (event) => {
        const { name, value } = event.target
        if (name === "email") {
            setDisabled(!validateEmail(value))
        }
        setDataForm({
            ...dataForm,
            [name]: value
        })
    }

    const handleTogglePassword = () => {
        setShowPassword(!showPassword)
    }

    const handleLoginPass = async () => {
        try {
            dispatch(showLoadingAction())
            const result = await loginUser({ email: dataForm.email, pp: dataForm.pp })
            await handleSaveSession(result)
            dispatch(hideLoadingAction())
            return;
        } catch (err) {
            dispatch(hideLoadingAction())
            if (404 === err.response.status) {
                Cookies.remove('user')
                Cookies.remove('login_id')
                Cookies.remove('access_token')
                history.push('/error')
                return;
            }
            dispatch(
                showAlertAction({
                    show: true,
                    variant: "error",
                    message: 'Ha ocurrido un error al procesar la solicitud.',
                })
            );

        }
    }

    const handleGenerateOTP = async () => {
        try {
            dispatch(showLoadingAction())
            await generateOTP({ email: dataForm.email })
            dispatch(hideLoadingAction())
            setStateForm(OTP_FORM);

        } catch (err) {
            dispatch(hideLoadingAction())
            dispatch(
                showAlertAction({
                    show: true,
                    variant: "error",
                    message: 'Ha ocurrido un error al procesar la solicitud.',
                })
            );
        }
    }

    const handleLoginOTP = async () => {
        try {
            dispatch(showLoadingAction())
            const result = await validateOTP({ email: dataForm.email, otpCode: dataForm.otp })
            await handleSaveSession(result);
            dispatch(hideLoadingAction())
            return;
        } catch (err) {
            dispatch(hideLoadingAction())
            if (404 === err.response.status) {
                Cookies.remove('user')
                Cookies.remove('login_id')
                Cookies.remove('access_token')
                history.push('/error')
                return;
            }
            dispatch(
                showAlertAction({
                    show: true,
                    variant: "error",
                    message: 'Ha ocurrido un error al procesar la solicitud.',
                })
            );
        }
    }

    const handleLogin = async () => {
        if (dataForm.option === "pass") await handleLoginPass()
        else await handleGenerateOTP();
    }

    const handleSaveSession = async (result) => {
        try {
            if (result === USER_PASSWORD_WRONG) {
                dispatch(
                    showAlertAction({
                        show: true,
                        variant: "warning",
                        message: 'Usuario o contraseña incorrecta.',
                    })
                );
                return;
            }
            if (result === USER_LOCKED) {
                dispatch(
                    showAlertAction({
                        show: true,
                        variant: "warning",
                        message: 'La cuenta del usuario esta bloqueada.',
                    })
                );
                return;
            }
            const userId = result.loginId;
            const token = result.authToken;
            const validity = result.validity;
            const refreshToken = result.refreshToken;
            Cookies.set('login_id', userId)
            Cookies.set('access_token', token)
            const res = await getUserDetailByEmail(userId)
            if (!res.Active) {
                Cookies.remove('user')
                Cookies.remove('login_id')
                Cookies.remove('access_token')
                this.setState({ isValid: false })
                return
            }
            const expireTimeMiliseconds = (+validity - 60)
            Cookies.set('access_token', token);
            Cookies.set('user', JSON.stringify({
                ...res,
                email:
                    userId,
                expire: Math.floor(Date.now() / 1000) + expireTimeMiliseconds,
                validity: expireTimeMiliseconds,
                token,
                refreshToken
            }))
            dispatch(hideLoadingAction())
            history.push('/principal')
        } catch (err) {
            throw err
        }
    }

    const returnValidEmail = () => {
        setStateForm(LOGIN_FORM);
        resetData();
    }

    useEffect(() => {
        resetData()
    }, [])

    const resetData = () => {
        setStateForm(LOGIN_FORM);
        setDataForm({ option: 'pass' })
    }

    const handleCreate = async () => {
        try {
            dispatch(showLoadingAction())
            await createUser({
                email: dataForm.email,
                pp: dataForm.pp
            })
            dispatch(hideLoadingAction())
            dispatch(
                showAlertAction({
                    show: true,
                    variant: "success",
                    message: 'Usuario creado con exito.',
                })
            );
            resetData();
            return;
        } catch (err) {
            dispatch(hideLoadingAction())
            console.log(err)
            dispatch(
                showAlertAction({
                    show: true,
                    variant: "error",
                    message: 'Ha ocurrido un error al procesar la solicitud.',
                })
            );
        }
    }

    const handleOpenPass = async () => {
        try {
            if (!dataForm.email) {
                dispatch(showAlertAction({ show: true, variant: "warning", message: "Ingresa un correo electronico" }))
                return
            }
            dispatch(showLoadingAction())
            await sendCode({ email: dataForm.email })
            setStateForm(PASS_FORM)
            dispatch(hideLoadingAction())
        } catch (err) {
            dispatch(hideLoadingAction())
            console.log(err)
            dispatch(
                showAlertAction({
                    show: true,
                    variant: "error",
                    message: 'Ha ocurrido un error al procesar la solicitud.',
                })
            );
        }
    }

    const isValidCreate = () => {
        return dataForm?.name && dataForm?.lastname && dataForm.passwordValid
    }

    const isValidReset = () => {
        return dataForm?.newPP && dataForm?.code && dataForm.passwordValid
    }

    const handleResetPP = async () => {
        try {
            dispatch(showLoadingAction())
            await resetPP({ email: dataForm.email, code: dataForm.code, pp: dataForm?.newPP })
            dispatch(hideLoadingAction())
            resetData();
            dispatch(
                showAlertAction({
                    show: true,
                    variant: "success",
                    message: 'Contraseña cambiada con exito.',
                })
            );
        } catch (err) {
            dispatch(hideLoadingAction())
            console.log(err)
            dispatch(
                showAlertAction({
                    show: true,
                    variant: "error",
                    message: 'Ha ocurrido un error al procesar la solicitud.',
                })
            );
        }
    }


    return (
        <View
            disabled={disabled}
            dataForm={dataForm}
            stateForm={stateForm}
            showPassword={showPassword}
            handleChange={handleChange}
            handleTogglePassword={handleTogglePassword}
            handleLogin={handleLogin}
            returnValidEmail={returnValidEmail}
            isValidCreate={isValidCreate}
            handleCreate={handleCreate}
            handleLoginOTP={handleLoginOTP}
            handleGenerateOTP={handleGenerateOTP}
            handleOpenPass={handleOpenPass}
            isValidReset={isValidReset}
            handleResetPP={handleResetPP}
            accountForm={accountForm}
        />
    )
}