import { environment } from "../config/config";
import { axiosWithToken } from "../config/axiosConfig";
export const getVisadosTypeByCountryId = async (countryId) => {

    const res = await axiosWithToken.get(`${environment.baseUrl}/VisaTypes/${countryId}`);
    if (res.data && res.data.Results) {
        return res.data.Results;
    }
    return [];
}


export const getDangerTypesByVisadoType = async(visaType) => {
    const res = await axiosWithToken.get(`${environment.baseUrl}/VisaApplicationDangerType/api/GetByVisaType/${visaType}`);
    if (res.data && res.data.Results) {
        return res.data.Results;
    }
    return [];
}

export const createVisaRequest = (data) => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data'
        }
    }
    return new Promise((resolve, reject) => {
        axiosWithToken.post(`${environment.baseUrl}/VisaApplication`, data, config)
            .then(result => {
                resolve(result.data.Results)
            })
            .catch(err => {
                reject(err)
            });
    });
}

export const getVisaReguirimentsFile = async (visaTypeId) => {

    const res = await axiosWithToken.get(`${environment.baseUrl}/RequirementByVisaType/${visaTypeId}?screenTypeId=3`);
    if (res.data && res.data.Results) {
        return res.data.Results;
    }
    return [];
}

export const getCompanyRequirementFiles = async (visaTypeId) => {

    const res = await axiosWithToken.get(`${environment.baseUrl}/RequirementByVisaType/${visaTypeId}?screenTypeId=1`);
    if (res.data && res.data.Results) {
        return res.data.Results;
    }
    return [];
}

export const getVisaAplicationByRequNumber = (requestNumber) => {
    return new Promise((resolve, reject) => {
        axiosWithToken.get(`${environment.baseUrl}/VisaRequestApplication/requestNumber/${requestNumber}`).then(result => {
            resolve(result.data.Results);
        }).catch(err => {
            reject(err);
        });
    });
}

export const getVisaThirdPartyRequestApplication = (email, filters) => {
    const emailEncript = window.btoa(email);
    const params = JSON.stringify(filters)
    return new Promise((resolve, reject) => {
        axiosWithToken.get(`${environment.baseUrl}/VisaRequestApplication/api/ThridPartyRequestApplication/${emailEncript}`, {params: filters}).then(result => {
            resolve(result.data.Results);
        }).catch(err => {
            reject(err);
        });
    })
}

export const getAllVisadosType = async () => {

    const res = await axiosWithToken.get(`${environment.baseUrl}/VisaTypes/api/visaTypes`);
    if (res.data && res.data.Results) {
        return res.data.Results;
    }
    return [];
}

export const getVisaThirdPartyCollaborators = (email, filters) => {
    const emailEncript = window.btoa(email);
    const params = JSON.stringify(filters)
    return new Promise((resolve, reject) => {
        axiosWithToken.get(`${environment.baseUrl}/VisaByCollaborator/api/ThridPartyCollaborator/${emailEncript}`,{params: filters}).then(result => {
            resolve(result.data.Results);
        }).catch(err => {
            reject(err);
        });
    })
}

export const updateAllVisaRequest = (id, data) => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data'
        }
    }
    return new Promise((resolve, reject) => {
        axiosWithToken.put(`${environment.baseUrl}/VisaApplication/${id}/updateAll`, data, config)
            .then(result => {
                resolve(result.data.Results);
            })
            .catch(err => {
                reject(err);
            });
    });
}

export const getAllStatus = () => {
    return new Promise((resolve, reject)=>{
        axiosWithToken.get(`${environment.baseUrl}/Status`).then(result => {
           resolve(result.data.Results);
        }).catch(err =>{
            reject(err);
        });
    });
}
