import {LOADING_ACTIONS} from '../actionsTypes/loading.types';

const INITIAL_STATE = {
  loading: false,
};

const loadingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_ACTIONS.SHOW: {
      return {...state, loading: action.payload};
    }
    case LOADING_ACTIONS.HIDE: {
      return {...state, loading: action.payload};
    }

    default:
      return state;
  }
};

export default loadingReducer;
