import { environment } from "../config/config";
import { axiosWithToken } from "../config/axiosConfig";
export const getVisaRequestMembersByRequestNumber = (visaAplicationId) => {
	return new Promise((resolve, reject) => {
		axiosWithToken
			.get(
				`${environment.baseUrl}/VisaRequestMembers/api/VisaRequestMembers/${visaAplicationId}`
			)
			.then((result) => {
				resolve(result.data.Results);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const addVisaColaborator = (data) => {
	const config = {
		headers: {
			"Content-type": "multipart/form-data",
		},
	};
	return new Promise((resolve, reject) => {
		axiosWithToken
			.post(`${environment.baseUrl}/VisaRequestMembers`, data, config)
			.then((result) => resolve(result.data.Results))
			.catch((err) => reject(err));
	});
};

export const updateVisaColaborator = (id, data) => {
	const config = {
		headers: {
			"Content-type": "multipart/form-data",
		},
	};
	return new Promise((resolve, reject) => {
		axiosWithToken
			.put(
				`${environment.baseUrl}/VisaRequestMembers/api/VisaRequestMembersUpdateAll/${id}`,
				data,
				config
			)
			.then((result) => resolve(result.data.Results))
			.catch((err) => reject(err));
	});
};

export const getVisaDurations = () => {
	return new Promise((resolve, reject) => {
		axiosWithToken
			.get(`${environment.baseUrl}/VisaDuration`)
			.then((result) => {
				resolve(result.data.Results);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const deleteColaborator = (idColb) => {
	return new Promise((resolve, reject) => {
		axiosWithToken
			.delete(`${environment.baseUrl}/VisaRequestMembers/${idColb}`)
			.then((result) => {
				resolve(result.data.Results);
			})
			.catch((err) => {
				reject(err);
			});
	});
};


export const validateColbaoratorCed = (ced, requestId) => {
	return new Promise((resolve, reject) => {
		axiosWithToken
			.get(
				`${environment.baseUrl}/VisaRequestMembers/api/VisaRequestMembersGetByAppliAndCed/${ced}?Id=${requestId}`
			)
			.then((result) => {
				resolve(result.data.Results);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
