import React, { Component } from 'react'
import LogoWM from '../../assets/imagen/Walmart_Logo.png';
import { Grid, Typography, Button } from '@material-ui/core'

import './styles.css';

export default class Index extends Component {

    render() {
        return (
            <div className='body-background'>
                <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" className="pt-5">
                    <Grid item xs={12} md={12} className="text-center mt-5">
                        <img src={LogoWM} alt="logo-walmart" className="logo-ca-wm" />
                        <Typography variant="h5" component="h5" className="my-4 text-white">
                            Lo sentimos no puedes acceder a esta página, no estás autorizado. Por favor cierre sesión e inténtelo de nuevo.
                        </Typography>
                        <Button href="/logout" variant="contained" color="inherit">Cerrar Sesión</Button>
                    </Grid>
                </Grid>
            </div>
        )
    }
}
