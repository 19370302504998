import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: "20px 30px",
	},
	container: {},
	containerCountries: {
		marginBottom: "20px",
	},
	containerPaper: {
		padding: "1rem",
	},
	containerPaperFiles: {
		padding: "1rem 0",
	},
	formControl: {
		minWidth: "90%",
	},
	inputText: {
		minWidth: "90%",
	},
	title: {
		fontSize: 21,
		color: theme.palette.primary.main,
		textAlign: "center",
	},
	titlePage: {
		fontSize: 25,
		color: theme.palette.primary.main,
		textAlign: "center",
		margin: 0,
		marginTop: "2rem",
	},
	containerInput: {
		marginTop: 20,
	},
	labelInput: {
		marginTop: 30,
		color: theme.palette.primary.main,
	},
	selectInput: {
		minWidth: "90%",
		padding: 0,
	},
	inputFile: {
		display: "none",
	},
	buttonAttach: {
		color: theme.palette.secondary.main,
		borderRadius: "0 5px 5px 0",
		boxShadow: "none",
		margin: 0,
		textTransform: "inherit",
	},
	buttonAttachLabel: {
		margin: 0,
	},
	containerButtonAttach: {
		display: "flex",
		alignItems: "center",
		width: "60%",
		flex: 1,
	},
	textButtonAttach: {
		margin: 0,
		backgroundColor: "#e0e0e0",
		fontSize: 14,
		padding: "6px 16px",
		color: "#777777",
		borderRight: "1px solid #b3b3b3",
		lineHeight: "1.75",
		borderRadius: "5px 0 0 5px",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	containerButtonAttachItem: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		borderBottom: "1px solid #e0e0e0",
		marginTop: 20,
		paddingBottom: 20,
		padding: "0 10px",
	},
	containerButtonAttachText: {
		flex: 1,
	},
	textButtonAttachItem: {
		margin: 0,
		color: "#777777",
	},
	textError: {
		color: "#f44336",
		fontSize: ".8rem",
	},
	buttonContainer: {
		marginTop: 40,
		textTransform: "none !important",
	},
	buttonSave: {
		marginTop: "1rem",
		textTransform: "none !important",
	},
	buttonCancel: {
		textTransform: "none !important",
        marginLeft: "4rem"
	},
	labelInputCheckbox: {
		width: '100%'
	},
	dangerZoneImage: {
		width: "30px",
		marginLeft: "10px"
	},
	inputCheckbox: {
		marginRight: "10px"
	},
	labelCheckbox: {
		marginTop: "0.5rem",
		color: theme.palette.primary.main,
		fontSize: "1.1rem"
	}

}));
