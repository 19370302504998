import { environment } from "../config/config";
import { axiosWithHeaders, axiosWithSig, axiosWithToken } from "../config/axiosConfig";
const baseUrl = '/auth'


export const loginUser = (data) => {
    return new Promise((resolve, reject) => {
        axiosWithSig
            .post(
                `${environment.baseUrl}${baseUrl}/login`, data
            )
            .then((result) => {
                resolve(result.data.Results);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const refreshToken = (data) => {
    return new Promise((resolve, reject) => {
        axiosWithSig
            .post(
                `${environment.baseUrl}${baseUrl}/refresh`, data
            )
            .then((result) => {
                resolve(result.data.Results);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getUserInfo = async (token) => {
    return new Promise((resolve, reject) => {
        axiosWithToken
            .get(
                `${environment.baseUrl}${baseUrl}/UserInfoIAM`
            )
            .then((result) => {
                resolve(result.data.Results);
            })
            .catch((err) => {
                reject(err);
            });
    });
};



export const logOut = async (data) => {
    return new Promise((resolve, reject) => {
        axiosWithToken.post(`${environment.baseUrl}${baseUrl}/logout`, data)
            .then(result => {
                resolve(result.data.Results)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const createUser = (data) => {
    return new Promise((resolve, reject) => {
        axiosWithSig
            .post(
                `${environment.baseUrl}${baseUrl}/create`, data
            )
            .then((result) => {
                resolve(result.data.Results);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const generateOTP = (data) => {
    return new Promise((resolve, reject) => {
        axiosWithSig
            .post(
                `${environment.baseUrl}${baseUrl}/otp/generate`, data
            )
            .then((result) => {
                resolve(result.data.Results);
            })
            .catch((err) => {
                reject(err);
            });
    });
};


export const validateOTP = (data) => {
    return new Promise((resolve, reject) => {
        axiosWithSig
            .post(
                `${environment.baseUrl}${baseUrl}/otp/validate`, data
            )
            .then((result) => {
                resolve(result.data.Results);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const sendCode = (data) => {
    return new Promise((resolve, reject) => {
        axiosWithSig
            .post(
                `${environment.baseUrl}${baseUrl}/password/resetCode`, data
            )
            .then((result) => {
                resolve(result.data.Results);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const resetPP = (data) => {
    return new Promise((resolve, reject) => {
        axiosWithSig
            .post(
                `${environment.baseUrl}${baseUrl}/password/reset`, data
            )
            .then((result) => {
                resolve(result.data.Results);
            })
            .catch((err) => {
                reject(err);
            });
    });
};


export const getSig = () => {
    return new Promise((resolve, reject) => {
        axiosWithHeaders
            .get(
                `${environment.baseUrl}${baseUrl}/signature`
            )
            .then((result) => {
                resolve(result.data.Results);
            })
            .catch((err) => {
                reject(err);
            });
    });
};