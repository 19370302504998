import React, { useEffect, useState } from "react";
//form components

import { ValidatorForm } from "react-material-ui-form-validator";

import {
	Grid,
	TextField,
	InputLabel,
	Button
} from "@material-ui/core/";
//Components
import FilesForm from "../../components/FilesForm/FilesForm";

import { useDispatch } from "react-redux";
import {
	showLoadingAction,
	hideLoadingAction,
} from "../../redux/actions/loading.actions";
import {
	getFile,
	getVisaCollabRequirementes,
	getVisaMemberFilesById,
} from "../../services/files.service";
import { useHistory, useParams } from "react-router-dom";
import { setTitlePageAction } from "../../redux/actions/title.actions";
import TableContent from "./components/TableData";
import useStyles from "./useStyles";
import {
	getVisaAplicationByRequNumber,
	getVisadosTypeByCountryId,
} from "../../services/visados.service";
import {
	getVisaRequestMembersByRequestNumber,
} from "../../services/colaborators.service";
import { getCompanyRegisterISD } from "../../services/company.service";

//constants
import { INVALID_DETAIL_VISA } from '../../utils/constants'

const inputCss = {
	style: {
		padding: 10,
	},
};
export default function ColaboratorsDetail({ auth }) {
	const [dataForm, setDataForm] = useState({
		identifyName: "",
		name: "",
		secondName: "",
		lastName: "",
	});
	const [colaboratorList, setColaboratorList] = useState([]);
	const [, setIsRegional] = useState(false);
	const [, setIsTemporal] = useState(false);
	const [errorsForm, setErorrsForm] = useState({});
	const [, setCompanyDataSaved] = useState({});
	const [requirementsFiles, setRequirementsFiles] = useState([]);
	const [savedFiles, setSavedFiles] = useState([]);
	const [, setSavedTemporalFiles] = useState([]);
	const [pageSize, setPageSize] = useState(25)
	const [, setUserInfo] = useState({});
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const urlParams = useParams();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
		initPage();
	}, []);

	const initPage = async () => {
		dispatch(setTitlePageAction({ text: "Colaboradores" }));
		dispatch(showLoadingAction());
		const user = auth.getUserInfo();
		setUserInfo(user);
		await getVisaAplicationInfo(urlParams.visaid, user.email);
		dispatch(hideLoadingAction());
	};

	const getColaboradoresList = async (id) => {
		try {
			const res = await getVisaRequestMembersByRequestNumber(id);
			const list = res.map((e, index) => {
				return { ...e, id: e.Id, index: index + 1 };
			});
			setColaboratorList(list);
		} catch (err) {
			console.log("err", err);
		}
	};

	const getVisaAplicationInfo = async (aplicationNumber, email) => {
		try {
			const res = await getVisaAplicationByRequNumber(aplicationNumber);
			if (INVALID_DETAIL_VISA.includes(res.Status)) {
				history.push(`/principal`);
				return
			}
			await getColaboradoresList(res.ApplicationId);
			await getColaboratorFiles(res.VisaTypeId);
			await getCompanyVendor(res.VendorId, email);
			await getVisaTypeInfo(res.CountryId, res.VisaTypeId);
			setIsRegional(res.Country === "Regional");
		} catch (err) {
			console.log("err", err);
		}
	};


	const getVisaTypeInfo = async (countryId, visaType) => {
		try {
			const visadosType = await getVisadosTypeByCountryId(countryId);
			const visaTypeSelect = visadosType.find((e) => e.ID === visaType);
			if (visaTypeSelect && visaTypeSelect.RegistrationCompany) {
				setIsTemporal(false);
			} else {
				setIsTemporal(true);
			}
			return;
		} catch (err) {
			dispatch(hideLoadingAction());
			return [];
		}
	};

	const getCompanyVendor = async (vendorId, email) => {
		try {
			dispatch(showLoadingAction());
			const res = await getCompanyRegisterISD(email, vendorId);
			if (res?.VendorId) {
				setCompanyDataSaved(res);
			}
			dispatch(hideLoadingAction());
			return res;
		} catch (err) {
			dispatch(hideLoadingAction());
			console.log("err company register", err);
		}
	};

	const getColaboratorFiles = async (visaType) => {
		try {
			const res = await getVisaCollabRequirementes(visaType);
			const requr = res.map((e) => {
				return {
					...e,
					name: e.RequirementName,
					nameInput: e.RequirementName,
					nameHiddenInput: e.RequirementName + "hidden",
					key: e.Id,
				};
			});
			setRequirementsFiles(requr);
		} catch (err) {
			console.log("err", err);
		}
	};


	const handleChangeDataForm = (name, state) => {
		setDataForm({ ...dataForm, [name]: state });
		if (errorsForm[name]) {
			const errors = { ...errorsForm, [name]: null };
			setErorrsForm(errors);
		}
	};


	const handleDetail = async (info) => {
		try {

			dispatch(showLoadingAction());
			setDataForm({
				identifyName: info.IdentificationNumber,
				name: info.FullName,
				secondName: info.SurName,
				lastName: info.LastName,
			})
			const filesColab = await getVisaMemberFilesById(info.Id);
			if (Array.isArray(filesColab) && filesColab.length > 0) {
				const files = {};
				filesColab.forEach((e) => {
					files[e.NameRequirement.trim()] = e;
				});
				setSavedTemporalFiles(files);
				setSavedFiles(files);
				window.scrollTo({
					top: 0,
					left: 0,
				});
			}
			dispatch(hideLoadingAction());

		} catch (err) {
			console.log(err);
			dispatch(hideLoadingAction());

		}
	}

	const handleRedirectDetail = () => {
		history.push(`/visa-detail/${urlParams.visaid}`)
	}

	const downloadMethod = async (file) => {
		const fileName = file.RouteRequirement.split("/").pop();

		dispatch(showLoadingAction());
		try {
			const formDataS = new FormData();
			formDataS.append("fileName", fileName);

			const res = await getFile(formDataS);
			let blob = new Blob([res], { type: "application/octet-stream" });
			let link = document.createElement("A");
			link.href = URL.createObjectURL(blob); // SET LINK ELEMENTS CONTENTS
			link.setAttribute("download", fileName); // SET ELEMENT CREATED 'ATTRIBUTE' TO DOWNLOAD, FILENAME PARAM AUTOMATICALLY
			link.click(); // TRIGGER ELEMENT TO DOWNLOAD
			link.remove();
			dispatch(hideLoadingAction());
		} catch (error) {
			dispatch(hideLoadingAction());
		}
	};

	return (
		<div>
			<ValidatorForm
				className={classes.root}
				autoComplete="off"
				onSubmit={() => {}}
				onError={()=>{}}
			>

				<Grid container className={classes.containerCountries} spacing={1}>
					{dataForm.identifyName.length > 0 && (
						<>
							<Grid item xs={6}>
								<div className={classes.containerInput}>
									<InputLabel id="identifyName" className={classes.labelInput}>
										* Número de identificación
									</InputLabel>
									<TextField
										className={classes.inputText}
										inputProps={inputCss}
										value={dataForm.identifyName}
										disabled
										name="identifyName"
										id="standard-basic"
										variant="outlined"
									/>
								</div>
							</Grid>
							<Grid item xs={6}>
								<div className={classes.containerInput}>
									<InputLabel id="name" className={classes.labelInput}>
										* Nombre
									</InputLabel>
									<TextField
										className={classes.inputText}
										inputProps={inputCss}
										value={dataForm.name}
										disabled
										name="name"
										id="standard-basic"
										variant="outlined"
									/>
								</div>
							</Grid>
							<Grid item xs={6}>
								<div className={classes.containerInput}>
									<InputLabel id="secondName" className={classes.labelInput}>
										* Primer Apellido
									</InputLabel>
									<TextField
										className={classes.inputText}
										inputProps={inputCss}
										value={dataForm.lastName}
										name="lastName"
										id="standard-basic"
										variant="outlined"
										disabled
									/>
								</div>
							</Grid>
							<Grid item xs={6}>
								<div className={classes.containerInput}>
									<InputLabel id="lastName" className={classes.labelInput}>
										* Segundo Apellido
									</InputLabel>
									<TextField
										className={classes.inputText}
										inputProps={inputCss}
										value={dataForm.secondName}
										name="secondName"
										id="standard-basic"
										variant="outlined"
										disabled
									/>
								</div>
							</Grid>
							<Grid item xs={12}>
								<FilesForm
									handleChangeDataForm={handleChangeDataForm}
									dataForm={dataForm}
									filesToUPload={requirementsFiles}
									errorsForm={errorsForm}
									// showDialogCustom={showDialogCustom}
									savedFiles={savedFiles}
									downloadMethod={downloadMethod}
									editMode={true}
								/>
							</Grid>
						</>
					)}
					<Grid item xs={12}>
						<div className={classes.title}>
							<h4>Colaboradores</h4>
						</div>
					</Grid>
					<Grid item xs={12}>
						<div className={classes.tableContainer}>
							<TableContent
								selectRows={[]}
								handleDetail={handleDetail}
								rowsThirdList={colaboratorList}
								pageSize={pageSize}
								setPageSize={setPageSize}
							/>
						</div>
					</Grid>
					<Grid item xs={6}>
						<div className={classes.buttonsContainer}>
							<Button
								variant="contained"
								className={classes.buttonSave}
								onClick={handleRedirectDetail}
							>
								Regresar
							</Button>
						</div>
					</Grid>
				</Grid>
			</ValidatorForm>
		</div>
	);
}
