import React, { useEffect, useState } from "react";

//form components

import InputLabel from "@material-ui/core/InputLabel";
import {
	TextValidator,
	ValidatorForm
} from "react-material-ui-form-validator";
import moment from "moment";
import {
	EMAIL_INPUT_NUMBER_MAX_MESSAGE,
	NORMAL_INPUT_TEXT,
	INPUT_ONLY_LETTER,
	INPUT_ONLY_LETTER_MESSAGE
} from "../../utils/input-rules";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
//Components
import AlertDialog from "../../components/AlertDialog/AlertDialog";
import FilesForm from "../../components/FilesForm/FilesForm";

import { useDispatch } from "react-redux";
import {
	showLoadingAction,
	hideLoadingAction,
} from "../../redux/actions/loading.actions";
import {
	creatVisaMemberFiles,
	getFile,
	getVisaCollabRequirementes,
	getVisaMemberFilesById,
	updateVisaMemberFiles,
} from "../../services/files.service";
import { useHistory, useParams } from "react-router-dom";
import { setTitlePageAction } from "../../redux/actions/title.actions";
import TableContent from "./components/TableData";
import useStyles from "./useStyles";
import {
	getAllStatus,
	getVisaAplicationByRequNumber,
	getVisadosTypeByCountryId,
	updateAllVisaRequest,
} from "../../services/visados.service";
import {
	addVisaColaborator,
	deleteColaborator,
	getVisaDurations,
	getVisaRequestMembersByRequestNumber,
	updateVisaColaborator,
	validateColbaoratorCed,
} from "../../services/colaborators.service";
import { getCompanyRegisterISD } from "../../services/company.service";
import { showAlertAction } from "../../redux/actions/alert.actions";
import {
	Tooltip
} from "@material-ui/core";
//constants
import {FILE_PREFIX} from '../../constants/api_proxy.constant'

const inputCss = {
	style: {
		padding: 10,
	},
};
export default function ColaboratorsForm({ auth }) {
	const [dataForm, setDataForm] = useState({
		identifyName: "",
		name: "",
		secondName: "",
		lastName: "",
	});
	const [editMode, setEditMode] = useState(false);

	const [openDialog, ] = useState(false);
	const [openDialogSubmit, setOpenDialogSubmit] = useState(false);
	const [openConfirmDelete, setConfimDelete] = useState(false);
	const [isRegional, setIsRegional] = useState(false);
	const [isTemporal, setIsTemporal] = useState(false);
	const [errorsForm, setErorrsForm] = useState({});
	const [companyDataSaved, setCompanyDataSaved] = useState({});
	const [colaboratorDelete, setColaboratorDelete] = useState({});
	const [visaEditAplication, setVisaEditAplication] = useState({});
	const [visaColaboratorEdit, setVisaColaboratorEdit] = useState({});
	const [requirementsFiles, setRequirementsFiles] = useState([]);
	const [visaDurationList, setVisaDurationList] = useState([]);
	const [colaboratorList, setColaboratorList] = useState([]);
	const [filesForm, setFilesForm] = useState([]);
	const [savedFiles, setSavedFiles] = useState([]);
	const [requestFiles, setRequestFiles] = useState([])
	const [pageSize, setPageSize] = useState(25)

	const [userInfo, setUserInfo] = useState({});
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const urlParams = useParams();

	const handleChange = (event) => {
		setDataForm((oldState) => ({
			...oldState,
			[event.target.name]: event.target.value,
		}));
	};

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
		initPage();
	}, []);

	const initPage = async () => {
		dispatch(setTitlePageAction({ text: "Registro de colaborador" }));
		dispatch(showLoadingAction());
		const user = auth.getUserInfo();
		setUserInfo(user);
		await getVisaAplicationInfo(urlParams.visaid, user.email);
		await getVisaDurationList();
		dispatch(hideLoadingAction());
	};

	const handleSubmitError = (errors) => {
		const errorsName = {};
		errors.forEach((element) => {
			errorsName[element.props.name] = "error";
		});
		setErorrsForm(errorsName);
	};

	const getColaboradoresList = async (id) => {
		try {
			const res = await getVisaRequestMembersByRequestNumber(id);
			const list = res.map((e, index) => {
				return { ...e, id: e.Id, index: index + 1 };
			});
			setColaboratorList(list);
		} catch (err) {
			console.log("err", err);
		}
	};

	const getVisaAplicationInfo = async (aplicationNumber, email) => {
		try {
			const res = await getVisaAplicationByRequNumber(aplicationNumber);
			await getColaboradoresList(res.ApplicationId);
			await getColaboratorFiles(res.VisaTypeId);
			await getCompanyVendor(res.VendorId, email);
			await getVisaTypeInfo(res.CountryId, res.VisaTypeId);
			setIsRegional(res.Country === "Regional");
			setVisaEditAplication(res);
		} catch (err) {
			console.log("err", err);
		}
	};


	const validateCedColaborator = async (newColaborator, visaAplicationId) => {
		try {
			dispatch(showLoadingAction());
			const res = await validateColbaoratorCed(newColaborator.identifyName, visaAplicationId);
			if (res && res.length > 0) {
				dispatch(hideLoadingAction());
				return true;
			}
			dispatch(hideLoadingAction());
			return false;
		} catch (err) {
			console.log('res ', err);
			return false;

		}
	}

	const getVisaTypeInfo = async (countryId, visaType) => {
		try {
			const visadosType = await getVisadosTypeByCountryId(countryId);
			const visaTypeSelect = visadosType.find((e) => e.ID === visaType);
			if (visaTypeSelect && visaTypeSelect.RegistrationCompany) {
				setIsTemporal(false);
			} else {
				setIsTemporal(true);
			}
			return;
		} catch (err) {
			dispatch(hideLoadingAction());
			return [];
		}
	};

	const getCompanyVendor = async (vendorId, email) => {
		try {
			dispatch(showLoadingAction());
			const res = await getCompanyRegisterISD(email, vendorId);
			if (res?.VendorId) {
				setCompanyDataSaved(res);
			}
			dispatch(hideLoadingAction());
			return res;
		} catch (err) {
			dispatch(hideLoadingAction());
			console.log("err company register", err);
		}
	};

	const getColaboratorFiles = async (visaType) => {
		try {
			const res = await getVisaCollabRequirementes(visaType);
			const requr = res.map((e) => {
				return {
					...e,
					name: e.RequirementName,
					nameInput: e.RequirementName,
					nameHiddenInput: e.RequirementName + "hidden",
					key: e.Id,
				};
			});
			setRequirementsFiles(requr);
		} catch (err) {
			console.log("err", err);
		}
	};
	const getVisaDurationList = async (visaType) => {
		try {
			const res = await getVisaDurations(visaType);
			setVisaDurationList(res);
		} catch (err) {
			console.log("err", err);
		}
	};
	const deleteColaboratorAction = async () => {
		try {
			dispatch(showLoadingAction());
			setConfimDelete(false);
			await deleteColaborator(colaboratorDelete.Id);
			await getColaboradoresList(visaEditAplication.ApplicationId);
			window.scrollTo({
				top: 0,
				left: 0,
			});
			dispatch(
				showAlertAction({
					show: true,
					variant: "success",
					message: "Colaborador eliminado correctamente.",
				})
			);
			dispatch(hideLoadingAction());
		} catch (err) {
			console.log("err", err);
		}
	};

	const handleSubmitForm = async () => {
		if (visaColaboratorEdit && visaColaboratorEdit.Id) {
			updateColaborator();
		} else {
			createColbarator();
		}
	};

	const updateColaborator = async () => {
		try {
			setOpenDialogSubmit(false);
			dispatch(showLoadingAction());
			const data = new FormData();

			let _duration = visaDurationList.filter((_d) => {
				return parseInt(_d.Months) === 12;
			});

			// Extract nested ternary into separate if-else statements
			if (isRegional) {
				_duration = visaDurationList.filter((_d) => {
					return parseInt(_d.Months) === 6;
				});
			} else if (isTemporal) {
				_duration = visaDurationList.filter((_d) => {
					return parseInt(_d.Months) === 3;
				});
			}

			const body = {
				Id: visaColaboratorEdit.Id,
				FullName: dataForm.name,
				LastName: dataForm.lastName,
				SurName: dataForm.secondName,
				IdentificationNumber: dataForm.identifyName,
				CreatedBy: userInfo.email,
				VisaApplicationId: visaEditAplication.ApplicationId,
				Duration: _duration[0],
				ExpirationDate: moment()
					.add(_duration[0].Months, "months")
					.utc()
					.utcOffset(-6)
					.format("YYYY/MM/DD"),
				CountriesRegional: isRegional ? companyDataSaved.Countrie : "",
				Commentary: "update",
				ModifiedBy: userInfo.email
			};
			data.append("metadata", JSON.stringify(body));

			await updateVisaColaborator(visaColaboratorEdit.Id, data);
			await updateColaboratorFiles(visaEditAplication.ApplicationId)
			await getColaboradoresList(visaEditAplication.ApplicationId);
			window.scrollTo(0, 0);
			dispatch(
				showAlertAction({
					show: true,
					variant: "success",
					message: "Colaborador actualizado correctamente.",
				})
			);
			clearForm();
			dispatch(hideLoadingAction());
		} catch (err) {
			dispatch(hideLoadingAction());
			console.log("err", err);
		}
	}

	const createColbarator = async () => {
		try {
			const exists = await validateCedColaborator(dataForm, visaEditAplication.ApplicationId);
			if (exists) {
				dispatch(
					showAlertAction({
						show: true,
						variant: "warning",
						message: "El número de identificación que está ingresando ya fue asignado a otro colaborador en esta misma solicitud.",
					})
				);
				return;
			}
			if (colaboratorList.length === 25) {
				dispatch(
					showAlertAction({
						show: true,
						variant: "warning",
						message: "Ha llegado a su límite de 25 colaboradores, si desea agregar más puede generar una nueva solicitud",
					})
				);
				return;
			}
			setOpenDialogSubmit(false);
			dispatch(showLoadingAction());
			const data = new FormData();

			let _duration = visaDurationList.filter((_d) => {
				return parseInt(_d.Months) === 12;
			});

			// Extract nested ternary into separate if-else statements
			if (isRegional) {
				_duration = visaDurationList.filter((_d) => {
					return parseInt(_d.Months) === 6;
				});
			} else if (isTemporal) {
				_duration = visaDurationList.filter((_d) => {
					return parseInt(_d.Months) === 3;
				});
			}

			const body = {
				FullName: dataForm.name,
				LastName: dataForm.lastName,
				SurName: dataForm.secondName,
				IdentificationNumber: dataForm.identifyName,
				CreatedBy: userInfo.email,
				VisaApplicationId: visaEditAplication.ApplicationId,
				Duration: _duration[0],
				ExpirationDate: moment()
					.add(_duration[0].Months, "months")
					.utc()
					.utcOffset(-6)
					.format("YYYY/MM/DD"),
				CountriesRegional: isRegional ? companyDataSaved.Countrie : "",
			};
			data.append("metadata", JSON.stringify(body));

			await addVisaColaborator(data);
			await saveColaboratorFiles(
				visaEditAplication.ApplicationId,
				dataForm.identifyName
			);
			await getColaboradoresList(visaEditAplication.ApplicationId);
			dispatch(
				showAlertAction({
					show: true,
					variant: "success",
					message: "Colaborador agregado correctamente.",
				})
			);
			window.scrollTo({
				top: 0,
				left: 0,
			});
			clearForm();
			dispatch(hideLoadingAction());
		} catch (err) {
			dispatch(hideLoadingAction());
			console.log("err", err);
		}
	}

	const sendRequest = async () => {
		if (editMode) {
			history.go(0)
			window.scrollTo({
				top: 0,
				left: 0,
			});
			return;
		}

		if (colaboratorList.length === 0) {
			dispatch(
				showAlertAction({
					show: true,
					variant: "warning",
					message: 'Debe agregar colaboradores antes de enviar la solicitud.',
				})
			);
			return null;

		}
		dispatch(showLoadingAction());
		try {
			const visaRequest = {
				ID: visaEditAplication.ApplicationId,
				RegistrationCompanyRequestId: companyDataSaved.Id,
				VisaTypeId: visaEditAplication.VisaTypeId,
				VendorId: visaEditAplication.VendorId,
				StatusId: "",
				email: visaEditAplication.email,
				CountryId: visaEditAplication.CountryId,
				ThirdCompanyName: companyDataSaved.BusinessName,
				LegalIdCompany: visaEditAplication.LegalIdCompany,
				Commentary: "envio",
			}
			const status = await getAllStatus();
			const stat = status.find(el => el.Name === 'Sin Asignar');
			if (stat) {
				visaRequest.StatusId = stat.Id;
			}
			const data = new FormData();
			visaRequest.ModifiedBy = userInfo.email;
			data.append('metadata', JSON.stringify(visaRequest));
			data.append('registerIsd', JSON.stringify({}));

			const resultForm = await updateAllVisaRequest(visaRequest.ID, data);

			dispatch(
				showAlertAction({
					show: true,
					variant: resultForm === 1 ? 'success' : 'error',
					message: resultForm === 1 ? 'Solicitud enviada con éxito, será revisada y contestada en un máximo de 5 días hábiles.' : 'Error al guardar la solicitud. Intente de nuevo.',
				})
			);

			if (resultForm === 1) {
				history.push(`/visa-list?country=${visaEditAplication.CountryId}&visaType=solicitudes`)
			}
		} catch (err) {
			console.log('err', err);
		}
		dispatch(hideLoadingAction());
	}


	const clearForm = () => {
		setDataForm({
			identifyName: "",
			name: "",
			secondName: "",
			lastName: "",
		});
		setEditMode(false);
		setVisaColaboratorEdit({});
		setSavedFiles({});
		setFilesForm([]);
	};

	const saveColaboratorFiles = async (visaAplicationId, cedula) => {
		if (filesForm.length === 0) {
			return;
		}
		try {
			const sendFiles = [];
			for (let i = 0; i < requirementsFiles.length; i++) {
				for (let x = 0; x < filesForm.length; x++) {
					if (requirementsFiles[i].name === filesForm[x].name) {
						sendFiles.push({
							...filesForm[x],
							...requirementsFiles[i],
						});
					}
				}
			}
			const data = new FormData();

			sendFiles.forEach((e) => {
				data.append(`${FILE_PREFIX}${e.name},${e.Id}`, e.file);
			});
			data.append(`CreatedBy`, window.btoa(userInfo.email));
			await creatVisaMemberFiles(visaAplicationId, cedula, data);
		} catch (err) {
			console.log("err save", err);
		}
	};

	const updateColaboratorFiles = async (visaAplicationId) => {
		if (filesForm.length === 0) {
			return;
		}
		try {
			const sendFiles = [];
			for (let i = 0; i < requirementsFiles.length; i++) {
				for (let x = 0; x < filesForm.length; x++) {
					if (requirementsFiles[i].name === filesForm[x].name) {
						sendFiles.push({
							...filesForm[x],
							...requirementsFiles[i],
						});
					}
				}
			}
			const data = new FormData();

			sendFiles.forEach((e) => {
				data.append(`${FILE_PREFIX}${requestFiles[e.name].Id}`, e.file);
			});
			data.append(`email`, window.btoa(userInfo.email));
			await updateVisaMemberFiles(visaAplicationId, data);
		} catch (err) {
			console.log("err save", err);
		}
	};

	const showConfirmAlert = () => {
		setOpenDialogSubmit(true);
	};
	const onCloseSubmitAlert = () => {
		setOpenDialogSubmit(false);
	};
	const onCloseSucessSave = () => { };
	const setUpladeFile = (file) => {
		setSavedFiles((oldState) => ({
			...oldState,
			[file.NameRequirement.trim()]: {},
		}));
	};

	const handleChangeDataForm = (name, state) => {
		setDataForm({ ...dataForm, [name]: state });
		if (errorsForm[name]) {
			const errors = { ...errorsForm, [name]: null };
			setErorrsForm(errors);
		}
	};
	const handleChangeFile = (nameInput, file) => {
		const filesExists = filesForm.filter((e) => e.name !== nameInput);

		setFilesForm([...filesExists, { name: nameInput, file: file }]);
	};

	const showConfirmDelete = (info) => {
		setColaboratorDelete(info)
		setConfimDelete(true);
	}

	const onCloseConfirmDelete = () => {
		setConfimDelete(false);
	}

	const setEditState = async (info) => {
		try {

			dispatch(showLoadingAction());
			setEditMode(true);
			setVisaColaboratorEdit(info);
			setDataForm({
				identifyName: info.IdentificationNumber,
				name: info.FullName,
				secondName: info.SurName,
				lastName: info.LastName,
			})
			const filesColab = await getVisaMemberFilesById(info.Id);
			if (Array.isArray(filesColab) && filesColab.length > 0) {
				const files = {};
				filesColab.forEach((e) => {
					files[e.NameRequirement.trim()] = e;
				});
				setRequestFiles(files)
				setSavedFiles(files);
				window.scrollTo({
					top: 0,
					left: 0,
				});
			}
			dispatch(hideLoadingAction());

		} catch (err) {
			console.log(err);
			dispatch(hideLoadingAction());

		}
	}


	const downloadMethod = async (file) => {
		const fileName = file.RouteRequirement.split("/").pop();

		dispatch(showLoadingAction());
		try {
			const formDataS = new FormData();
			formDataS.append("fileName", fileName);

			const res = await getFile(formDataS);
			let blob = new Blob([res], { type: "application/octet-stream" });
			let link = document.createElement("A");
			link.href = URL.createObjectURL(blob); // SET LINK ELEMENTS CONTENTS
			link.setAttribute("download", fileName); // SET ELEMENT CREATED 'ATTRIBUTE' TO DOWNLOAD, FILENAME PARAM AUTOMATICALLY
			link.click(); // TRIGGER ELEMENT TO DOWNLOAD
			link.remove();
			dispatch(hideLoadingAction());
		} catch (error) {
			dispatch(hideLoadingAction());
		}
	};

	return (
		<div>
			<ValidatorForm
				className={classes.root}
				autoComplete="off"
				onSubmit={showConfirmAlert}
				onError={handleSubmitError}
			>
				<Grid container className={classes.containerCountries} spacing={1}>
					<Grid item xs={6}>
						<Tooltip title={editMode ? "Este campo no es editable, para cambiarlo debe eliminar el colaborador" : ""}>
							<div className={classes.containerInput}>
								<InputLabel id="identifyName" className={classes.labelInput}>
									* Número de identificación
								</InputLabel>
								<TextValidator
									className={classes.inputText}
									inputProps={inputCss}
									value={dataForm.identifyName}
									onChange={handleChange}
									disabled={editMode}
									name="identifyName"
									id="standard-basic"
									variant="outlined"
									validators={NORMAL_INPUT_TEXT}
									errorMessages={EMAIL_INPUT_NUMBER_MAX_MESSAGE}
								/>
							</div>
						</Tooltip>
					</Grid>
					<Grid item xs={6}>
						<div className={classes.containerInput}>
							<InputLabel id="name" className={classes.labelInput}>
								* Nombre
							</InputLabel>
							<TextValidator
								className={classes.inputText}
								inputProps={inputCss}
								value={dataForm.name}
								onChange={handleChange}
								name="name"
								id="standard-basic"
								variant="outlined"
								validators={INPUT_ONLY_LETTER}
								errorMessages={INPUT_ONLY_LETTER_MESSAGE}
							/>
						</div>
					</Grid>
					<Grid item xs={6}>
						<div className={classes.containerInput}>
							<InputLabel id="secondName" className={classes.labelInput}>
								* Primer Apellido
							</InputLabel>
							<TextValidator
								className={classes.inputText}
								inputProps={inputCss}
								value={dataForm.lastName}
								onChange={handleChange}
								name="lastName"
								id="standard-basic"
								variant="outlined"

								validators={INPUT_ONLY_LETTER}
								errorMessages={INPUT_ONLY_LETTER_MESSAGE}
							/>
						</div>
					</Grid>
					<Grid item xs={6}>
						<div className={classes.containerInput}>
							<InputLabel id="lastName" className={classes.labelInput}>
								* Segundo Apellido
							</InputLabel>
							<TextValidator
								className={classes.inputText}
								inputProps={inputCss}
								value={dataForm.secondName}
								onChange={handleChange}
								name="secondName"
								id="standard-basic"
								variant="outlined"

								validators={INPUT_ONLY_LETTER}
								errorMessages={INPUT_ONLY_LETTER_MESSAGE}
							/>
						</div>
					</Grid>
					<Grid item xs={6}>
						<FilesForm
							handleChangeDataForm={handleChangeDataForm}
							dataForm={dataForm}
							filesToUPload={requirementsFiles}
							errorsForm={errorsForm}
							// showDialogCustom={showDialogCustom}
							handleChangeFile={handleChangeFile}
							savedFiles={savedFiles}
							updateFile={setUpladeFile}
							downloadMethod={downloadMethod}
							editMode={false}
						/>
					</Grid>
					<Grid item xs={12}>
						<div className={classes.textAlertContainer}>
							<p className={classes.textAlert}>
								Se acepta un máximo de 25 colaboradores por cada solicitud
							</p>
						</div>
					</Grid>
					<Grid item xs={12}>
						<div className={classes.tableContainer}>
							<TableContent
								selectRows={[]}
								handleEdit={setEditState}
								rowsThirdList={colaboratorList}
								pageSize={pageSize}
								setPageSize={setPageSize}
								deleteColaborator={showConfirmDelete}
							/>
						</div>
					</Grid>
					<Grid item xs={6}>
						<div className={classes.buttonsContainer}>
							<Button
								type="submit"
								color="secondary"
								variant="contained"
								className={classes.buttonSave}
							>
								{editMode ? "Actualizar" : "Guardar"}
							</Button>
							<Button
								type="button"
								variant="outlined"
								color="secondary"
								onClick={sendRequest}
								className={classes.buttonCancel}
							>
								{editMode ? "Regresar" : "Enviar"}
							</Button>
						</div>
					</Grid>
				</Grid>
				<AlertDialog
					open={openDialog}
					hiddeCancel={true}
					message={"Se ha guardado correctamente el registro de empresa"}
					onAcept={onCloseSucessSave}
				/>
				<AlertDialog
					open={openDialogSubmit}
					onClose={onCloseSubmitAlert}
					showWarningIcon={true}
					message={editMode ? "Confirmación de actualización de colaborador" : "Confirmación de registro de colaborador"}
					onAcept={handleSubmitForm}
				/>
				<AlertDialog
					open={openConfirmDelete}
					onClose={onCloseConfirmDelete}
					showWarningIcon={true}
					message={"¿Esta seguro de eliminar este colaborador?"}
					onAcept={deleteColaboratorAction}
				/>
			</ValidatorForm>
		</div>
	);
}
