import { ALERT_ACTIONS } from "../actionsTypes/alert.types";

const INITIAL_STATE = {
	show: false,
	message: "",
	timeout: 5000,
	variant: "success",
	color: ""
};

const alertReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ALERT_ACTIONS.SHOW_ALERT: {
			return { ...state, ...action.payload };
		}
		case ALERT_ACTIONS.HIDE_ALERT: {
			return { ...state, ...action.payload };
		}

		default:
			return state;
	}
};

export default alertReducer;
