import React, { Fragment, useEffect, useState } from 'react';

import { Route, useHistory } from "react-router-dom";

import Callback from '../components/Callback';
import Auth from './../config/Auth';

import { ProtectedRoute } from '../guards/Protected.route';
import { VisaApplicationList } from '../screens/VisaApplicationList/VisaApplicationList';
import Header from './../utils/header/Header';

import { Profile } from '../screens/Profile/Profile';
import { CompanyRegister } from '../screens/CompanyRegister/CompanyRegister';
import CompanyRegisterList from '../screens/CompanyRegisterList/CompanyRegisterList';
import Privacity from '../components/Privacity/Privacity';
import VisadoRequest from '../screens/VisadoRequest/VisadoRequest';
import ColaboratorsForm from '../screens/ColaboratorsForm/ColaboratorsForm';
import VisadoRequestDetail from '../screens/VisadoRequestDetail/VisadoRequestDetail'
import ColaboratorsDetail from '../screens/ColaboratorsDetail/ColaboratorsDetail';

const PrivateRoutes = () => {
    const [valid, setValid] = useState(false);
    const [actualRoute, setActualRoute] = useState("")
    const history = useHistory();

    useEffect(() => {
        initPage()
    }, [])

    history.listen((newRoute) => {
        setActualRoute(newRoute?.pathname)
    })

    useEffect(async () => {
        if (actualRoute) {

            const token = await Auth.isAuthenticated()
            if (!token) {
                Auth.logout();
                return;
            }
        }
    }, [actualRoute])

    const initPage = async () => {
        const token = await Auth.isAuthenticated()
        if (!token) {
            Auth.logout();
            return;
        }
        const userInfo = Auth.getUserInfo()
        setValid(true)
    }

    return (
        <>
            {valid && (
                <Fragment >
                    <ProtectedRoute path="/header" component={Header} />
                    <Route path="/callback" render={props => <Callback {...props} />} />
                    <Route path="/visa-list/" render={(props) => <VisaApplicationList {...props} auth={Auth} />} />
                    <Route path="/principal" render={(props) => <CompanyRegisterList {...props} auth={Auth} />} />
                    <Route path="/company-register-list" render={(props) => <CompanyRegisterList {...props} auth={Auth} />} />
                    <Route path="/privacidad" render={(props) => <Privacity {...props} auth={Auth} />} />
                    <Route path="/header" component={Header} />
                    <Route path="/profile" render={(props) => <Profile {...props} auth={Auth} />} />
                    <Route path="/visa-register" render={(props) => <VisadoRequest {...props} auth={Auth} />} />
                    <Route path="/visa-update/:visaid" render={(props) => <VisadoRequest {...props} auth={Auth} />} />
                    <Route path="/visa-detail/:visaid" render={(props) => <VisadoRequestDetail {...props} auth={Auth} />} />
                    <Route path="/colaborator-register/:visaid" render={(props) => <ColaboratorsForm {...props} auth={Auth} />} />
                    <Route path="/colaborator-detail/:visaid" render={(props) => <ColaboratorsDetail {...props} auth={Auth} />} />
                    <Route path="/company-register" render={(props) => <CompanyRegister {...props} auth={Auth} />} />
                    <Route path="/company-edit/:id" render={(props) => <CompanyRegister {...props} auth={Auth} />} />
                    <Route exact path="/" render={(props) => <CompanyRegisterList {...props} auth={Auth} />} />
                </Fragment>
            )}
            {!valid && <></>}
        </>
    )

}

export default PrivateRoutes;
