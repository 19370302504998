import { makeStyles, lighten } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { pdf } from '@react-pdf/renderer';
import moment from "moment";

//UI
import {
  Button,
  Grid,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

//Components
import VisaApplicationTable from "./components/VisaApplicationTable";
import FilterForm from "./components/FilterForm";
import Filter from "./components/Filter";
// Redux
import { setTitlePageAction } from "../../redux/actions/title.actions"
import { useDispatch } from "react-redux"
import { showLoadingAction, hideLoadingAction } from "../../redux/actions/loading.actions"
import { showAlertAction } from "../../redux/actions/alert.actions"

//Services
import {
  getVisadosTypeByCountryId,
  getVisaThirdPartyRequestApplication,
  getVisaThirdPartyCollaborators,
  getDangerTypesByVisadoType,
  getAllStatus
} from "../../services/visados.service";
import { getRegistrationCompanyApproved } from "../../services/company.service"
import { getCountriesListByEmail } from "../../services/countries.service";
import { getVisaDangerZonesSaved } from "../../services/dangerZones.service"
import { getVisaDurations } from "../../services/colaborators.service"

// Utils
import { PdfDocument } from '../../utils/visa-pdf'

//Constants
import { IGNORE_STATUS } from "../../utils/constants"


const useToolbarStyles = makeStyles((theme) => ({
  tableContainer: {
    width: "97%",
    margin: "auto",
    marginTop: "2rem",
    marginBottom: "5rem",
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
  boton: {
    boxSizing: "border-box",
    borderRadius: 18,
    border: "1px solid #0071CE",
    color: "#0071CE",
    marginLeft: 0,
  },
  container: {
    marginLeft: 25,
    marginTop: 50,
    width: "97%"
  },
  selectInput: {
    minWidth: '90%',
    padding: 0,
  },
  solicitudesRadio: {
    marginLeft: '177px',
    marginTop: '-50px'
  },
  inputLabel: {
    fontSize: 13,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  radiosContainer: {
    marginTop: 20,
  },
  buttonContainer: {
    marginTop: 20,
  }
}));




export const VisaApplicationList = ({ auth, location }) => {

  const initialFilter = {
    vendorSelected: null,
    visaTypeSelected: null,
    requestNumber: null,
    status: null,
    date: null,
    name: null,
    ced: null,
    expirationDate: null,
    duation: null
  }

  const classes = useToolbarStyles();
  const [countries, setCountries] = useState([])
  const [countrieSelected, setCountrieSelected] = useState(null)
  const [dataFilter, setDataFilter] = useState(initialFilter)
  const [visadosType, setVisadosType] = useState(null)
  const [filterSelected, setFilterSelected] = useState('')
  const [typeFilter, setTypeFilter] = useState([])
  const [vendorsList, setVendorsList] = useState([])
  const [userInfo, setUserInfo] = useState({});
  const [visadosTypeList, setVisadosTypeList] = useState([])
  const [statusTypeList, setStatusTypeList] = useState([])
  const [pageSize, setPageSize] = useState(25)
  const [dataTable, setDataTable] = useState([])
  const [dataTableCollabs, setDataTableCollabs] = useState([])
  const [visaDurations, setVisaDurations] = useState([])

  const dispatch = useDispatch();
  const history = useHistory();

  const filterParams = {
   
    solicitudes: {
      country: 'country',
      visaTypeId: 'visaTypeId',
      requestId: 'requestId',
      status: 'status',
      date: 'date',
      vendor: 'vendor',
      typeFilter: [
        { Id: 'Vendor', Name: 'Socio comercial' },
        { Id: 'VisaType', Name: 'Tipo de visado' },
        { Id: 'RequestNumber', Name: 'Número de solicitud' },
        { Id: 'Status', Name: 'Estado' },
        { Id: 'date', Name: 'Fecha solicitud' }
      ]
    },
    visados: {
      country: 6,
      vendor: 0,
      visaTypeId: 1,
      name: 2,
      ced: 3,
      expirationDate: 4,
      duration: 5,
      typeFilter: [
        { Id: 'Vendor', Name: 'Socio comercial' },
        { Id: 'VisaType', Name: 'Tipo de visado' },
        { Id: 'name', Name: 'Nombre o apellido' },
        { Id: 'ced', Name: 'Cédula' },
        { Id: 'expirationDate', Name: 'Fecha de vencimiento' },
        { Id: 'duration', Name: 'Duración' },
      ]
    }

  };


  useEffect(() => {
    dispatch(setTitlePageAction({ text: "Visados" }));
    initPage();
  }, []);

  useEffect(async () => {
    setFilterSelected((beforeState) => null)
    setDataFilter(initialFilter)
  }, [countrieSelected]);

  useEffect(async () => {
    if (visadosType == null) {
      return;
    }
    setFilterSelected((beforeState) => null)
    setTypeFilter((beforeState) => filterParams[visadosType].typeFilter)
    setDataFilter(initialFilter)
  }, [visadosType]);

  useEffect(async () => {
    try {
      dispatch(showLoadingAction());
      if (visadosType) {
        await handleFilter(userInfo.email, visadosType)
      }
      dispatch(hideLoadingAction());

    }
    catch (e) {
      console.log(e)
      dispatch(
        showAlertAction({
          show: true,
          variant: "error",
          message:
            `Ha ocurrido un error al intentar obtener la información`,
        })
      );
      dispatch(hideLoadingAction());
    }
  }, [dataFilter]);


  const initPage = async () => {
    dispatch(showLoadingAction());
    const user = auth.getUserInfo();
    const countries = await getCountriesListByEmail(user.email)
    setCountries(countries)
    //setVendorsList(vendors)
    setUserInfo(user);
    readyQueryParams(user.email)
    dispatch(hideLoadingAction());
  }

  const readyQueryParams = async (email) => {
    if(!location) {
      return;
    }
    const sp = new URLSearchParams(location.search);
    const countrie = sp.get("country")
    const visaType = sp.get("visaType")
    const vendor = sp.get("vendor")
    const status = sp.get("status")

    if (countrie == null) {
      return;
    }
    setCountrieSelected((oldState) => countrie)
    setVisadosType((oldState) => visaType != null ? visaType : 'visados')

    if (vendor) {
      setFilterSelected((oldState) => 'Vendor')
      await getVendors(email, countrie)
      setDataFilter({ ...initialFilter, vendorSelected: vendor })
      setTimeout(() => {
        return;
      }, 600);
    }

    if (status) {
      setFilterSelected((oldState) => 'Status')
      await getStatus(status)
      setTimeout(() => {
        return;
      }, 600);

    }

    return;
  };



  const redirectVisaRegister = () => {
    let params = countrieSelected ? "?country=" + countrieSelected : ""
    params += countrieSelected && filterSelected === "Vendor" && dataFilter.vendorSelected ? "&vendor=" + dataFilter.vendorSelected : ""
    history.push(`/visa-register${params}`);
  };

  const redirectVisaEdit = (params) => {
    history.push(`/visa-update/${params.RequestId}`);
  };

  const redirectVisaDetail = (params) => {
    history.push(`/visa-detail/${params.RequestId}`);
  };

  const handleChangeCountrie = async (event) => {
    setCountrieSelected(event.target.value)
  }

  const handleChangeVisadosType = async (event) => {
    setVisadosType(event.target.value)
  }

  const handleChangeFilterSelected = async (event) => {
    if (event.target.value === 'VisaType') {
      let visadosType = await getVisadosTypeByCountryId(countrieSelected);
      visadosType = visadosType?.map((x) => {
        return { Id: x.ID, Name: x.Name }
      })
      setVisadosTypeList(visadosType);
    }
    if (event.target.value === 'Status') {
      await getStatus(null)
    }

    if (event.target.value === 'Vendor') {
      await getVendors(userInfo.email, countrieSelected)
    }

    if (event.target.value === 'duration') {
      let durationList = await getVisaDurations()
      durationList = durationList?.map(x => {
        return { Id: x.Description, Name: x.Description }
      })
        .filter((value, index, self) => self?.map(item => item.Name).indexOf(value.Name) == index)
      setVisaDurations((beforeState) => durationList)
    }
    setFilterSelected(event.target.value)
  }

  const getVendors = async (email, countrieSelected) => {
    let vendorList = await getRegistrationCompanyApproved(email, countrieSelected)
    vendorList = vendorList?.map(x => {
      return { Id: x.VendorId, Name: x.Company }
    })
    setVendorsList(vendorList)
  }

  const getStatus = async (selected) => {
    let statusList = await getAllStatus();
    statusList = statusList.filter(x => !IGNORE_STATUS.includes(x.Name))
    setStatusTypeList(statusList);

    if(selected){
      const selectedStatus = statusList.find(x => x.Name == selected)
      if(selectedStatus) {
        setDataFilter({...initialFilter, status: selectedStatus.Id})
      }
    }

  }

  const handleChangeDataFilter = async (event) => {
    setDataFilter({ ...initialFilter, [event.target.name]: event.target.value })
  }

  const handleOnKeyPressDataFilter = async (event) => {
    if (event.key === 'Enter') {
      setDataFilter({...initialFilter, [event.target.name]: event.target.value})
    }
  }

  const handleChangeDataDateFilter = async (name, event) => {
    setDataFilter({ ...initialFilter, [name]: event })
  }

  const handleFilter = async (email, visadosType) => {
    let filter = {}

    if (countrieSelected == null) {
      return;
    }
    filter = {country:countrieSelected}
    if (dataFilter.visaTypeSelected) {
      filter = {...filter, visaTypeId: dataFilter.visaTypeSelected}
    }
    if (dataFilter.requestNumber) {
      filter = {...filter, requestNumber: dataFilter.requestNumber}
    }
    if (dataFilter.status) {
      filter = {...filter,status: dataFilter.status}
    }
    if (dataFilter.date) {
      filter = {...filter,date: moment(dataFilter.date).format("YYYY-MM-DD") }
    }
    if (dataFilter.vendorSelected) {
      filter = {...filter, vendor: dataFilter.vendorSelected}
    }
    if (dataFilter.name) {
      filter = {...filter, name: dataFilter.name}
    }
    if (dataFilter.ced) {
      filter = {...filter, ced: dataFilter.ced}
    }
    if (dataFilter.expirationDate) {
      filter = {...filter, expirationDate: moment(dataFilter.expirationDate).format("YYYY-MM-DD")}
    }
    if (dataFilter.duration) {
      filter = {...filter, duration: dataFilter.duation}
    }
    await getDataTables(email, filter, visadosType)
  }

  const getDataTables = async (email, filter, visadosType) => {
    console.log(filter)
    if (visadosType === "solicitudes") {
      const res = await getVisaThirdPartyRequestApplication(email, filter)
      setDataTable(res)
    }
    else if (visadosType === "visados") {
      const res = await getVisaThirdPartyCollaborators(email, filter)
      setDataTableCollabs(res)
    }
  }




  const redirectVisaDownload = async (params) => {
    try {
      dispatch(showLoadingAction());
      const dangerTypes = await getDangerTypesByVisadoType(params.VisaTypeId)
      let dangerTypesSaved = await getVisaDangerZonesSaved(params.VisaApplicationId);
      const dangerTypesSavedInfo = []
      if (dangerTypesSaved.length > 0) {
        dangerTypesSaved = dangerTypesSaved[0]
        if (dangerTypesSaved.DangerType1 != 0) {
          dangerTypesSavedInfo.push(dangerTypes[0])
        }
        if (dangerTypesSaved.DangerType2 != 0) {
          dangerTypesSavedInfo.push(dangerTypes[1])
        }
        if (dangerTypesSaved.DangerType3 != 0) {
          dangerTypesSavedInfo.push(dangerTypes[2])
        }
        if (dangerTypesSaved.DangerType4 != 0) {
          dangerTypesSavedInfo.push(dangerTypes[3])
        }
      }

      const dataColab = {
        vendor: params.Vendor,
        name: params.Name,
        surName: params.SurName,
        lastName: params.LastName,
        ced: params.Ced,
        visaApplicationId: params.VisaApplicationId,
        expirationDate: params.ExpirationDate
      }

      const blob = await pdf(<PdfDocument data={params} dataColb={dataColab} dangerTypes={dangerTypesSavedInfo} />).toBlob();
      const url = URL.createObjectURL(blob);

      let link = document.createElement('a');
      link.href = url;
      link.download = `${params.Vendor} - ${params.Name} ${params.LastName} ${params.SurName} - Visado.pdf`;
      document.body.appendChild(link);
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      link.remove();

      URL.revokeObjectURL(url);
      dispatch(hideLoadingAction());
    }
    catch (e) {
      console.log(e)
      dispatch(
        showAlertAction({
          show: true,
          variant: "error",
          message:
            `Ha ocurrido un error al intentar descargar el visado`,
        })
      );
      dispatch(hideLoadingAction());
    }
  };

  return (
    <>
      <Grid container className={classes.container} >
        <Grid item xs={12} md={5}>
          <Filter
            labelText={"País"}
            labelName={"countrie"}
            variant={"outlined"}
            value={countrieSelected}
            handleChange={handleChangeCountrie}
            data={countries}
            variantFilter={'select'}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.radiosContainer} >
            <RadioGroup
              row
              aria-label="Visados"
              name="visadosType"
              value={visadosType}
              onChange={handleChangeVisadosType}
            >
              <FormControlLabel
                value="visados"
                disabled={countrieSelected == null}
                control={<Radio />}
                label="Visados generados"
              />

              <FormControlLabel
                value="solicitudes"
                disabled={countrieSelected == null}
                control={<Radio />}
                label="Solicitudes de visado"
              />
            </RadioGroup>
          </div>
        </Grid>
        <Grid item xs={12} md={3}  className={classes.buttonContainer}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.boton}
            onClick={() => redirectVisaRegister()}
          >
            + Solicitar Visado
          </Button>
        </Grid>
        <br />
        <hr />
      </Grid>
      {visadosType
        ?
        <>
          <FilterForm
            handleChangeFilterSelected={handleChangeFilterSelected}
            filterData={typeFilter}
            filterSelected={filterSelected}
            handleChangeDataFilter={handleChangeDataFilter}
            dataFilter={dataFilter}
            visadosTypeList={visadosTypeList}
            statusTypeList={statusTypeList}
            handleOnKeyPressDataFilter={handleOnKeyPressDataFilter}
            handleChangeDataDateFilter={handleChangeDataDateFilter}
            vendorsList={vendorsList}
            visaDurations={visaDurations}

          />
          <div className={classes.tableContainer}>
            <VisaApplicationTable
              selectRows={[]}
              rows={visadosType === 'solicitudes' ? dataTable : dataTableCollabs}
              pageSize={pageSize}
              setPageSize={setPageSize}
              variant={visadosType}
              redirectVisaDetail={redirectVisaDetail}
              redirectVisaEdit={redirectVisaEdit}
              redirectVisaDownload={redirectVisaDownload}
            />
          </div>
        </>
        : null
      }
    </>

  )


};

export default VisaApplicationList;
