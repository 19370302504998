import React from "react";
import {
    Grid,
    TextField,
    InputLabel,
    Button
} from "@material-ui/core/";

//Components
import FilesForm from "../../../components/FilesForm/FilesForm";
import CommentsForm from "../../../components/CommentsForm/CommentsForm";

//Assets
import Electrico from '../../../assets/danger-types/electrico.png';
import Altura from '../../../assets/danger-types/en-altura.png';
import Espacio from '../../../assets/danger-types/en-espacio-confinado.png';
import Caliente from '../../../assets/danger-types/en-caliente.png';

export const VisadoInfo = props => {

    const {
        data,
        classes,
        registerISD,
        visaFiles,
        requirementsFiles,
        errorsForm,
        handleChangeDataForm,
        listCommentaries,
        createCommentary,
        dataForm,
        downloadMethod,
        dangerTypes,
        handleRedirectColaborator,
        handleRedirectList
    } = props

    return (
        <div>
            <Grid
                container
                spacing={1}
            >
                <Grid
                    item
                    md={6}
                    xs={12}
                    className={classes.item}
                >
                    <InputLabel shrink htmlFor="country" className={classes.labelInput}>
                        País
                    </InputLabel>
                    <TextField
                        fullWidth
                        id={'country'}
                        value={data.Country || ''}
                        variant='outlined'
                        size='small'
                        disabled

                    />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                    className={classes.item}
                >
                    <InputLabel shrink htmlFor="Vendor" className={classes.labelInput}>
                        Vendor
                    </InputLabel>
                    <TextField
                        fullWidth
                        id={'Vendor'}
                        value={data.CompanyVendor || ''}
                        variant='outlined'
                        disabled
                        size='small'
                    />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                    className={classes.item}
                >
                    <InputLabel shrink htmlFor="visaType" className={classes.labelInput}>
                        Tipo de visado
                    </InputLabel>
                    <TextField
                        fullWidth
                        id={'visaType'}
                        value={data.VisaType || ''}
                        variant='outlined'
                        disabled
                        size='small'

                    />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                    className={classes.item}
                >
                    <InputLabel shrink htmlFor="businessName" className={classes.labelInput}>
                        Nombre empresa socio (Razón social)
                    </InputLabel>
                    <TextField
                        fullWidth
                        id={'businessName'}
                        value={registerISD.BusinessName || ''}
                        variant='outlined'
                        disabled
                        size='small'

                    />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                    className={classes.item}
                >
                    <InputLabel shrink htmlFor="legalId" className={classes.labelInput}>
                        Cédula jurídica empresa socio
                    </InputLabel>
                    <TextField
                        fullWidth
                        id={'legalId'}
                        value={registerISD.LegalId || ''}
                        variant='outlined'
                        disabled
                        size='small'

                    />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                    className={classes.item}
                >
                    <InputLabel shrink htmlFor="legalRepresentative" className={classes.labelInput}>
                        Nombre representante legal
                    </InputLabel>
                    <TextField
                        fullWidth
                        id={'legalRepresentative'}
                        value={registerISD.LegalRepresentative || ''}
                        variant='outlined'
                        disabled
                        size='small'

                    />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                    className={classes.item}
                >
                    <InputLabel shrink htmlFor="email" className={classes.labelInput}>
                        Correo electrónico para notificaciones
                    </InputLabel>
                    <TextField
                        fullWidth
                        id={'email'}
                        value={data.email || ''}
                        variant='outlined'
                        disabled
                        size='small'
                    />
                </Grid>
                {dangerTypes.length > 0 && (
                    <Grid item xs={12} className={classes.item}>
                        {dangerTypes.map((dangerType) => {

                            // Extract nested ternary into an independent function
                            const renderDangerTypeImage = () => {
                                if (dangerType.VisaDangerTypeName.includes("Eléctrico")) {
                                    return <img src={Electrico} className={classes.dangerZoneImage} />;
                                }
                                if (dangerType.VisaDangerTypeName.includes("Altura")) {
                                    return <img src={Altura} className={classes.dangerZoneImage} />;
                                }
                                if (dangerType.VisaDangerTypeName.includes("Espacio")) {
                                    return <img src={Espacio} className={classes.dangerZoneImage} />;
                                }
                                if (dangerType.VisaDangerTypeName.includes("Caliente")) {
                                    return <img src={Caliente} className={classes.dangerZoneImage} />;
                                }
                                return null;
                            };

                            return (
								// See Walmart.VisadoSeguridad.Api.Repository.Entities.VisaDangerTypesByVisaType for reference
								<div key={dangerType.ID}>
                                    <input
                                        type="checkbox"
                                        id={dangerType.VisaDangerTypeName}
                                        checked={dangerType.selected}
                                        disabled
                                        name={dangerType.VisaDangerTypeName}
                                        className={classes.inputCheckbox}
                                    />
                                    <label
                                        for={dangerType.VisaDangerTypeName}
                                        className={classes.labelCheckbox}
                                    >
                                        {dangerType.VisaDangerTypeName}
                                    </label>
                                    {renderDangerTypeImage()}
                                </div>
                            );
                        })}
                    </Grid>
                )}
                <Grid
                    item
                    md={12}
                    xs={12}
                    className={classes.item}
                >
                    <h3 className={classes.title}>Documentos</h3>
                    <FilesForm
                        filesToUPload={requirementsFiles}
                        savedFiles={visaFiles}
                        editMode={false}
                        errorsForm={errorsForm}
                        dataForm={dataForm}
                        downloadMethod={downloadMethod}
                        handleChangeDataForm={handleChangeDataForm}
                    />
                </Grid>
                <Grid item xs={12} className={classes.item}>
                    <CommentsForm
                        commentaryList={listCommentaries}
                        dataForm={dataForm}
                        showBox={true}
                        editMode={true}
                        update={true}
                        createCommentary={createCommentary}
                        handleChangeDataForm={handleChangeDataForm}
                        showCommentaries={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={handleRedirectColaborator}
                    >
                        Ver colaboradores
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.buttonBack}
                        onClick={handleRedirectList}
                    >
                        Regresar
                    </Button>
                </Grid>
            </Grid>
        </div>
    )

}


export default VisadoInfo
