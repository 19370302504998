import React from 'react'
import { Grid, Container, Typography, Paper } from '@material-ui/core'

export default function Privacity() {

        return (
            <Container fixed>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <Paper className="p-4 my-3">
                            <Typography component="h5" variant="h5" className="mb-4 title">
                                AVISO DE PRIVACIDAD
                            </Typography>
                            <Typography className="my-3 text-justify" >
                                Walmart de Centroamerica¹ le informa, y usted así lo consiente y/o autoriza, que los datos personales solicitados, suministrados por usted y/o recolectados a través de sitio y/o plataforma serán almacenados, tratados y manejados por Walmart de Centroamerica¹ con la finalidad de tramitar (emitir o no) el permiso o certificado que permite el acceso de sus colaboradores o personal subcontratado a las instalaciones de Walmart de Centroamerica¹, conforme al contrato de servicios correspondiente o a los fines de desarrollar las actividades comerciales que les hayan sido asignadas por el proveedor que corresponda. Igualmente, se le informa , y usted así lo consiente y/o autoriza, que Walmart de Centroamerica¹ podrá  hacer las revisiones necesarias para confirmar y verificar la autenticidad y veracidad de dichos datos personales, a través de fuentes licitas.
                        </Typography>
                            <Typography className="my-3 text-justify" >
                                Al suministrar la información o permitir que sea recolectada, usted nos autoriza a utilizar sus datos conforme a lo antes señalado, en forma directa o a través de terceros intermediaros, nacionales o extranjeros, contratados a tales fines, y por consiguiente, usted autoriza la transferencia de sus datos a dichos terceros intermediarios para cumplir con dicho objetivo. Igualmente nos autoriza a transferir sus datos a: (i) empresas, nacionales o internacionales, del mismo grupo de Walmart de Centroamerica¹ como subsidiarias, filiales, afiliadas, controladas o controladoras de Walmart de Centroamerica¹; (ii) servidores externos o “nubes” propiedad de terceros, nacionales o extranjeros, contratados a los fines de ampliar la capacidad de almacenaje de la información o para el manejo más adecuado y ágil de dicha información; y  (iii) terceros nacionales o extranjeros para realizar procesos de monitoreo o auditoría para el cumplimiento de la legislación aplicable y de nuestras políticas internas. Igualmente, podremos suministrar los datos suministrados o recolectados por requerimiento de autoridades judiciales o administrativa, en cumplimiento de obligaciones legales o reglamentarias. Estas transferencias de datos personales serán realizadas con todas las medidas de seguridad apropiadas de acuerdo a las políticas internas de Walmart de Centroamerica¹ y de acuerdo a la legislación aplicable.
                        </Typography>
                            <Typography className="my-3 text-justify" >
                                En caso de que ocurra una vulneración de seguridad en cualquier fase del tratamiento de sus Datos Personales y sensibles, Walmart¹ lo hará del conocimiento general a través del correo que usted nos ha suministrado. En caso de realizar alguna modificación o actualización al presente Aviso de Privacidad se hará   de su conocimiento en la presente página.
                        </Typography>
                            <Typography className="my-3 text-justify" >
                                En cualquier momento usted podrá ejercer su derecho de acceso, rectificación u oposición al uso de sus datos personales a través de la medios de contacto indicados en la presente plataforma en la sección de requisitos. También podrá solicitar la revocación de su consentimiento al tratamiento de sus datos personales o la eliminación de sus datos personales de las bases de datos  o listas de distribución de Walmart de Centroamerica¹, quedando entendido que dicha revocatoria implicaría la anulación del permiso y/o certificado que permite el acceso de sus colaboradores o personal subcontratado a las instalaciones de Walmart de Centroamerica¹ emitido en base a dichos datos.
                        </Typography>
                            <Typography component="p" variant="caption">
                                Por Walmart de Centroamérica debe entenderse cómo cualquier compañía filial, subsidiaria, o propiedad de Wal-mart Stores, Inc. en Centroamérica.
                        </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        )
}
