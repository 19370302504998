import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import {
    getUserInfo,
} from '../services/auth.service'

//Components
import Loading from './Loading';

//Services
import { getUserDetailByEmail } from "../services/profile.service";

//Constants
import { EXPIRE_TIME } from "../utils/constants"

//Cookie
import Cookies from 'js-cookie'

class Callback extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isValid: true
        }
    }

    async componentDidMount() {
        if (/callback|grant_type|client_id|code|error/.test(this.props.location?.pathname)) {
            const token = ""
            try {
                Cookies.set('login_id', "JonhDoe@gmail.com")
                const userInfo = await getUserInfo(token)
                if (!userInfo) {
                    this.setState({ isValid: false })
                    Cookies.remove('user')
                    Cookies.remove('login_id')
                    Cookies.remove('access_token')
                    return
                }
                Cookies.set('login_id', userInfo?.data?.email)
                const res = await getUserDetailByEmail(userInfo?.data?.email)
                if (!res.Active) {
                    Cookies.remove('user')
                    Cookies.remove('login_id')
                    Cookies.remove('access_token')
                    this.setState({ isValid: false })
                    return
                }
                userInfo.expire = new Date().getTime() + EXPIRE_TIME;
                userInfo.token = token
                Cookies.set('access_token', token)
                Cookies.set('user', JSON.stringify({...res, email: userInfo?.data?.email}))
                this.props.history.push('/principal')
                return;
            } catch (err) {
                console.log(err)
                this.setState({ isValid: false })
                Cookies.remove('user')
                Cookies.remove('access_token')
                Cookies.remove('login_id')
            }
        }

    }

    render() {
        const { auth } = this.props
        const { isValid } = this.state
        return isValid ? <Loading{...this.props} auth={auth} /> : <Redirect to="/error" />
    }
}


export default connect(null)(Callback);
