export const LOADING_ACTIONS = {
  SHOW: 'SHOW',
  HIDE: 'HIDE',
};

export const showLoadingAction = () => (dispatch, getPrevState) => {
  dispatch({
    type: LOADING_ACTIONS.SHOW,
    payload: true,
  });
};

export const hideLoadingAction = () => (dispatch, getPrevState) => {
  dispatch({
    type: LOADING_ACTIONS.HIDE,
    payload: false,
  });
};
