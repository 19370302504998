import React from "react";
import { WALMART_LOGO } from "../../assets/assets";
import CalculatedIcon from "../CalculatedIcon";
import Auth from "../../config/Auth";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Sidebar from "../../components/Sidebar";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import settinsImage from "./../../assets/imagen/settings.png";
import exitImage from "./../../assets/imagen/exit.png";
import {
	showLoadingAction,
	hideLoadingAction,
} from "../../redux/actions/loading.actions";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginLeft: 5,
	},
	title: {
		flexGrow: 1,
		textAlign: "center",
	},
	logo: {
		marginLeft: "500px",
	},
	imageIcon: {
		width: "20px",
		marginRight: "10px",
	},
	textMenu: {
		color: theme.palette.text.custom,
	},
	textMenuBold: {
		color: theme.palette.text.custom,
		fontWeight: "bold",
	},
}));

const Header = ({ children }) => {
	const classes = useStyles();
	const [auth] = React.useState(true);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const history = useHistory();
	const titleReducer = useSelector(({ titleReducer }) => titleReducer);
	const dispatch = useDispatch();

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleUser = () => {
		history.push("/profile");
	};

	const handleLogOut = async () => {
		try {
			dispatch(showLoadingAction());
			await Auth.logout()
			dispatch(hideLoadingAction());
		} catch (err) {
			dispatch(hideLoadingAction());
		}
	}

	return (
		<div className={classes.root}>
			<AppBar position="static" style={{ backgroundColor: "#041F41" }}>
				<Toolbar>
					<Sidebar
						edge="start"
						className={classes.menuButton}
						color="inherit"
						aria-label="menu"
					/>

					<CalculatedIcon
						style={classes.menuButton}
						{...WALMART_LOGO}
						className={classes.imagenLogo}
					/>
					<Typography variant="h6" className={classes.title}>
						<div>{titleReducer.text}</div>
					</Typography>
					{auth && (
						<div>
							<IconButton
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleMenu}
								color="inherit"
							>
								<AccountCircle />
							</IconButton>
						</div>
					)}
					<h4>{children}</h4>
					<Menu
						id="menu-appbar"
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						keepMounted
						transformOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						open={open}
						onClose={handleClose}
					>
						<MenuItem onClick={handleUser} className={classes.textMenu}>
							<img className={classes.imageIcon} src={settinsImage}></img>
							Configuración perfil
						</MenuItem>
						<MenuItem
							onClick={() => {
								handleClose(); // Cerrar el menu
								handleLogOut(); // Realiza el proceso del logout
							}}
							className={classes.textMenuBold}
						>
							<img className={classes.imageIcon} src={exitImage}></img>
							Cerrar Sesión
						</MenuItem>
					</Menu>
				</Toolbar>
			</AppBar>
		</div>
	);
};

export default Header;
