import { combineReducers } from 'redux';
import loadingReducer from './loading.reducer';
import alertReducer from './alert.reducer'
import titleReducer from './title.reducer'

const rootReducer = combineReducers({
    loadingReducer,
    alertReducer,
    titleReducer
})

export default rootReducer;
