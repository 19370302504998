import axios from 'axios';
import Cookies from 'js-cookie'
import history from '../history';
import { environment } from './config';
import { getSig } from '../services/auth.service';

export const axiosWithToken = axios.create();
export const axiosWithHeaders = axios.create();
export const axiosWithSig = axios.create();


//Headers and Token
axiosWithToken.interceptors.request.use(function (config) {
    config.headers.Authorization = Cookies.get('access_token');
    config.headers.visado_origin = environment.ORIGIN_THIRD;
    config.headers.loginId = Cookies.get('login_id');
    config.headers["WM_SEC.AUTH_TOKEN"] = Cookies.get('access_token');
    config.headers["WM_CONSUMER.ID"] = environment.service_registry["WM_CONSUMER.ID"];
    config.headers["WM_SVC.ENV"] = environment.service_registry["WM_SVC.ENV"];
    config.headers["WM_SVC.NAME"] = environment.service_registry["WM_SVC.NAME"];
    config.headers["Wm_svc.Version"] = environment.service_registry["Wm_svc.Version"];
    config.headers["Wm_sec.Auth_id"] = Cookies.get('login_id');
    return config
}, function (error) {
    return Promise.reject(error);
});

axiosWithToken.interceptors.response.use(function (response) {
    return response;
}, async function (error) {
    if (401 === error.response.status) {
        Cookies.remove('user')
        Cookies.remove('access_token')
        history.replace("/error");
        window.location.reload()
        return;
    }
    return Promise.reject(error);
});

//Headers and signature
axiosWithSig.interceptors.request.use(async function (config) {
    try {
        const sig = await getSig();
        config.headers.Authorization = Cookies.get('access_token');
        config.headers.visado_origin = environment.ORIGIN_THIRD;
        config.headers.loginId = Cookies.get('login_id');
        config.headers["WM_SEC.AUTH_SIGNATURE"] = sig?.signature;
        config.headers["WM_CONSUMER.INTIMESTAMP"] = sig?.timestamp;
        config.headers["WM_CONSUMER.ID"] = environment.service_registry["WM_CONSUMER.ID"];
        config.headers["WM_SVC.ENV"] = environment.service_registry["WM_SVC.ENV"];
        config.headers["WM_SVC.NAME"] = environment.service_registry["WM_SVC.NAME"];
        config.headers["Wm_svc.Version"] = environment.service_registry["Wm_svc.Version"];
        config.headers["Wm_sec.Auth_id"] = Cookies.get('login_id');
        return config
    }
    catch (err) {
        throw err
    }
}, function (error) {
    return Promise.reject(error);
});

axiosWithSig.interceptors.response.use(function (response) {
    return response;
}, async function (error) {
    if (401 === error.response.status) {
        Cookies.remove('user')
        Cookies.remove('access_token')
        history.replace("/error");
        window.location.reload()
        return;
    }
    return Promise.reject(error);
});



//Only headers

axiosWithHeaders.interceptors.request.use(function (config) {
    config.headers.Authorization = Cookies.get('access_token');
    config.headers.visado_origin = environment.ORIGIN_THIRD;
    config.headers.loginId = Cookies.get('login_id');
    config.headers["WM_CONSUMER.ID"] = environment.service_registry["WM_CONSUMER.ID"];
    config.headers["WM_SVC.ENV"] = environment.service_registry["WM_SVC.ENV"];
    config.headers["WM_SVC.NAME"] = environment.service_registry["WM_SVC.NAME"];
    config.headers["Wm_svc.Version"] = environment.service_registry["Wm_svc.Version"];
    config.headers["Wm_sec.Auth_id"] = Cookies.get('login_id');
    return config
}, function (error) {
    return Promise.reject(error);
});

axiosWithHeaders.interceptors.response.use(function (response) {
    return response;
}, async function (error) {
    if (401 === error.response.status) {
        Cookies.remove('user')
        Cookies.remove('access_token')
        history.replace("/error");
        window.location.reload()
        return;
    }
    return Promise.reject(error);
});