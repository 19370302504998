import React from "react";
import { withStyles } from "@material-ui/styles";
//form components

import Button from "@material-ui/core/Button";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloudDownload from "@material-ui/icons/CloudDownload";
import { TextValidator } from "react-material-ui-form-validator";
import {
	NORMAL_INPUT,
} from "../../utils/input-rules";
import { showAlertAction } from "../../redux/actions/alert.actions";
import { useDispatch } from "react-redux";

const inputCss = {
	style: {
		padding: 10,
	},
};
const styles = (theme) => ({
	root: {
		flexGrow: 1,
	},
	container: {
		padding: 20,
	},
	containerPaper: {
		padding: "1rem",
	},
	containerPaperFiles: {
		padding: "1rem 0",
	},
	formControl: {
		minWidth: "90%",
	},
	inputText: {
		minWidth: "90%",
	},
	title: {
		fontSize: 21,
		color: theme.palette.primary.main,
		textAlign: "center",
	},
	titlePage: {
		fontSize: 25,
		color: theme.palette.primary.main,
		textAlign: "center",
		margin: 0,
		marginTop: "2rem",
	},
	containerInput: {
		marginTop: 20,
	},
	labelInput: {
		marginTop: 30,
		color: theme.palette.primary.main,
	},
	selectInput: {
		minWidth: "100%",
		padding: 0,
	},
	inputFile: {
		display: "none",
	},
	buttonAttach: {
		color: theme.palette.secondary.main,
		borderRadius: "0 5px 5px 0",
		boxShadow: "none",
		margin: 0,
		textTransform: "inherit",
	},
	buttonAttachLabel: {
		margin: 0,
	},
	containerButtonAttach: {
		display: "flex",
		alignItems: "center",
		width: "51%",
		justifyContent: "center",
	},
	textButtonAttach: {
		margin: 0,
		backgroundColor: "#e0e0e0",
		fontSize: 14,
		padding: "6px 16px",
		color: "#777777",
		borderRight: "1px solid #b3b3b3",
		lineHeight: "1.75",
		borderRadius: "5px 0 0 5px",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		width: 800
	},
	textButtonAttachUpdate: {
		margin: 0,
		backgroundColor: "#e0e0e0",
		fontSize: 14,
		color: "#777777",
		borderRight: "1px solid #b3b3b3",
		lineHeight: "1.75",
		borderRadius: "5px 0 0 5px",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	containerButtonAttachItem: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		borderBottom: "1px solid #e0e0e0",
		marginTop: 20,
		paddingBottom: 20,
		padding: "0 10px",
		fontSize: "1rem",
		fontWeight: "bold",
	},
	containerButtonAttachText: {
		width: "48%",
	},
	textButtonAttachItem: {
		margin: 0,
		color: "#495057",
	},
	textError: {
		color: "#f44336",
		fontSize: ".8rem",
	},
});

const filesTypeAllowed = [
	"application/pdf",
	"application/doc",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	"application/msword",
	"application/docx",
	"image/jpeg",
	"image/jpg",
	"image/png",
	"text/csv",
	"application/vnd.ms-excel",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

function FilesForm({
	classes,
	dataForm,
	handleChangeDataForm,
	errorsForm,
	handleChangeFile,
	showDialogCustom,
	filesToUPload,
	savedFiles = {},
	downloadMethod,
	updateFile,
	editMode = false
}) {
	const dispatch = useDispatch();

	const handleChange = (event) => {
		const existFile = filesToUPload.find((e) => e.name === event.target.name);
		if (existFile && existFile.name) {
			if (filesTypeAllowed.includes(event.target.files[0].type)) {
				handleChangeDataForm(
					existFile.nameHiddenInput,
					event.target.files[0].name
				);
				handleChangeFile(event.target.name, event.target.files[0]);
			} else {
				dispatch(
					showAlertAction({
						show: true,
						variant: 'warning',
						message: "Formato de archivo no valido, los formatos son: PDF, DOC, DOCS, PNG, JPG, XLS, CSV y XLSX",
					})
				);
			}
		} else {
			handleChangeDataForm(event.target.name, event.target.value);
		}
	};

	return (
		<div>
			{filesToUPload.map((e) =>
				savedFiles[e.name] && savedFiles[e.name].Id ? (
					<div className={classes.containerButtonAttachItem} key={e.key}>
						<div className={classes.containerButtonAttachText}>
							<p className={classes.textButtonAttachItem}>{e.name}</p>
						</div>
						<div className={classes.containerButtonAttach}>
							<span className={classes.textButtonAttachUpdate}>
								<Button
									className={classes.buttonAttach}
									variant="contained"
									component="span"
									disabled={editMode}
									onClick={() => updateFile(savedFiles[e.name])}
								>
									Actualizar
								</Button>
							</span>
							<label htmlFor={e.name} className={classes.buttonAttachLabel}>
								<Button
									className={classes.buttonAttach}
									variant="contained"
									component="span"
									onClick={() => downloadMethod(savedFiles[e.name])}
									startIcon={<CloudDownload />}
								>
									Descargar
								</Button>
							</label>
						</div>
					</div>
				) : (
					<div className={classes.containerButtonAttachItem} key={e.key}>
						<div className={classes.containerButtonAttachText}>
							<p className={classes.textButtonAttachItem}>{e.name} *</p>
							{errorsForm[e.nameHiddenInput] ? (
								<span className={classes.textError}>Archivo requerido</span>
							) : null}
						</div>
						<div className={classes.containerButtonAttach}>
							<span className={classes.textButtonAttach}>
								{dataForm[e.nameHiddenInput]
									? dataForm[e.nameHiddenInput]
									: "Buscar archivo"}
							</span>
							<TextValidator
								className={classes.inputFile}
								id={e.name}
								onChange={handleChange}
								value=""
								name={e.name}
								data-testid={e.name}
								type="file"
							/>
							<TextValidator
								className={classes.inputText}
								inputProps={inputCss}
								disabled
								value={dataForm[e.nameHiddenInput]}
								name={e.nameHiddenInput}
								type="hidden"
								validators={NORMAL_INPUT}
								errorMessages={""}
							/>
							<label htmlFor={e.name} className={classes.buttonAttachLabel}>
								<Button
									className={classes.buttonAttach}
									variant="contained"
									component="span"
									startIcon={<AttachFileIcon />}
								>
									Adjuntar
								</Button>
							</label>
						</div>
					</div>
				)
			)}
		</div>
	);
}

export default withStyles(styles)(FilesForm);
