
export const PERMISSIONS_TABLE = {
    Aprobado : {
        Solicitar : 1,
        Actualizar: 1,
        Ver: 1,
        Action: "Actualizar",
        Mensaje: ""
    },
    Vigente : {
        Solicitar : 1,
        Actualizar: 1,
        Ver: 1,
        Action: "Actualizar",
        Mensaje: ""
    },
    "Por Vencer" : {
        Solicitar : 1,
        Actualizar: 1,
        Ver: 1,
        Action: "Renovar",
        Mensaje: "Renovar (Antes o después de vencimiento)"
    },
    Vencido : {
        Solicitar : 0,
        Actualizar: 1,
        Ver: 1,
        Action: "Renovar",
        Mensaje: "Renovar (Antes o después de vencimiento)"
    },
    Recibido : {
        Solicitar : 0,
        Actualizar: 1,
        Ver: 0,
        Action: "Actualizar",
        Mensaje: ""
    },
    "Sin Asignar" : {
        Solicitar : 0,
        Actualizar: 1,
        Ver: 0,
        Action: "Actualizar",
        Mensaje: ""
    },
    Rechazado : {
        Solicitar : 0,
        Actualizar: 1,
        Ver: 0,
        Action: "Reenviar",
        Mensaje: "Reenvío con correcciones"
    },
    Cancelado : {
        Solicitar : 0,
        Actualizar: 0,
        Ver: 0
    },

}
