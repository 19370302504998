import { environment } from '../config/config';
import { axiosWithToken } from '../config/axiosConfig';

export const getCountriesList = () => {
    return new Promise((resolve, reject)=>{
        axiosWithToken.get(`${environment.baseUrl}/Countries`).then(result => {
           resolve(result.data.Results);
        }).catch(err =>{
            reject(err);
        });
    });
}


export const getCountriesListByEmail = (email) => {
	const emailEncrip = window.btoa(email);

    return new Promise((resolve, reject)=>{
        axiosWithToken.get(`${environment.baseUrl}/Countries/CountriesByEmail/${emailEncrip}`).then(result => {
           resolve(result.data.Results);
        }).catch(err =>{
            reject(err);
        });
    });
}


