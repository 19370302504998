

import { LoginForm } from "./components/loginForm";
import { ACCOUNT_FORM, LOGIN_FORM, OTP_FORM, PASS_FORM } from "../../constants/login.constant";
import { AccountForm } from "./components/accountForm";
import { OtpForm } from "./components/otpForm";
import { PassForm } from "./components/passForm";
export const View = (props) => {

    const {
        stateForm,
        dataForm,
        showPassword,
        handleChange,
        handleTogglePassword,
        handleLogin,
        returnValidEmail,
        isValidCreate,
        handleCreate,
        handleLoginOTP,
        handleGenerateOTP,
        handleOpenPass,
        isValidReset,
        handleResetPP,
        accountForm
    } = props



    return (
        <>
            {stateForm === LOGIN_FORM &&
                <LoginForm
                    handleSubmit={handleLogin}
                    dataForm={dataForm}
                    handleChange={handleChange}
                    showPassword={showPassword}
                    returnValidEmail={returnValidEmail}
                    handleTogglePassword={handleTogglePassword}
                    handleOpenPass={handleOpenPass}
                    accountForm={accountForm}
                />
            }
            {
                stateForm === ACCOUNT_FORM &&
                <AccountForm
                    dataForm={dataForm}
                    returnValidEmail={returnValidEmail}
                    handleChange={handleChange}
                    showPassword={showPassword}
                    handleTogglePassword={handleTogglePassword}
                    isValidCreate={isValidCreate}
                    handleCreate={handleCreate}
                />
            }
            {
                stateForm === OTP_FORM &&
                <OtpForm
                    dataForm={dataForm}
                    handleChange={handleChange}
                    handleSubmit={handleLoginOTP}
                    handleGenerateOTP={handleGenerateOTP} />
            }
            {
                stateForm === PASS_FORM && 
                <PassForm
                    dataForm={dataForm}
                    handleChange={handleChange}
                    showPassword={showPassword}
                    isValidReset={isValidReset}
                    handleTogglePassword={handleTogglePassword}
                    handleResetPP={handleResetPP}
                    returnValidEmail={returnValidEmail}
                />
            }
        </>
    )
}