import React from "react";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from '@material-ui/core/Typography';
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { SwipeableDrawer } from "@material-ui/core";
import clsx from "clsx";
import HomeIcon from "@material-ui/icons/Home";
import { NavLink } from "react-router-dom";
import { WALMART_Sol } from "../assets/assets";
import CalculatedIcon from "../utils/CalculatedIcon";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import packageInfo from "../../package.json";
const version = packageInfo.version;

const useStyles = makeStyles({
	list: {
		width: 250,
	},
	fullList: {
		width: "600",
	},
});

const Sidebar = () => {
	const classes = useStyles();
	const [state, setState] = React.useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});

	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event &&
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};
	const list = (anchor) => (
		<div
			className={clsx(classes.list, {
				[classes.fullList]: anchor === "top" || anchor === "bottom",
			})}
			role="presentation"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
		>
			<List>
				<ListItem
					style={{
						backgroundColor: "#041F41",
						color: "#041F41",
						marginTop: -17,
					}}
				></ListItem>
				<ListItem style={{ backgroundColor: "#041F41", color: "white" }}>
					<CalculatedIcon style={{ marginLeft: "150"}} {...WALMART_Sol} />
					<Typography style={{ marginLeft: 10 }} variant="h7" >
						v{version}
					</Typography>
					<ArrowBackIcon style={{ marginLeft: "115" }} />
				</ListItem>

				<NavLink
					activeClassName="active"
					className="nav-item nav-link"
					exact
					to="/principal"
				>
					<ListItem button>
						<ListItemIcon>
							<HomeIcon />
						</ListItemIcon>
						<ListItemText primary={"Inicio"} />
					</ListItem>
				</NavLink>
			</List>
			<Divider />
		</div>
	);

	return (
		<div>
			<React.Fragment key={"left"}>
				<Button
					onClick={toggleDrawer("left", true)}
					style={{ color: "white", fontSize: 15 }}
				>
					<MenuIcon />
					Menú
				</Button>
				<SwipeableDrawer
					anchor={"left"}
					open={state["left"]}
					onClose={toggleDrawer("left", false)}
					onOpen={toggleDrawer("left", true)}
				>
					{list("left")}
				</SwipeableDrawer>
			</React.Fragment>
		</div>
	);
};

export default Sidebar;
