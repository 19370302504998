import { environment } from '../config/config';
import { axiosWithToken } from '../config/axiosConfig';

export const getUserDetailByEmail = async (userEmail) => {
    const userEncript = window.btoa(userEmail);
    const res = await axiosWithToken.get(`${environment.baseUrl}/VisaThirdParty/VisaThirdPartyUserByEmail/${userEncript}`);
    if(res.data && res.data.Results) {
        return res.data.Results;
    }
    return [];
}

export const updateUserInfo = async (data) =>  {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data'
        }
    }
    return new Promise((resolve, reject) => {
        axiosWithToken.put(`${environment.baseUrl}/VisaThirdParty/User`, data, config)
            .then(result => {
                resolve(result.data.Results)
            })
            .catch(err => {
                reject(err)
            });
    });
}
