import React, { useEffect, useState } from "react";
//form components

import { ValidatorForm } from "react-material-ui-form-validator";

//Components
import AlertDialog from "../../components/AlertDialog/AlertDialog";

import { useDispatch } from "react-redux";
import {
	showLoadingAction,
	hideLoadingAction,
} from "../../redux/actions/loading.actions";
import {
	createVisaFiles,
	getFile,
	getVisaAplicationFilesById,
	updateVisaMemberFiles
} from "../../services/files.service";
import { getVendorsByEmailAndContrieId } from "../../services/vendors.service";
import {
	getCountriesListByEmail,
} from "../../services/countries.service";

import { useHistory, useParams } from "react-router-dom";

import { setTitlePageAction } from "../../redux/actions/title.actions";
import { showAlertAction } from "../../redux/actions/alert.actions";
import {
	createVisaRequest,
	getDangerTypesByVisadoType,
	getVisaAplicationByRequNumber,
	getVisadosTypeByCountryId,
	getVisaReguirimentsFile,
	getCompanyRequirementFiles,
	updateAllVisaRequest
} from "../../services/visados.service";
import { useStyles } from "./useStyles";
import InfoForm from "./components/InfoForm";
import {
	getCompanyRegisterISD,
	getVendorValidator
} from "../../services/company.service";
import {
	saveVisaAplicationDangerZones,
	getVisaDangerZonesSaved,
	updateVisaAplicationDangerZones
} from "../../services/dangerZones.service";
import {
	createCommentaryByVisaAplication,
	getCommentsByVisaAplicationId,
} from "../../services/comments.service";

//constants
import {
	INVALID_EDIT_STATUS,
	extraCompanyFileNicaragua,
	companyFiles, VALID_VISADO_REQUEST
} from '../../utils/constants'
//constants
import { FILE_PREFIX } from '../../constants/api_proxy.constant'
import { getMaxLength } from "../../utils/helper";




export default function VisadoRequest({ auth, location }) {
	const [dataForm, setDataForm] = useState({
		visadoType: "",
		vendor: "",
		country: "",
		legalName: "",
		legalCompanyId: "",
		legalNameRep: "",
		notifyEmail: "",
		commentary: "",
		commentaryTwo: "",
		dangerZone: "",
	});
	const [openDialog,] = useState(false);
	const [openDialogSubmit, setOpenDialogSubmit] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [errorsForm, setErorrsForm] = useState({});
	const [visaEditAplication, setVisaEditAplication] = useState({});
	const [countries, setCountries] = useState([]);
	const [vendors, setVendors] = useState([]);
	const [visadosType, setVisadosType] = useState([]);
	const [dangerTypes, setDangerTypes] = useState([]);
	const [savedFiles, setSavedFiles] = useState([]);
	const [savedTemporalFiles, setSavedTemporalFiles] = useState({});
	const [requirementsFiles, setRequirementsFiles] = useState([]);
	const [companyDataSaved, setCompanyDataSaved] = useState({});
	const [filesForm, setFilesForm] = useState([]);
	const [filesRequest, setFilesRequest] = useState({})

	const [listCommentaries, setListCommentaries] = useState([]);
	const [isAnual, setIsAnual] = useState(null);
	const [userInfo, setUserInfo] = useState({});
	const [sendButton, setSendButton] = useState(false);
	const [companyRequirementFiles, setCompanyRequirementFiles] = useState([]);
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();

	const urlParams = useParams();

	const handleChange = (event) => {
		setDataForm({ ...dataForm, [event.target.name]: event.target.value });
		if (event.target.name === "vendor") {
			handleChangeVendor(event.target.value, userInfo.email);
		} else if (event.target.name === "country") {
			handleChangeCountry(event.target.value, userInfo.email);
		} else if (event.target.name === "visadoType") {
			handleChangeVisaType(event.target.value);
		}
	};

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
		initPage();
	}, []);


	const initPage = async () => {
		dispatch(setTitlePageAction({ text: "Solicitud de visado" }));
		dispatch(showLoadingAction());
		const user = auth.getUserInfo();
		setUserInfo(user);

		await getCountries(user.email);
		await readyQueryParams(user.email);
		if (urlParams.visaid) {
			await getVisaAplicationInfo(urlParams.visaid, user.email);
		}
		dispatch(hideLoadingAction());
	};

	const readyQueryParams = async (email) => {
		const sp = new URLSearchParams(location.search);
		const safeParams = {}
		for (let [key, value] of sp.entries()) {
			const encodedKey = encodeURIComponent(key);
			const encodedValue = encodeURIComponent(value);
			safeParams[encodedKey] = encodedValue;
		}
		if (safeParams["country"]) {
			await handleChangeCountry(safeParams["country"], email);
		}
		if (safeParams["vendor"]) {
			await handleChangeVendor(safeParams["vendor"], email);
		}
		return;
	};

	const getVisaAplicationInfo = async (aplicationId, email) => {
		try {
			setEditMode(true);
			const res = await getVisaAplicationByRequNumber(aplicationId);
			if (INVALID_EDIT_STATUS.includes(res.Status)) {
				history.push(`/visa-detail/${aplicationId}`);
				return
			}
			setVisaEditAplication(res);
			await handleChangeCountry(res.CountryId, email);
			await handleChangeVendor(res.VendorId, email);
			const res3 = await handleChangeVisaType(res.VisaTypeId, res);
			await getDanverZonesSaved(res3, res.ApplicationId);
			await getCommentariesList(res.ApplicationId);
			await getVisaRequestFiles(res.ApplicationId);
			setDataForm((oldState) => ({
				...oldState,
				visadoType: res.VisaTypeId,
				vendor: res.VendorId,
				country: res.CountryId,
				notifyEmail: res.email,
			}));

		} catch (err) {
			console.log("err", err);
		}
	};

	const getRequirimentsFiles = async (visaTypeId) => {
		try {
			let res = []
			const visaTypeSelected = visadosType.find((e) => e.ID === visaTypeId);
			if (visaTypeSelected && visaTypeSelected.Name.includes("Anual")) {
				res = await getCompanyRequirementFiles(visaTypeId)
			} else {
				res = await getVisaReguirimentsFile(visaTypeId);
			}
			const requr = res.map((e) => {
				return {
					...e,
					name: e.RequirementName,
					nameInput: e.RequirementName,
					nameHiddenInput: e.RequirementName + "hidden",
					key: e.Id,
				};
			});

			setRequirementsFiles(requr);
		} catch (err) {
			console.log("err ", err);
		}
	};

	const getRequirimentsFilesNicaragua = async (visaTypeId) => {
		try {
			let res = await getCompanyRequirementFiles(visaTypeId);
			res = res.filter(x => x.RequirementName.toLowerCase().includes(extraCompanyFileNicaragua[0].name.toLocaleLowerCase()))
			if (res === undefined) {
				return;
			}
			const requr = [
				{
					name: res[0].RequirementName,
					nameInput: res[0].RequirementName,
					nameHiddenInput: res[0].RequirementName + "hidden",
					key: res[0].Id,
				}
			]
			setRequirementsFiles(requr);
		} catch (err) {
			console.log("err ", err);
		}
	};

	const getCommentariesList = async (visaAplicationId) => {
		try {
			const res = await getCommentsByVisaAplicationId(visaAplicationId);
			setListCommentaries(res);
		} catch (err) {
			console.log("err comentarios list", err);
		}
	};

	const getVisaRequestFiles = async (visaAplicationId) => {
		try {
			const res = await getVisaAplicationFilesById(visaAplicationId);
			if (Array.isArray(res) && res.length > 0) {
				const files = {};
				res.forEach((e) => {
					files[e.NameRequirement.trim()] = e;
				});
				setSavedTemporalFiles(files);
				setFilesRequest(files)
			}
		} catch (err) {
			console.log("err files list", err);
		}
	};

	const getVendors = async (userEmail, countrieId) => {
		try {
			const res = await getVendorsByEmailAndContrieId(userEmail, countrieId);
			setVendors(res);
		} catch (err) {
			console.log("err", err);
		}
	};

	const getCountries = async (userEmail) => {
		try {
			const res = await getCountriesListByEmail(userEmail);
			setCountries(res);
			return;
		} catch (err) {
			console.log("err", err);
		}
	};

	const handleChangeVendor = async (vendorId, email) => {
		try {
			setSendButton(false)
			dispatch(showLoadingAction());
			const statusVendor = await getVendorValidator(email, vendorId)
			if (!VALID_VISADO_REQUEST.includes(statusVendor)) {
				dispatch(showAlertAction({
					show: true,
					variant: "warning",
					message:
						`Su registro de empresa se encuentra en estado: ${statusVendor}`,
				}))
				dispatch(hideLoadingAction());
				return null
			}
			setSendButton(true)
			const res = await getCompanyRegisterISD(email, vendorId);

			setCompanyDataSaved(res);
			if (res && res.VendorId) {
				setDataForm((oldState) => ({
					...oldState,
					vendor: vendorId,
					legalName: res.BusinessName,
					legalCompanyId: res.LegalId,
					legalNameRep: res.LegalRepresentative
				}));
				if (Array.isArray(res.UploadedFiles) && res.UploadedFiles.length > 0) {
					const files = {};
					res.UploadedFiles.forEach((e) => {
						files[e.NameRequirement.trim()] = e;
					});
					setSavedFiles(files);
				}
			}
			dispatch(hideLoadingAction());
			return res;
		} catch (err) {
			dispatch(hideLoadingAction());
			console.log("err company register", err);
		}
	};

	const handleChangeCountry = async (countryId, email) => {
		try {
			setSendButton(false)
			dispatch(showLoadingAction());
			setDataForm((oldState) => ({
				...oldState,
				country: countryId,
			}));
			await getVendors(email, countryId)
			const visadosType = await getVisadosTypeByCountryId(countryId);
			setVisadosType(visadosType);

			dispatch(hideLoadingAction());
			return visadosType;
		} catch (err) {
			dispatch(hideLoadingAction());
			return [];
		}
	};

	const handleChangeVisaType = async (visaType, visaData) => {
		try {
			dispatch(showLoadingAction());
			const visaTypeSelected = visadosType.find((e) => e.ID === visaType);
			const countrieSelected = countries.find(x => x.Id == dataForm.country)

			// Extract nested ternary into an independent function
			const determineIsAnual = () => {
				if (visaData && visaData.VisaType.includes("Anual")) {
					return true;
				}
				if (visaTypeSelected && visaTypeSelected.RegistrationCompany) {
					return true;
				}
				return false;
			};

			// Extract nested ternary into an independent function
			const determineCountry = () => {
				if (visaData) {
					return visaData.Country;
				}
				if (countrieSelected) {
					return countrieSelected.Name;
				}
				return "";
			};

			const tempIsAnual = determineIsAnual();
			const tempCountrie = determineCountry();

			if (tempIsAnual) {
				setIsAnual(true);
				if (tempCountrie.includes("Nicaragua")) {
					await getRequirimentsFilesNicaragua(visaType)
					setCompanyRequirementFiles(extraCompanyFileNicaragua)
				}
			} else {
				getRequirimentsFiles(visaType);
				setIsAnual(false);
			}
			const dangerTypes = await getDangerTypesByVisadoType(visaType);
			setDangerTypes(dangerTypes);
			dispatch(hideLoadingAction());
			return dangerTypes;
		} catch (err) {
			console.log(err)
			dispatch(hideLoadingAction());
			return [];
		}
	};

	const handleChangeDangerTypes = async (dangerType) => {
		const dangerZonesCopy = [...dangerTypes];
		dangerZonesCopy.forEach((type) => {
			if (type.VisaDangerTypeName == dangerType.target.name) {
				type.selected = !type.selected;
			}
		});

		setDangerTypes(dangerZonesCopy);
		existDangerZoneSelected();
	};

	const existDangerZoneSelected = () => {
		let exist = "";
		for (let i = 0; i < getMaxLength(dangerTypes.length); i++) {
			if (dangerTypes[i].selected) {
				exist = true;
			}
		}
		setDataForm((oldState) => ({ ...oldState, dangerZone: exist }));
	};

	const handleSubmitError = (errors) => {
		const errorsName = {};
		errors.forEach((element) => {
			errorsName[element.props.name] = "error";
		});
		setErorrsForm(errorsName);
	};

	const showConfirmAlert = () => {
		setOpenDialogSubmit(true);
	};
	const onCloseSubmitAlert = () => {
		setOpenDialogSubmit(false);
	};
	const onCloseSucessSave = () => { };

	const createCommentary = async () => {
		if (!dataForm.commentary) {
			dispatch(
				showAlertAction({
					show: true,
					variant: "warning",
					message: "Agregue un comentario",
				})
			);
			return;
		}
		if (dataForm.commentary.length > 125) {
			dispatch(
				showAlertAction({
					show: true,
					variant: "warning",
					message: "El comentario no debe exceder el tamaño de 125 caractres.",
				})
			);
			return;
		}
		try {
			dispatch(showLoadingAction());
			const body = {
				VisaApplicationId: visaEditAplication.ApplicationId,
				Commentary: dataForm.commentary,
				UserId: userInfo.email,
				UserName: companyDataSaved.BusinessName,
				CreatedBy: userInfo.email,
				Origin: "Tercero",
			};
			await createCommentaryByVisaAplication(body);
			setDataForm((oldState) => ({ ...oldState, commentary: "" }));
			dispatch(
				showAlertAction({
					show: true,
					variant: "success",
					message: "Comentario agregado correctamente.",
				})
			);
			await getCommentariesList(visaEditAplication.ApplicationId);
			dispatch(hideLoadingAction());
		} catch (err) {
			console.log("err", err);
			dispatch(hideLoadingAction());
		}
	};

	const handleChangeDataForm = (name, state) => {
		setDataForm({ ...dataForm, [name]: state });
		if (errorsForm[name]) {
			const errors = { ...errorsForm, [name]: null };
			setErorrsForm(errors);
		}
	};
	const handleCancel = () => {
		history.push("/");
	};

	const handleChangeFile = (nameInput, file) => {
		const filesExists = filesForm.filter((e) => e.name !== nameInput);

		setFilesForm([...filesExists, { name: nameInput, file: file }]);
	};

	const downloadMethod = async (file) => {
		const fileName = file.RouteRequirement.split("/").pop();

		dispatch(showLoadingAction());
		try {
			const formDataS = new FormData();
			formDataS.append("fileName", fileName);
		
			const res = await getFile(formDataS);
			// Use const instead of var
			const blob = new Blob([res], { type: "application/octet-stream" });
			// Use const instead of var
			const link = document.createElement("A");
			link.href = URL.createObjectURL(blob); // SET LINK ELEMENTS CONTENTS
			link.setAttribute("download", fileName); // SET ELEMENT CREATED 'ATTRIBUTE' TO DOWNLOAD, FILENAME PARAM AUTOMATICALLY
			link.click(); // TRIGGER ELEMENT TO DOWNLOAD
			link.remove();
			dispatch(hideLoadingAction());
		} catch (error) {
			dispatch(hideLoadingAction());
		}
	};

	const handleVariant = async () => {
		if (editMode) {
			await updateVisado()
			return
		}
		await createVisado()
	}
	const createVisado = async () => {
		dispatch(showLoadingAction());
		setOpenDialogSubmit(false);
		try {
			const visaRequestData = {
				RegistrationCompanyRequestId: companyDataSaved
					? companyDataSaved.Id
					: "00000000-0000-0000-0000-000000000000",
				VisaTypeId: dataForm.visadoType,
				VendorId: dataForm.vendor,
				// 'StatusId': "",
				email: dataForm.notifyEmail,
				CountryId: dataForm.country,
				ThirdCompanyName: dataForm.legalName,
				LegalIdCompany: dataForm.legalCompanyId,
				Commentary: dataForm.commentary,
			};

			const registerIsd = {
				Id: "00000000-0000-0000-0000-000000000000",
				VisaApplicationId: "00000000-0000-0000-0000-000000000000",
				CompanyName: companyDataSaved.BusinessName,
				LegalIdentityCard: companyDataSaved.LegalId,
				LegalRepresentativeName: companyDataSaved.LegalRepresentative,
				ContractExpirationDate: new Date(),
			};
			const data = new FormData();

			visaRequestData.ModifiedBy = userInfo.email;
			registerIsd.ModifiedBy = userInfo.email;
			visaRequestData.CreatedBy = userInfo.email;
			registerIsd.CreatedBy = userInfo.email;

			data.append("metadata", JSON.stringify(visaRequestData));
			data.append("registerIsd", JSON.stringify(registerIsd));
			const res = await createVisaRequest(data);
			const body = {
				VisaApplicationId: res.ID,
				Commentary: dataForm.commentary,
				UserId: userInfo.email,
				UserName: companyDataSaved.BusinessName,
				CreatedBy: userInfo.email,
				Origin: "Tercero",
			};
			if (dataForm.commentary.length > 0) {
				await createCommentaryByVisaAplication(body);
			}
			await saveFiles(res.ID);
			await saveDangerzones(res.ID);
			dispatch(hideLoadingAction());
			dispatch(
				showAlertAction({
					show: true,
					variant: "success",
					message:
						`Solicitud creada con éxito`,
				})
			);
			history.push(`/colaborator-register/${res.ApplicationNumber}`);
		} catch (err) {
			dispatch(hideLoadingAction());
			dispatch(
				showAlertAction({
					show: true,
					variant: "error",
					message:
						`Ha ocurrido un error al crear la solicitud`,
				})
			);
		}
	};

	const updateVisado = async () => {
		dispatch(showLoadingAction());
		setOpenDialogSubmit(false);
		try {
			const visaRequestData = {
				ID: visaEditAplication.ApplicationId,
				RegistrationCompanyRequestId: companyDataSaved
					? companyDataSaved.Id
					: "00000000-0000-0000-0000-000000000000",
				VisaTypeId: dataForm.visadoType,
				VendorId: dataForm.vendor,
				StatusId: visaEditAplication.StatusId,
				email: dataForm.notifyEmail,
				CountryId: dataForm.country,
				ThirdCompanyName: dataForm.legalName,
				LegalIdCompany: dataForm.legalCompanyId,
				Commentary: dataForm.commentary,
			};

			const registerIsd = {
				Id: "00000000-0000-0000-0000-000000000000",
				VisaApplicationId: "00000000-0000-0000-0000-000000000000",
				CompanyName: null,
				LegalIdentityCard: null,
				LegalRepresentativeName: null,
				ContractExpirationDate: null,
			};
			const data = new FormData();

			visaRequestData.ModifiedBy = userInfo.email;
			registerIsd.ModifiedBy = userInfo.email;
			visaRequestData.CreatedBy = userInfo.email;
			registerIsd.CreatedBy = userInfo.email;

			data.append("metadata", JSON.stringify(visaRequestData));
			data.append("registerIsd", JSON.stringify(registerIsd));
			await updateAllVisaRequest(visaEditAplication.ApplicationId, data);
			await updateVisaApplicationFiles(visaEditAplication.ApplicationId)
			await updateDangerzones(visaEditAplication.ApplicationId)
			dispatch(
				showAlertAction({
					show: true,
					variant: "success",
					message:
						`Solicitud actualizada con exito`,
				})
			);
			dispatch(hideLoadingAction());
			history.push(`/colaborator-register/${urlParams.visaid}`);
		} catch (err) {
			dispatch(hideLoadingAction());
			dispatch(
				showAlertAction({
					show: true,
					variant: "error",
					message:
						`Ha ocurrido un error al actualizar la solicitud`,
				})
			);
			console.log("err create", err);
		}
	};

	const saveFiles = async (visaAplicationId) => {
		if (requirementsFiles.length === 0) {
			return;
		}
		try {
			const sendFiles = [];
			for (let i = 0; i < getMaxLength(requirementsFiles.length); i++) {
				for (let x = 0; x < filesForm.length; x++) {
					if (requirementsFiles[i].name === filesForm[x].name) {
						sendFiles.push({
							...filesForm[x],
							...requirementsFiles[i],
						});
					}
				}
			}
			const data = new FormData();

			sendFiles.forEach((e) => {
				data.append(`${FILE_PREFIX}${e.Id},${e.name}`, e.file);
			});
			data.append(`email`, window.btoa(userInfo.email));
			await createVisaFiles(visaAplicationId, data);
		} catch (err) {
			console.log("err save", err);
		}
	};

	const getDanverZonesSaved = async (dangerList, visaAplicationId) => {
		try {
			const dangerZonesTypesCopy = [...dangerList];
			const res = await getVisaDangerZonesSaved(visaAplicationId);
			// This reduces the cognitive complexity
			if (res && res.length > 0) {
				const dangerTypeMapping = {
					1: 'DangerType1',
					2: 'DangerType2',
					3: 'DangerType3',
					4: 'DangerType4',
				};
	
				dangerZonesTypesCopy.forEach((dangerZoneType) => {
					const dangerTypeKey = dangerTypeMapping[dangerZoneType.VisaDangerTypeID];
					if (dangerTypeKey) {
						dangerZoneType.selected = res[0][dangerTypeKey] > 0;
					}
				});
			}
			setDangerTypes(dangerZonesTypesCopy);
			existDangerZoneSelected();
		} catch (err) { }
	};

	const saveDangerzones = async (visaAplicationId) => {
		if (dangerTypes.length === 0) {
			return;
		}
		try {
			const body = {
				VisaApplicationId: visaAplicationId,
				DangerType1: 0,
				DangerType2: 0,
				DangerType3: 0,
				DangerType4: 0,
				Active: true,
				ModifiedBy: userInfo.email,
				CreatedBy: userInfo.email,
			};
			for (let i = 0; i < getMaxLength(dangerTypes.length); i++) {
				if (dangerTypes[i].selected) {
					switch (dangerTypes[i].VisaDangerTypeID) {
						case 1: {
							body.DangerType1 = 1;
							break;
						}
						case 2: {
							body.DangerType2 = 2;
							break;
						}
						case 3: {
							body.DangerType3 = 3;
							break;
						}
						case 4: {
							body.DangerType4 = 4;
							break;
						}
					}
				}
			}
			const data = new FormData();
			data.append("VisaApplicationId", body.VisaApplicationId);
			data.append("DangerType1", body.DangerType1);
			data.append("DangerType2", body.DangerType2);
			data.append("DangerType3", body.DangerType3);
			data.append("DangerType4", body.DangerType4);
			data.append("Active", body.Active);
			data.append("ModifiedBy", body.ModifiedBy);
			data.append("CreatedBy", body.CreatedBy);

			await saveVisaAplicationDangerZones(data);
		} catch (err) {
			console.log("err danger zones", err);
		}
	};

	const updateDangerzones = async (visaAplicationId) => {
		if (dangerTypes.length === 0) {
			return;
		}
		try {
			const body = {
				VisaApplicationId: visaAplicationId,
				DangerType1: 0,
				DangerType2: 0,
				DangerType3: 0,
				DangerType4: 0,
				Active: true,
				ModifiedBy: userInfo.email,
				CreatedBy: userInfo.email,
			};
			for (let i = 0; i < getMaxLength(dangerTypes.length); i++) {
				if (dangerTypes[i].selected) {
					switch (dangerTypes[i].VisaDangerTypeID) {
						case 1: {
							body.DangerType1 = 1;
							break;
						}
						case 2: {
							body.DangerType2 = 2;
							break;
						}
						case 3: {
							body.DangerType3 = 3;
							break;
						}
						case 4: {
							body.DangerType4 = 4;
							break;
						}
					}
				}
			}

			await updateVisaAplicationDangerZones(body);
		} catch (err) {
			console.log("err danger zones", err);
		}
	};

	const setUpladeFile = (file) => {
		setSavedTemporalFiles((oldState) => ({
			...oldState,
			[file.NameRequirement.trim()]: {},
		}));
	};

	const updateVisaApplicationFiles = async (visaAplicationId) => {
		if (filesForm.length === 0) {
			return;
		}
		try {

			const sendFiles = [];
			if (companyRequirementFiles.length > 0) {
				sendFiles.push({
					...filesForm[0],
					...requirementsFiles[0],
				});
			}
			else {
				for (let i = 0; i < getMaxLength(requirementsFiles.length); i++) {
					for (let x = 0; x < filesForm.length; x++) {
						if (requirementsFiles[i].name === filesForm[x].name) {
							sendFiles.push({
								...filesForm[x],
								...requirementsFiles[i],
							});
						}
					}
				}
			}

			const data = new FormData();
			sendFiles.forEach((e) => {
				data.append(`${FILE_PREFIX}${filesRequest[e.name]?.Id ?? e?.Id}`, e.file);
			});
			data.append(`email`, window.btoa(userInfo.email));
			await updateVisaMemberFiles(visaAplicationId, data);

		} catch (err) {
			console.log("err save", err);
		}
	};

	return (
		<div>
			<ValidatorForm
				className={classes.root}
				autoComplete="off"
				onSubmit={showConfirmAlert}
				onError={handleSubmitError}
			>
				<InfoForm
					vendors={vendors}
					countries={countries}
					visadosType={visadosType}
					dangerTypes={dangerTypes}
					handleChange={handleChange}
					companyFiles={companyFiles}
					extraCompanyFiles={companyRequirementFiles}
					handleChangeDataForm={handleChangeDataForm}
					handleCancel={handleCancel}
					listCommentaries={listCommentaries ? listCommentaries : []}
					createCommentary={createCommentary}
					dataForm={dataForm}
					classes={classes}
					savedFiles={savedFiles}
					errorsForm={errorsForm}
					downloadMethod={downloadMethod}
					isAnual={isAnual}
					handleChangeDangerTypes={handleChangeDangerTypes}
					requirementsFiles={requirementsFiles}
					handleChangeFile={handleChangeFile}
					editMode={editMode}
					savedTemporalFiles={savedTemporalFiles}
					setUpladeFile={setUpladeFile}
					sendButton={sendButton}
				/>
				<AlertDialog
					open={openDialog}
					hiddeCancel={true}
					message={"Se ha creado correctamente el registro de visados"}
					onAcept={onCloseSucessSave}
				/>
				<AlertDialog
					open={openDialogSubmit}
					onClose={onCloseSubmitAlert}
					showWarningIcon={true}
					message={"Confirmación de registro de visado"}
					onAcept={handleVariant}
				/>
			</ValidatorForm>
		</div>
	);
}
