import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { useDispatch, useSelector } from 'react-redux';
import { hideAlertAction } from '../../redux/actions/alert.actions';


export const SnackBarAlert = () => {
    const alertReducer = useSelector(({ alertReducer }) => alertReducer);
    const dispatch = useDispatch();
    const [openAlert, setOpenAlert] = useState(false)

    useEffect(() => {
        setOpenAlert(alertReducer.show)
    }, [alertReducer])

    const handleClose = (event, reason) => {
        setOpenAlert(false);
    };

    return (
        <div>
            {
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    open={openAlert}
                    autoHideDuration={3000}
                    onClose={handleClose}>
                    <Alert elevation={6} onClose={handleClose} severity={alertReducer?.variant ?? "success"}>
                        {alertReducer?.message}
                    </Alert>
                </Snackbar>
            }
        </div>
    )
}

