import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image
} from "@react-pdf/renderer";

import moment from 'moment';
import 'moment/locale/es';

import QRious from 'qrious';

//Assets
import Logo from "../assets/logo-WM-CA.png";
import Electrico from '../assets/danger-types/electrico.png';
import Altura from '../assets/danger-types/en-altura.png';
import Espacio from '../assets/danger-types/en-espacio-confinado.png';
import Caliente from '../assets/danger-types/en-caliente.png';
import { formatDate } from "./dates";
import {sanitize} from './helper'

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#ffffff",
        padding: 10,
        margin: 10,
        position: 'relative'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    image: {
        width: 100,
        height: 25,
        position: 'absolute',
        right: 20,
        top: 0
    },
    trabajosImg: {
        marginLeft: 100,
        width: '60%'
    },
    dangerImg: {
        display: "inline-block",
        width: "80px",
    },
    table: {
        display: "table",
        // width: "auto",
        marginBottom: 23,
        width: 557
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
        borderBottom: 1,
        borderColor: '#e0e0e0',
        borderStyle: 'solid',
        paddingBottom: 5
    },
    tableCol: {
        width: "12.5%"
    },
    colNumberVisa: {
        width: "23%"
    },
    tableHeader: {
        borderBottom: 1,
        borderColor: '#000',
        borderStyle: 'solid'
    },
    tableFirstCol: {
        width: '2 %'
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: 9
    },
    textBody: {
        width: 557,
        fontSize: 10
    },
    textCenter: {
        textAlign: 'center'
    },
    textTitle: {
        width: 557,
        fontSize: 14
    },
    textTitlePolicy: {
        width: 557,
        fontSize: 13,
        marginTop: 50
    },
    textSubtitle: {
        fontSize: 10,
        marginBottom: 15,
        paddingBottom: 5,
        borderColor: '#000',
        borderStyle: 'solid',
        width: 557,
        borderBottom: 1
    },
    marginBottom: {
        marginBottom: 15
    },
    infoContact: {
        marginTop: 15,
        marginBottom: 15,
        fontWeight: 600
    },
    qr: {
        width: 160,
        marginLeft: 200
    },
    containerQr: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    containerFlex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: '15px'
    },

    logo_policity: {
        width: 167,
        height: 40,
    },
    text_decoration: {
        fontSize: 13,
        fontWeight: 'bold',
        textDecoration: 'underline'
    },
    subText: {
        marginLeft: 20
    }
});

const renderInfo = (colaborator) => {
    if (sanitize(colaborator.ThirdCompanyName)) {
        return (
            <Text style={[styles.textBody, styles.marginBottom]}>
                Por este medio les envío un cordial saludo y a la vez me permito hacer de su conocimiento que la empresa {sanitize(colaborator.ThirdCompanyName)} cédula jurídica número: {sanitize(colaborator.LegalIdThirdCompamy)} realizará labores en representación del Socio Comercial: {sanitize(colaborator.CompanyName) || sanitize(colaborator.Vendor)} cédula jurídica número: {sanitize(colaborator.LegalIdentityCard) || sanitize(colaborator.RegisterCompanyLegalId)} código vendor número {sanitize(colaborator.CodVendor)}
            </Text>
        );
    } else {
        return (
            <Text style={[styles.textBody, styles.marginBottom]}>
                Por este medio les envío un cordial saludo y a la vez me permito hacer de su conocimiento que la empresa {sanitize(colaborator.CompanyName) || sanitize(colaborator.Vendor)} cédula jurídica número: {sanitize(colaborator.LegalIdentityCard) || sanitize(colaborator.RegisterCompanyLegalId)} código vendor número {sanitize(colaborator.CodVendor)} descrita en adelante como socio comercial realizará labores en las instalaciones de Walmart.
            </Text>
        );
    }
}

const getImageUrl = (dangerTypeName) => {
    if (dangerTypeName.includes("Eléctrico")) {
        return Electrico;
    }
    if (dangerTypeName.includes("Altura")) {
        return Altura;
    }
    if (dangerTypeName.includes("Espacio")) {
        return Espacio;
    }
    if (dangerTypeName.includes("Caliente")) {
        return Caliente;
    }
    return null;
}

const renderDangerTypeImg = (dangerTypes) => {
    return dangerTypes.length ? (
        <View style={styles.containerFlex}>
            {
                dangerTypes.map(item => {
                    const url = getImageUrl(item.VisaDangerTypeName);
                    return <Image style={styles.dangerImg} src={url} key={`img-danger-${item.ID}`} />
                })
            }
        </View>
    ) : null
}

const renderOptions = (colaborator, dangerTypes) => {
    if (colaborator.VisaType.includes('Trabajos Peligrosos')) {
        return (
            <View>
                {colaborator.Countrie === 'Regional' &&
                    <Text style={styles.textBody}>
                        d.  La persona arriba descrita tienen autorización para realizar labores en {sanitize(colaborator.CountriesRegional)}
                    </Text>
                }

                <Text style={[styles.textBody, styles.marginBottom]}>
                    {colaborator.Countrie === 'Regional' ? 'e' : 'd'}.  La persona arriba descrita presentó constancia de contar con la capacitación y competencias necesarias para poder participar los siguientes Trabajos Peligrosos:
                </Text>
                {renderDangerTypeImg(dangerTypes)}
            </View>
        );
    } else if (colaborator.Countrie === 'Regional') {
        return (
            <Text style={styles.textBody}>
                d.  La persona arriba descrita tienen autorización para realizar labores en {sanitize(colaborator.CountriesRegional)}
            </Text>
        );
    }
    return null;
}

export function PdfDocument(props) {
    const colaborator = props.data;
    const dangerTypes = props.dangerTypes;
    const renderDescription = renderInfo(colaborator);
    const renderOptionD = renderOptions(colaborator, dangerTypes);
    const qr = new QRious();
    qr.value = JSON.stringify(props.dataColb);
    const codeQr = qr.toDataURL('image/jpeg');

    moment.locale('es');
    const date = `${moment().format('dddd')}, ${moment().format('D')} de ${moment().format('MMMM')} de ${moment().format('YYYY')}`;

    let _walmart_operador = {
        "costa rica": 'Corporación Supermercados Unidos, S.R.L (CSU,S.R.L)',
        "nicaragua": 'Corporación Supermercados Unidos, S.R.L (CSU,S.R.L)',
        "el salvador": 'Operadora del Sur, S.A de C.V',
        'guatemala': 'Operadora de Tiendas, S.A',
        "honduras": 'Operadora del Oriente, S.A de C.V',
        'regional': 'Corporación Supermercados Unidos, S.R.L (CSU,S.R.L)',
    }[colaborator.Countrie.toLocaleLowerCase().trim()]
    if (colaborator.Countrie.toLocaleLowerCase().trim() === 'regional') {
        _walmart_operador = {
            "cr": 'Corporación Supermercados Unidos, S.R.L (CSU,S.R.L)',
            "ni": 'Corporación Supermercados Unidos, S.R.L (CSU,S.R.L)',
            "sv": 'Operadora del Sur, S.A de C.V',
            'gt': 'Operadora de Tiendas, S.A',
            "hn": 'Operadora del Oriente, S.A de C.V',
        }[colaborator.VendorCountrie.toLocaleLowerCase().trim()]
    }



    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.container}>
                    <View>
                        <Text style={styles.textTitle}>{_walmart_operador}</Text>
                        <Text style={styles.textSubtitle}>PROCESO DE ADMINISTRACIÓN Y CONTROL DE PERSONAL TERCERIZADO</Text>
                        <Image
                            style={styles.image}
                            source={Logo}
                        />

                        <Text style={[styles.textBody, styles.marginBottom]}>{date}</Text>
                        <Text style={styles.textBody}>Estimados señores:</Text>
                        <Text style={styles.textBody}>Proveedores, Gerencia y Administración de Tiendas</Text>
                        <Text style={[styles.textBody, styles.marginBottom]}>{_walmart_operador}</Text>
                        <Text style={[styles.textBody, styles.marginBottom]}>Presente:</Text>

                        {renderDescription}

                        <Text style={[styles.textBody, styles.marginBottom]}>
                            A continuación, se muestra la persona que cumplió satisfactoriamente el proceso de administración y control de personal tercerizado establecido por Walmart México y Centroamérica, se encuentran autorizadas para el ingreso en nuestras instalaciones.
                        </Text>
                        <Text style={[styles.textBody, styles.marginBottom]}>
                            El proveedor fue previamente informado y tiene conocimiento que su personal debe presentar copia de esta certificación; además, debe portar el gafete de identificación, documento de identidad, documento de Seguro Social vigente y otros que se solicitan, carné de manipulación de alimentos cuando corresponda así como debidamente uniformado.
                        </Text>
                    </View>
                    <View style={styles.table}>

                        <View style={[styles.tableRow, styles.tableHeader]}>
                            <View style={[styles.tableCol, styles.tableFirstCol]}>
                                <Text style={styles.tableCell}>#</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Nombre</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>1ER Apellido</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>2DO Apellido</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Identificación</Text>
                            </View>
                            <View style={[styles.tableCol, styles.colNumberVisa]}>
                                <Text style={styles.tableCell}>Número de Visado</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Vigencia</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Vencimiento</Text>
                            </View>
                        </View>
                        {
                            colaborator &&
                            <View style={styles.tableRow}>
                                <View style={[styles.tableCol, styles.tableFirstCol]}>
                                    <Text style={styles.tableCell}>1</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{sanitize(colaborator.Name)}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{sanitize(colaborator.LastName)}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{sanitize(colaborator.SurName)}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{sanitize(colaborator.Ced)}</Text>
                                </View>
                                <View style={[styles.tableCol, styles.colNumberVisa]}>
                                    <Text style={styles.tableCell}>{sanitize(colaborator.CodVisado)}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{sanitize(colaborator.VisaDuration)}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{formatDate(colaborator.ExpirationDate)}</Text>
                                </View>
                            </View>
                        }

                    </View>
                    <Text style={[styles.textBody, styles.marginBottom]}>Observaciones</Text>
                    <Text style={styles.textBody}>a. La actividad que el proveedor desee realizar dentro de la tienda, deberá ser coordinada con el departamento de promoción del formato correspondiente.</Text>
                    <Text style={styles.textBody}>b. La persona arriba descrita, presentó constancia de estar enterado de las normas a observar establecidas por Walmart México y Centroamérica.</Text>
                    <Text style={[styles.textBody, styles.marginBottom]}>
                        c.  Es responsabilidad del proveedor reforzar periódicamente dichas normas así como el cumplimiento de las reglas que los colaboradores deben de seguir  en las tiendas o unidades de negocios a las cuales han sido asignados por el proveedor.
                    </Text>

                    {renderOptionD}


                    <View style={[styles.containerQr, styles.marginBottom]}>
                        <Image source={codeQr} style={styles.qr} />
                    </View>
                    <Text style={[styles.textBody, styles.marginBottom]}>"Contribuimos a mejorar la calidad de vida de las familias de México y Centroamérica"</Text>
                </View>
            </Page>

            <Page size="A4" style={styles.page}>
                <View style={styles.container}>

                    <Text style={[styles.textBody, styles.infoContact]}>En caso de tener alguna duda o consulta sobre esta certificación puede comunicarse con:</Text>

                    <Text style={styles.textBody}>Costa Rica</Text>
                    <Text style={styles.textBody}>Correo: CAMVisadoSeguridadCR@wal-mart.com</Text>
                    <Text style={[styles.textBody, styles.marginBottom]}>Teléfono: (506) 2582-8605 EXT 28605</Text>

                    <Text style={styles.textBody}>Guatemala</Text>
                    <Text style={styles.textBody}>Correo: CAMVisadoSeguridadGT@wal-mart.com</Text>
                    <Text style={[styles.textBody, styles.marginBottom]}>Teléfono: (502) 2243-7079 Ext 37079</Text>
                    <Text style={styles.textBody}>El Salvador</Text>
                    <Text style={styles.textBody}>Correo: CAMVisadoSeguridadSV@wal-mart.com</Text>
                    <Text style={[styles.textBody, styles.marginBottom]}>Teléfono: (503) 2523-6800 extensión 37410</Text>

                    <Text style={styles.textBody}>Honduras</Text>
                    <Text style={styles.textBody}>Correo: CAMVisadoSeguridadHN@wal-mart.com</Text>
                    <Text style={[styles.textBody, styles.marginBottom]}>Teléfono: (504) 2265-8100 Ext. 58690</Text>

                    <Text style={styles.textBody}>Nicaragua</Text>
                    <Text style={styles.textBody}>Correo: CAMVisadoSeguridadNI@wal-mart.com</Text>
                    <Text style={[styles.textBody, styles.marginBottom]}>Teléfono: (505) 2264-9370 Ext 49268</Text>
                    <Text style={[styles.textBody, styles.marginBottom]}>"Contribuimos a mejorar la calidad de vida de las familias de México y Centroamérica"</Text>

                </View>
            </Page>
            <Page size="A4" style={styles.page}>
                <View style={styles.container}>
                    <View>
                        <Image
                            style={[styles.image, styles.textCenter]}
                            source={Logo}
                        />
                        <Text style={[styles.textTitlePolicy, styles.marginBottom, styles.textCenter]}>
                            NORMAS A OBSERVAR POR PERSONAL DE EMPRESAS PROVEEDORAS O PROVEEDORES
                        </Text>
                        <Text style={[styles.textBody, styles.marginBottom]}>
                            1. Para poder ingresar a la tienda, presentar a Protección de Activos o a la administración de la tienda, el certificado de visado, junto con su identificación y carnet de su proveedor.
                        </Text>
                        <Text style={[styles.textBody, styles.marginBottom]}>
                            2. Reportar su ingreso y salida a Protección de Activos, Seguridad o a la Jefatura encargada del área.Portar carnet proporcionado por el proveedor en lugar visible y vestir el uniforme o vestimenta que corresponda.
                        </Text>
                        <Text style={[styles.textBody, styles.marginBottom]}>
                            3. No ingresar, portar, guardar o consumir alcohol o drogas o medicamentos que alteren su estado físico y / o mental durante la permanencia en las Instalaciones de Walmart.No fumar en las Instalaciones de Walmart, en virtud de lo dispuesto por las leyes referentes al consumo del tabaco en lugares públicos.
                        </Text>
                        <Text style={[styles.textBody, styles.marginBottom]}>
                            4. No portar cualquier tipo de armas, sean armas blancas o de fuego, así como cualquier tipo de explosivos y productos químicos.
                        </Text>
                        <Text style={[styles.textBody, styles.marginBottom]}>
                            5. No ingresar o permanecer en áreas de acceso restringido.
                        </Text>
                        <Text style={[styles.textBody, styles.marginBottom]}>
                            6. No perjudicar los intereses de otros proveedores.Deberá respetar el espacio en góndolas asignado a otros proveedores.
                        </Text>
                        <Text style={[styles.textBody, styles.marginBottom]}>
                            7. Realizar exclusivamente las actividades para las cuales fue designado a las Instalaciones de Walmart por su proveedor.
                        </Text>
                        <Text style={[styles.textBody, styles.marginBottom]}>
                            8. Debe asegurar que todos los productos de proveedor para el cual labora cumplan con las normas de Protección al Consumidor.
                        </Text>
                        <Text style={[styles.textBody, styles.marginBottom]}>
                            9. No participar o iniciar bromas, juegos, discusiones o burlas a personal de otros proveedores, clientes o empleados de Walmart, ni usar lenguaje o gestos inapropiados, amenazar ni utilizar violencia física o verbal durante la permanencia en las Instalaciones de Walmart.Para descansar e ingerir alimentos deberá utilizar las zonas definidas para ese fin en cada Instalación de Walmart.
                        </Text>
                        <Text style={[styles.textBody, styles.marginBottom]}>
                            10. Mantener el área donde efectúa sus actividades limpia y ordenada, libre de derrames.
                        </Text>
                        <Text style={[styles.textBody, styles.marginBottom]}>
                            11. Requerir autorización para: trabajos en altura; que requieran llamas, fuego, corte con acetileno, soldadura o esmerilado, productos químicos, venenos o insecticidas; e ingresar a espacios confinados.Cuando sea autorizado, debe portar el equipo de protección respectivo.
                        </Text>
                        <Text style={[styles.textBody, styles.marginBottom]}>
                            12. No bloquear salidas y rutas de evacuación de emergencia, ni bloquear extintores o elementos de la alarma de incendios.
                        </Text>
                        <Text style={[styles.textBody, styles.marginBottom]}>
                            13. En caso de simulacros o emergencias en las Instalaciones de Walmart, acatar las indicaciones de la Brigada de Walmart.Contar con el equipo y / o utensilios de protección personal especial requerida y haber sido capacitado para el uso seguro de los equipos que requiera para realizar sus actividades.
                        </Text>
                        <Text style={[styles.textBody, styles.marginBottom]}>
                            14. Utilizar extensiones eléctricas en buen estado, de cable diez(10) o doce(12) THH, las que podrá enchufar únicamente en los puntos autorizados por la jefatura del departamento en que se encuentran.
                        </Text>
                        <Text style={[styles.textBody, styles.marginBottom]}>
                            15. Colocar los desechos, incluyendo el cartón, en el lugar definido para su almacenamiento por la jefatura de Walmart.
                        </Text>
                        <Text style={[styles.textBody, styles.marginBottom]}>
                            16. Utilizar escalera de mano en buen estado para subirse a las góndolas, racks u otro tipo de muebles.
                        </Text>
                        <Text style={[styles.textBody, styles.marginBottom]}>
                            17. Entregar la muestra de las degustaciones al adulto responsable y no a los niños directamente.Informar previamente a entregar la muestra sobre si el producto contiene algún producto alergénico, partes pequeñas, o elementos que puedan causar ahogo o si está caliente
                        </Text>
                        <Text style={[styles.textBody, styles.marginBottom]}>
                            18. Portar la tarjeta de salud, carnet de manipulación de alimentos vigentes, o lo que la legislación nacional estipule.
                        </Text>
                        <Text style={[styles.textBody, styles.marginBottom]}>
                            19. Cumplir con el adecuado lavado de manos, uso de guantes, procedimiento de limpieza profunda y control de temperaturas.
                        </Text>
                        <Text style={[styles.textBody, styles.marginBottom]}>
                            20. Vigilar el cumplimiento de las normas de etiquetado de los productos a su cargo y bajo ninguna circunstancia re - etiquetar, modificar o alterar la información del producto tal como la vida útil.
                        </Text>
                        <Text style={[styles.textBody, styles.marginBottom]}>
                            21. No se permite ninguna práctica que ponga en riesgo la inocuidad de ninguno de los alimentos o productos que se comercialicen o degusten en las Instalaciones de Walmart.
                        </Text>
                    </View>
                </View>
            </Page>
            <Page size="A4" style={styles.page}>
                <View style={styles.container}>

                    <Text style={[styles.textBody, styles.infoContact, styles.text_decoration]}>
                        Sección: normas a observar relacionadas con Seguridad de la Cadena de Suministro:
                    </Text>
                    <Text style={[styles.textBody, styles.marginBottom]}>
                        1.	Cumplir con los procedimientos que Walmart establezca como parte de sus funciones y que garanticen la seguridad de la cadena de suministro.
                    </Text>
                    <Text style={[styles.textBody, styles.marginBottom]}>
                        2.	Reportar con el líder o contacto que Walmart designe, cualquier situación real o sospechosa que pueda poner en riesgo la cadena de suministro de Walmart, tales como:
                    </Text>
                    <Text style={[styles.textBody, styles.marginBottom, styles.subText]}>
                        a.	Personas no autorizadas o sospechosas dentro de alguna instalación donde exista un trasiego de carga de Walmart.
                    </Text>
                    <Text style={[styles.textBody, styles.marginBottom, styles.subText]}>
                        b.	Ingreso o egreso de mercancías sospechosas dentro de las cargas de Walmart.
                    </Text>
                    <Text style={[styles.textBody, styles.marginBottom, styles.subText]}>
                        c.	Algún caso o sospecha de intrusión o acceso inadecuado a los sistemas informáticos de Walmart, incluyendo el sistema de CCTV de la instalación de Walmart donde se trasiegue carga; o cualquier caso o sospecha de perdida, hurto o fuga de información desde los sistemas de Walmart.
                    </Text>
                    <Text style={[styles.textBody, styles.marginBottom, styles.subText]}>
                        d.	Incumplimiento a los procedimientos de carga y descarga de contenedores, incluyendo inspecciones agrícolas para garantizar la limpieza e inocuidad del equipo, así como las inspecciones de 7 puntos del contenedor.
                    </Text>
                    <Text style={[styles.textBody, styles.marginBottom, styles.subText]}>
                        e.	Robo, modificación o alteración de marchamos, sellos o precintos con los que se cierran los contenedores.
                    </Text>
                    <Text style={[styles.textBody, styles.marginBottom, styles.subText]}>
                        f.	Alteración de la documentación con la que viaja la carga de exportación.
                    </Text>
                    <Text style={[styles.textBody, styles.marginBottom]}>
                        3.	En consecuencia, de lo anterior, asumo mi compromiso y responsabilidad de cumplir con las medidas y protocolos implementados por Walmart.
                    </Text>
                    <Text style={[styles.textBody, styles.marginBottom]}>
                        4.	Reconozco que Walmart tiene un Código de Conducta, el cual está disponible en el siguiente enlace https://www.walmartcentroamerica.com/codigo-de-conducta
                    </Text>
                </View>
            </Page>
        </Document>
    );
}
