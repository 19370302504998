import walmartLogo from "./imagen/walMartLogo.svg";
import iconPerson from "./imagen/icon_person.svg";
import bigWalmartLogo from "./imagen/long_walmart_logo.svg";
import logoPNG from "./imagen/Walmart_Logo.png";
import logOutIcon from "./imagen/logOut_icon.svg";
import SolWalmart from "./imagen/SolWalmart.png";
import Spark from './imagen/Spark.gif'

export const WALMART_LOGO = {
  src: walmartLogo,
  style: { width: 186, heigth: 47 },
  lt: "walmart_long_logo",
};

export const PERSON_ICON = {
  src: iconPerson,
  style: { width: 18, heigth: 18 },
  lt: "icon_person",
};

export const BIG_WALMART_LOGO = {
  src: bigWalmartLogo,
  style: { width: 465, heigth: 162 },
  lt: "big_walmart_logo",
};

export const PDF_WALMART_LOGO = {
  src: logoPNG,
  style: { width: 113, heigth: 45 },
  lt: "big_walmart_logo",
};

export const LOGOUT_ICON = {
  src: logOutIcon,
  style: { width: 18, heigth: 18, marginRight: "8px" },
  lt: "logout_icon",
};

export const WALMART_Sol = {
  src: SolWalmart,
  style: { width: 40, heigth: 40, },
  lt: "sol_walmart",
};


export const WALMART_Loading = {
  src: Spark,
  style: { width: 100, heigth: 100 },
  lt: "loadingWalmart",
};
