import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import RadioGroup from "@material-ui/core/RadioGroup"
import Radio from "@material-ui/core/Radio"
import OutlinedInput from "@material-ui/core/OutlinedInput";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { Advice } from "../../../components/Advice/Advice";

import styleGlobal from "../../../global/styleGlobal";

export const LoginForm = (props) => {
    const classesGlobal = styleGlobal();

    const handleChangeRadio = (event) => {
        const { value } = event.target
        const newEvent = {
            target: {
                name: 'option',
                value
            }
        }
        if (props.handleChange) props.handleChange(newEvent)
    }

    return (
        <Box component="form" onSubmit={props.handleSubmit} noValidate sx={{ mt: 3 }} >
            <Typography component="h1" variant="h5" style={{ marginBottom: 35 }} className={classesGlobal.title}>
                <b>Bienvenido (a)</b>
            </Typography>
            <label className={classesGlobal.label}>Correo electrónico</label>
            <TextField
                required
                fullWidth
                id="email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={props.handleChange}
                variant="outlined"
                value={props.dataForm?.email ?? ""}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" tabindex="-1">
                            <Advice
                                tabindex="-1"
                                title={""}
                                text={"Ingrese un correo electrónico válido para poder continuar a la siguiente pantalla."}
                            ></Advice>
                        </InputAdornment>
                    ),
                }}
            />
            <FormControl>
                <RadioGroup
                    aria-labelledby="radio-buttons-group-login"
                    value={props.dataForm?.option ?? "pass"}
                    name="radio-buttons-group-login"
                    onChange={handleChangeRadio}
                >
                    <FormControlLabel value="code" control={<Radio />} label={`Recibir código de verificación`} />
                    <FormControlLabel value="pass" control={<Radio />} label="Iniciar sesión con contraseña" />
                </RadioGroup>
            </FormControl>
            <br></br>
            {props.dataForm?.option === "pass" &&
                <div>
                    <label className={classesGlobal.label}>Contraseña</label>
                    <OutlinedInput
                        type={props.showPassword ? "text" : "password"}
                        value={props.dataForm?.pp ?? ""}
                        fullWidth
                        id="pp"
                        name="pp"
                        onChange={props.handleChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => { if (props.handleTogglePassword) props.handleTogglePassword() }}
                                    onMouseDown={() => { if (props.handleTogglePassword) props.handleTogglePassword() }}
                                    edge="end"
                                >
                                    {props.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <div style={{ textAlign: "right", width: "100%" }}>
                        <Button variant="text" style={{ textTransform: "none" }} className={classesGlobal.buttonText} onClick={props.handleOpenPass} >
                            ¿Olvidó su contraseña?
                        </Button>
                    </div>
                    <div style={{ textAlign: "right", width: "100%" }}>
                        <Button variant="text" style={{ textTransform: "none" }} className={classesGlobal.buttonText} onClick={props.accountForm} >
                            Crear cuenta
                        </Button>
                    </div>
                </div>
            }
            <Button className={classesGlobal.button} fullWidth variant="contained" style={{ textTransform: "none", marginTop: 10, marginBottom: 5 }} onClick={() => { if (props.handleSubmit) props.handleSubmit() }} disabled={!props?.dataForm?.email}>
                {props.dataForm?.option === "pass" ? <b>Iniciar sesión</b> : <b>Enviar código de verificación</b>}
            </Button>
        </Box>
    )
}