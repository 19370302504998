import  Box  from "@material-ui/core/Box";
import  AppBar  from "@material-ui/core/AppBar";
import  Toolbar  from "@material-ui/core/Toolbar";
import  Container  from "@material-ui/core/Container";
import  CssBaseline  from "@material-ui/core/CssBaseline";
import {walmartBase64} from '../../assets/imagen/walmartBase64'

import style from "./style"

export const LoginContainer = (props) => {
    const classes = style();
    
    return (
        <>
            <Box sx={{flexGrow: 1}}>
                <AppBar position="static">
                    <Toolbar className={classes.toolbar}>
                        <img src={walmartBase64} className={classes.logo} alt="walmart"/>
                    </Toolbar>
                </AppBar>
            </Box>
            <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                {props.children}
            </Box>
            </Container>
        </>
    )
}