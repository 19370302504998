import { ENVS } from '../enums/environments';

const configs = {
    [ENVS.LOCAL]: {
        service_registry: {
            "WM_CONSUMER.ID": "4c77d654-3c6c-11ee-be56-0242ac120002",
            "WM_SVC.ENV": "dev",
            "WM_SVC.NAME": "CAM-VISADOS-API",
            "Wm_svc.Version": "1.0.0"
        },
        baseUrl: 'http://localhost:5000/camvisadoseguridad/api/v1',
        ORIGIN_THIRD: 'Terceros',
    },
    [ENVS.DEV]: {
        service_registry: {
            "WM_CONSUMER.ID": "4c77d654-3c6c-11ee-be56-0242ac120002",
            "WM_SVC.ENV": "dev",
            "WM_SVC.NAME": "CAM-VISADOS-API",
            "Wm_svc.Version": "1.0.0"
        },
        baseUrl: 'https://cam.visados.api.dev.cam.wal-mart.com/camvisadoseguridad/api/v1',
        ORIGIN_THIRD: 'https://vsthird.qa.walmart.com',
    },
    [ENVS.PENTEST]: {
        service_registry: {
            "WM_CONSUMER.ID": "ea40bc4c-3c6d-11ee-be56-0242ac120002",
            "WM_SVC.ENV": "stg",
            "WM_SVC.NAME": "CAM-VISADOS-API",
            "Wm_svc.Version": "1.0.0"
        },
        baseUrl: 'https://cam.visados.api.stg.cam.wal-mart.com/camvisadoseguridad/api/v1',
        ORIGIN_THIRD: 'https://personalproveedores.qa.wal-mart.com',
    },
    [ENVS.STAGE]: {
        service_registry: {
            "WM_CONSUMER.ID": "ea40bc4c-3c6d-11ee-be56-0242ac120002",
            "WM_SVC.ENV": "stg",
            "WM_SVC.NAME": "CAM-VISADOS-API",
            "Wm_svc.Version": "1.0.0"
        },
        baseUrl: 'https://developer.api.us.stg.walmart.com/api-proxy/service/CAM/VISADOS-API/v1',
        ORIGIN_THIRD: 'https://personalproveedores.qa.wal-mart.com',
    },
    [ENVS.PROD]: {
        service_registry: {
            "WM_CONSUMER.ID": "29df2ec4-985d-11ee-b9d1-0242ac120002",
            "WM_SVC.ENV": "prod",
            "WM_SVC.NAME": "CAM-VISADOS-API",
            "Wm_svc.Version": "1.0.0"
        },
        baseUrl: 'https://developer.api.us.walmart.com/api-proxy/service/CAM/VISADOS-API/v1',
        ORIGIN_THIRD: 'https://personalproveedores.wal-mart.com',
    }
};

const flags = () => {
    const isQA = /\.qa\./
    const isDev = /\.dev\./
    const isPentest = /\.pentest\./
    const location = window.location.href
    const host = window.location.host
    if (host === 'localhost:3000') {
        return ENVS.LOCAL
    } else if (isDev.exec(location)) {
        return ENVS.DEV
    } else if (isQA.exec(location)) {
        return ENVS.STAGE
    } else if (isPentest.exec(location)) {
        return ENVS.PENTEST
    } else {
        return ENVS.PROD
    }
}

export const environment = {
    ...configs[flags()]
};
