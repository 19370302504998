import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import styleGlobal from "../../../global/styleGlobal";
import { Advice } from "../../../components/Advice/Advice";
import PasswordChecklist from "react-password-checklist";


export const PassForm = (props) => {
    const classesGlobal = styleGlobal();

    const {
        dataForm,
        handleChange,
        handleResetPP,
        showPassword,
        isValidReset,
        handleTogglePassword
    } = props
    return (
        <Box component="form" noValidate sx={{ mt: 3 }} >
            <Typography component="h1" variant="h5" style={{ marginBottom: 35 }} className={classesGlobal.title}>
                <b>Crear nueva contraseña</b>
            </Typography>
            <p className={classesGlobal.infoText}>
                <b>{dataForm?.email}</b>
                <Button variant="text" className={classesGlobal.buttonText} style={{ textTransform: "none" }} onClick={props.returnValidEmail}>
                    <b>Cambiar</b>
                </Button>
            </p>
            <p className={classesGlobal.infoText}>
                Enviamos una contraseña temporal para que pueda iniciar sesión, revise su correo {dataForm?.email} inicie sesión.
            </p>
            <br></br>
            <label className={classesGlobal.label}>Contraseña temporal</label>
            <TextField
                variant="outlined"
                required
                fullWidth
                value={dataForm?.code ?? ""}
                id="code"
                name="code"
                autoComplete="code"
                onChange={handleChange}
                autocomplete="off"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Advice title={""} text={"Ingrese la contraseña que se le envio a su correo el cual debe de tener un máximo de 20 caracteres."}></Advice>
                        </InputAdornment>
                    ),
                }}
            />
            <label className={classesGlobal.label} style={{ marginTop: 15 }}>Nueva contraseña * </label>
            <OutlinedInput
                type={showPassword ? "text" : "password"}
                value={dataForm?.newPP ?? ""}
                fullWidth
                id="newPP"
                name="newPP"
                onChange={handleChange}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleTogglePassword}
                            onMouseDown={handleTogglePassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            <br></br>
            <p className={classesGlobal.infoText} style={{ marginTop: 15 }}>La contraseña debe contener:</p>
            <PasswordChecklist
                className={classesGlobal.infoText}
                rules={["maxLength", "minLength", "capital", "lowercase", "specialChar", "number"]}
                minLength={8}
                maxLength={100}
                value={dataForm?.newPP ?? ""}
                onChange={(isValid) => {
                    const event = {
                        target: {
                            name: 'passwordValid',
                            value: isValid
                        }
                    }
                    handleChange(event)
                }}
                messages={{
                    minLength: "Al menos 8 caracteres",
                    maxLength: "Un máximo de 100 caracteres",
                    specialChar: "Al menos un carácter especial",
                    capital: "Al menos una letra mayúscula",
                    lowercase: "Al menos una letra minúscula",
                    number: "Al menos un número.",
                }}
            />
            <br />
            <Button className={classesGlobal.button} fullWidth variant="contained"  style={{ textTransform: "none", marginTop: 10, marginBottom: 15 }} disabled={!isValidReset()} onClick={handleResetPP}  >
                Cambiar contraseña
            </Button>
        </Box>
    )
}