import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import { DataGrid, esES } from "@material-ui/data-grid";
import { formatDate } from "../../../utils/dates";
import Tooltip from "@material-ui/core/Tooltip";

//icons
import iconVencido from "./../../../assets/status/error.png";
import iconPorfVender from "./../../../assets/status/forbidden.png";
import iconVigente from "./../../../assets/status/check.png";
import SinAsignar from "./../../../assets/status/SinAsignar.png";
import iconCanceled from "./../../../assets/status/cancelar.png";

import AddIcon from "@material-ui/icons/Add";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import VisibilityIcon from '@material-ui/icons/Visibility';
// Utils
import { PERMISSIONS_TABLE } from "../../../utils/table-permissions"
import moment from 'moment';

import { useHistory } from "react-router-dom";

const icons = {
	Vencido: iconVencido,
	Rechazado: iconVencido,
	"Por Vencer": iconPorfVender,
	Vigente: iconVigente,
	Recibido: iconPorfVender,
	"Sin Asignar": SinAsignar,
	Cancelado: iconCanceled
};

const inputCss = {
	style: {
		padding: "5px !important",
	},
};
const useStyles = makeStyles((theme) => ({
	container: {
		width: "100%",
		overflow: "wrap",
	},
	button: {
		textTransform: "none",
		borderRadius: "1rem",
		width: "8rem",
	},
	buttonContainer: {
		marginTop: 30,
	},
	Recibido: {
		color: "#000",
	},
	Rechazado: {
		color: "red"
	},
	Vencido: {
		color: "red",
	},
	Vigente: {
		color: "green",
	},
	Aprobado: {
		color: "#3bb54a",
	},
	Borrar: {
		color: "red",
	},
	Eliminado: {
		color: "#3C3737",
	},
	Cancelado: {
		color: "#3C3737",
	},
	iconStatus: {
		width: 20,
		height: 20,
		marginRight: 10,
	},
	iconContainer: {
		display: "flex",
		alignItems: "center",
	},
	"Sin-Asignar": {
		color: "#0072ce",
	},
	"Por-vencer": {
		color: "#0072ce",
	},
	notFoundText: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		height: "100%",
		paddingTop: 100,
	},
	columns: {
		color: theme.palette.primary.main,
		fontWeight: "bold",
		textAlign: "center",
	},
	buttonSee: {
		borderRadius: "20px",
		textTransform: "none !important",
	},
	buttonUpdate: {
		textTransform: "none !important",
	}
}));

export default function TableContent(props) {
	const {
		rowsThirdList,
		handleChangeRowsSelect,
		redirectVisaRegister,
		pageSize,
		setPageSize,
		redirectVisaApplicationList
	} = props;

	const classes = useStyles();

	const history = useHistory();

	const redirectEditRegister = (data) => {
		history.push("/company-edit/" + data.Id);
	}



	const diffDays = (date, otherDate) => `A ${otherDate.diff(date, 'days') + 1} días por vencer`

	const columns = [
		{
			field: "ExpirationStatus",
			renderHeader: () => {
				return <span className={classes.columns}>{"Estado"}</span>;
			},
			width: 150,
			headerAlign: "center",
			renderCell: (cellValues) => {
				return (
					<Tooltip title={cellValues.row.ExpirationStatus === "Por Vencer" ? diffDays(moment(), moment(cellValues.row.Expiration)) : ""} placement="left-start">
						<div className={classes.iconContainer}>
							{icons[cellValues.row.ExpirationStatus] ? (
								<img
									className={classes.iconStatus}
									src={icons[cellValues.row.ExpirationStatus]}
								/>
							) : null}
							<span className={classes[cellValues.row.ExpirationStatus.replace(" ", "-")]}>
								{cellValues.row.ExpirationStatus}
							</span>
						</div>
					</Tooltip>
				);
			},
		},
		{
			field: "BusinessName",
			renderHeader: () => {
				return <span className={classes.columns}>{"Socio comercial"}</span>;
			},
			width: 355,
			headerAlign: "center",
			renderCell: (cellValues) => {
				return (
					<span>
						{cellValues.row.Company}
					</span>
				);
			},
		},
		{
			field: "ExpirationDate",
			renderHeader: () => {
				return (
					<span className={classes.columns}>{"Fecha de vencimiento"}</span>
				);
			},
			width: 200,
			headerAlign: "center",
			align: "center",
			renderCell: (cellValues) => {
				return <span>{formatDate(cellValues.row.Expiration)}</span>;
			},
		},
		{
			field: "action0",
			renderHeader: () => {
				return <span className={classes.columns}>{""}</span>;
			},
			width: 190,
			align: "center",
			renderCell: (cellValues) => {
				// Extract nested ternary into an independent function
				const renderActionButton = () => {
					if (!PERMISSIONS_TABLE[cellValues.row.ExpirationStatus]) {
						return null;
					}
					if (PERMISSIONS_TABLE[cellValues.row.ExpirationStatus].Solicitar) {
						return (
							<div>
								<Button
									variant="outlined"
									color="secondary"
									startIcon={<AddIcon />}
									className={classes.buttonSee}
									onClick={() => redirectVisaRegister(cellValues.row)}
								>
									Solicitar visado
								</Button>
							</div>
						);
					}
					return null;
				};

				return renderActionButton();
			},
		},
		{
			field: "action1",
			renderHeader: () => {
				return <span className={classes.columns}>{""}</span>;
			},
			width: 150,
			align: "center",
			renderCell: (cellValues) => {
				// Extract nested ternary into an independent function
				const renderActionButton = () => {
					if (!PERMISSIONS_TABLE[cellValues.row.ExpirationStatus]) {
						return null;
					}
					if (PERMISSIONS_TABLE[cellValues.row.ExpirationStatus].Actualizar) {
						return (
							<Tooltip title={PERMISSIONS_TABLE[cellValues.row.ExpirationStatus].Mensaje}>
								<div>
									<Button
										onClick={() => redirectEditRegister(cellValues.row)}
										color="secondary"
										data-testid="redirectEditRegister"
										className={classes.buttonUpdate}
										startIcon={<AutorenewIcon />}
									>
										{PERMISSIONS_TABLE[cellValues.row.ExpirationStatus].Action}
									</Button>
								</div>
							</Tooltip>
						);
					}
					return null;
				};
			
				return renderActionButton();
			},
		},
		{
			field: "action2",
			width: 150,
			align: "center",
			renderHeader: () => {
				return <span className={classes.columns}>{""}</span>;
			},
			renderCell: (cellValues) => {
				// Extract nested ternary into an independent function
				const renderActionButton = () => {
					if (!PERMISSIONS_TABLE[cellValues.row.ExpirationStatus]) {
						return null;
					}
					if (PERMISSIONS_TABLE[cellValues.row.ExpirationStatus].Ver) {
						return (
							<div>
								<Button
									onClick={() => redirectVisaApplicationList(cellValues.row)}
									color="secondary"
									className={classes.buttonUpdate}
									startIcon={<VisibilityIcon />}
								>
									Ver visados
								</Button>
							</div>
						);
					}
					return null;
				};
			
				return renderActionButton();
			},
		},
	];

	return (

		<div className={classes.container}>
			<DataGrid
				rows={rowsThirdList}
				columns={columns}
				pageSize={pageSize}
				onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
				rowsPerPageOptions={[25, 50, 100]}
				pagination
				autoHeight
				disableColumnSelector
				disableColumnMenu
				disableSelectionOnClick
				columnBuffer={6}
				getRowId={(row) => row.Id}
				onSelectionModelChange={(rows) => {
					handleChangeRowsSelect(rows);
				}}
				isRowSelectable={(e) => { }}
				components={{
					NoRowsOverlay: () => (
						<span className={classes.notFoundText}>
							No se han encontrado registros
						</span>
					),
				}}
				localeText={esES.props.MuiDataGrid.localeText}
			/>
		</div>
	);
}
