import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

import Dashboard from "./components/Dashboard";
import TableContent from "./components/TableContent";

import {
	getCompanyRequestList,
	getCompanyDashboardData,
} from "../../services/company.service";
import {
	getUserDetailByEmail
} from "../../services/profile.service";
import { getCountriesListByEmail } from "../../services/countries.service";

import AddIcon from "@material-ui/icons/Add";
import InputLabel from "@material-ui/core/InputLabel";
import { Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {
	showLoadingAction,
	hideLoadingAction,
} from "../../redux/actions/loading.actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Disclamer from "../../components/Disclamer/Disclamer";
import { setTitlePageAction } from "../../redux/actions/title.actions";

const useToolbarStyles = makeStyles((theme) => ({
	root: {},
	tableContainer: {
		width: "75rem",
		margin: "auto",
		marginTop: "2rem",
		marginBottom: "5rem",
	},
	boton: {
		boxSizing: "border-box",
		borderRadius: 18,
		border: "1px solid #0071CE",
		color: "#0071CE",
	},
	dasboardContainer: {
		display: "flex",
		justifyContent: "center",
		marginTop: "2rem",
	},
	countryContainer: {
		width: "59rem",
		margin: "auto",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "0px 4rem",
	},
	buttonRegister: {
		borderRadius: "20px",
		textTransform: "none !important",
	},
	containerInput: {
		marginTop: 20,
		width: "50%",
	},
	labelInput: {
		marginTop: 0,
		color: theme.palette.primary.main,
	},
	selectInput: {
		minWidth: "90%",
		padding: 0,
	},
}));

export default function CompanyRegisterList({ auth }) {
	const classes = useToolbarStyles();
	const [dataTable, setDataTable] = useState([]);
	const [countriSelect, setCountriSelect] = useState("");
	const [countries, setCountries] = useState([]);
	const [dataDashboard, setDataDashbaord] = useState([]);
	const [userInfo, setUserInfo] = useState({});
	const [pageSize, setPageSize] = useState(25)
	const [registerButton, setRegisterButton] = useState(true)

	const dispatch = useDispatch();

	const history = useHistory();

	useEffect(() => {
		window.scrollTo(0, 0);
		initPage();
	}, []);

	const initPage = async () => {
		dispatch(showLoadingAction());
		dispatch(setTitlePageAction({ text: "Inicio" }));
		const user = auth.getUserInfo();
		setUserInfo(user);
		const countriesList = await getCountries(user.email);
		if (Array.isArray(countriesList) && countriesList.length > 0) {
			setCountries(countriesList);
			setCountriSelect(countriesList[0].Id);
			await getDataDashboard(user.email, countriesList[0].Id);
			await getDataTable(user.email, countriesList[0].Id);
		}
		await getDetail(user.email)
		dispatch(hideLoadingAction());
	};

	const getDataDashboard = async (email, countryId) => {
		try {
			const res = await getCompanyDashboardData(email, countryId);
			setDataDashbaord([res]);
		} catch (err) { }
	};

	const getDetail = async (userEmail) => {
		try {
			const res = await getUserDetailByEmail(userEmail);

			if (!res.UploadedFiles) {
				setRegisterButton(false)
				return
			}
			if (res.UploadedFiles.length <= 0) {
				setRegisterButton(false)
				return
			}

		} catch (err) {
			console.log("res detail", err);
		}
	};

	const getDataTable = async (email, countryId) => {
		try {
			const res = await getCompanyRequestList(email, countryId);
			const list = res.map((e) => ({ ...e, id: e.Id }));
			setDataTable(list);
		} catch (err) { }
	};
	const handleChangeCountry = async (event) => {
		dispatch(showLoadingAction());
		setCountriSelect(event.target.value);
		await getDataDashboard(userInfo.email, event.target.value);
		await getDataTable(userInfo.email, event.target.value);
		dispatch(hideLoadingAction());
	};

	const getCountries = async (userEmail) => {
		try {
			return await getCountriesListByEmail(userEmail);
		} catch (err) {
			console.log("err", err);
			return [];
		}
	};

	const redirectRegister = () => {
		history.push("/company-register");
	};

	const redirectVisaRegister = (params) => {
		history.push(`/visa-register?country=${countriSelect}&vendor=${params.VendorId}`);
	};

	const handleDashboardAllWithCountry = (countrieId) => {
		history.push(`/visa-list?country=${countrieId}&visaType=solicitudes`)
	}

	const handleStatusDashboard = (status, countrieId) => {
		if(status === "Aprobado") {
			history.push(`/visa-list?country=${countrieId}`)
		} else {
			history.push(`/visa-list?country=${countrieId}&visaType=solicitudes&status=${status}`)
		}
	}

	const redirectVisaApplicationList = (data) => {
		history.push(`/visa-list?country=${countriSelect}&vendor=${data.VendorId}`)
	}


	return (
		<div>
			<div className={classes.dasboardContainer}>
				<Dashboard
					dataDashboard={dataDashboard}
					handleDashboardAllWithCountry={handleDashboardAllWithCountry}
					handleStatusDashboard={handleStatusDashboard}
				/>
			</div>
			<div className={classes.countryContainer}>
				<Tooltip title={!registerButton ? 'Es necesario que configure su perfil' : ''}>
					<span>
						<Button
							variant="outlined"
							color="secondary"
							className={classes.buttonRegister}
							onClick={redirectRegister}
							disabled={!registerButton}
							startIcon={<AddIcon />}
						>
							Registrar empresa
						</Button>
					</span>
				</Tooltip>
				<div className={classes.containerInput}>
					<InputLabel id="country" className={classes.labelInput}>

						* País
					</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={countriSelect}
						name="country"
						onChange={handleChangeCountry}
						variant="outlined"
						className={classes.selectInput}
					>
						{countries.map((item) => (
							<MenuItem key={item.Id} value={item.Id}>
								{item.Name}
							</MenuItem>
						))}
					</Select>
				</div>
			</div>
			<div className={classes.tableContainer}>
				<TableContent
					selectRows={[]}
					rowsThirdList={dataTable}
					redirectVisaRegister={redirectVisaRegister}
					pageSize={pageSize}
					setPageSize={setPageSize}
					redirectVisaApplicationList={redirectVisaApplicationList}
				/>
				<Disclamer />
			</div>
		</div>
	);
}
