
import { environment } from '../config/config';
import { axiosWithToken } from '../config/axiosConfig';

export const getVisaThirdInfoByEnail = async (userEmail) => {
    const userEncript = window.btoa(userEmail);
    const res = await axiosWithToken.get(`${environment.baseUrl}/VisaThirdParty/VendorsByEmail/${userEncript}`);
    if(res.data && res.data.Results) {
        return res.data.Results;
    }
    return [];
}

export const getVendorsByEmailAndContrieId = async (userEmail,countrieId) => {
    const userEncript = window.btoa(userEmail);
    const res = await axiosWithToken.get(`${environment.baseUrl}/VisaThirdParty/VendorsByEmailAndCountrieId/${userEncript}?countrieId=${countrieId}`);
    if(res.data && res.data.Results) {
        return res.data.Results;
    }
    return [];
}



