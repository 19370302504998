import React from 'react'
import { DataGrid, esES } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";

//UI
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
    Button,
    Tooltip
} from "@material-ui/core";

//Icons
import iconVencido from "../../../assets/status/error.png";
import iconPorfVender from "../../../assets/status/forbidden.png";
import iconVigente from "../../../assets/status/check.png";
import SinAsignar from "../../../assets/status/SinAsignar.png";
import iconCanceled from "../../../assets/status/cancelar.png";
import iconDraft from "../../../assets/status/eraser.png"
import iconDeleted from "../../../assets/status/borrar.png";
//Utils
import { formatDate } from "../../../utils/dates";

//const
import {
    INVALID_EDIT_STATUS,
    INVALID_DETAIL_VISA
} from "../../../utils/constants"

// Define the NoRowsOverlay component outside of the render method
const NoRowsOverlay = ({ classes }) => (
	<span className={classes.notFoundText}>
		No se han encontrado registros
	</span>
);

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: 50,
        width: "100%",
        overflow: "wrap",
    },
    notFoundText: {
        paddingTop: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
    columns: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
        textAlign: "center",
        overflowWrap: "break-word",
        whiteSpace: 'unset'
    },
    iconStatus: {
        width: 20,
        height: 20,
        marginRight: 10,
    },
    iconContainer: {
        display: "flex",
        alignItems: "center",
    },
    Recibido: {
        color: "#000",
    },
    Rechazado: {
        color: "red"
    },
    Vencido: {
        color: "red",
    },
    Vigente: {
        color: "green",
    },
    Aprobado: {
        color: "#3bb54a",
    },
    Borrar: {
        color: "red",
    },
    Eliminado: {
        color: "#3C3737",
    },
    Cancelado: {
        color: "#3C3737",
    },
    "Sin-Asignar": {
        color: "#0072ce",
    },
    "Por-vencer": {
        color: "#0072ce",
    },
    Borrador: {
        color: "#455a64"
    },
}));


const VisaApplicationTable = (props) => {

    const {
        rows,
        handleChangeRowsSelect,
        pageSize,
        setPageSize,
        variant,
        redirectVisaEdit,
        redirectVisaDownload,
        redirectVisaDetail
    } = props

    const classes = useStyles();


    const icons = {
        Vencido: iconVencido,
        Rechazado: iconVencido,
        "Por Vencer": iconPorfVender,
        Vigente: iconVigente,
        Recibido: iconPorfVender,
        "Sin Asignar": SinAsignar,
        Cancelado: iconCanceled,
        Borrador: iconDraft,
        Aprobado: iconVigente,
        Eliminado: iconDeleted
    };

    const columnsRequest = [
        {
            field: "Status",
            renderHeader: () => {
                return <span className={classes.columns}>Estado</span>
            },
            width: 160,
            headerAlign: "center",
            renderCell: (cellValues) => {
                return (
                    <div className={classes.iconContainer}>
                        {icons[cellValues.row.Status] ? (
                            <img
                                className={classes.iconStatus}
                                src={icons[cellValues.row.Status]}
                            />
                        ) : null}
                        <span className={classes[cellValues.row.Status.replace(" ", "-")]}>
                            {cellValues.row.Status}
                        </span>
                    </div>
                );
            }
        },
        {
            field: "RequestId",
            renderHeader: () => {
                return <span className={classes.columns}>Número de solicitud</span>
            },
            style: { overflowWrap: "break-word" },
            width: 160,
            align: "center",
            renderCell: (cellValues) => {
                return (
                    <span> {cellValues.row.RequestId}</span>
                );
            }
        },
        {
            field: "CreationDate",
            renderHeader: () => {
                return <span className={classes.columns}>Fecha solicitud</span>
            },
            width: 200,
            align: "center",
            headerAlign: "center",
            renderCell: (cellValues) => {
                return (
                    <span>{formatDate(cellValues.row.CreationDate)}</span>
                );
            }
        },
        {
            field: "VisaType",
            renderHeader: () => {
                return <span className={classes.columns}>Tipo de visado</span>
            },
            width: 400,
            headerAlign: "center",
            renderCell: (cellValues) => {
                return (
                    <span>{cellValues.row.VisaType}</span>
                );
            }
        },
        {
            field: "Country",
            renderHeader: () => {
                return <span className={classes.columns}>País</span>
            },
            width: 200,
            headerAlign: "center",
            renderCell: (cellValues) => {
                return (
                    <span>{cellValues.row.Country}</span>
                );
            }
        },
        {
            field: "CompanyVendor",
            renderHeader: () => {
                return <span className={classes.columns}>Socio Comercial</span>
            },
            width: 350,
            headerAlign: "center",
            renderCell: (cellValues) => {
                return (
                    <span>{cellValues.row.CompanyVendor}</span>
                );
            }
        },
        {
            field: "action1",
            renderHeader: () => {
                return <span className={classes.columns}>{""}</span>;
            },
            width: 130,
            align: "center",
            renderCell: (cellValues) => {
                return (
                    !INVALID_DETAIL_VISA.includes(cellValues.row.Status) ?
                        <Tooltip title="Ver Detalle">
                            <Button
                                color="secondary"
                                onClick={() => redirectVisaDetail(cellValues.row)}
                                startIcon={<VisibilityIcon />}
                            >
                            </Button>
                        </Tooltip> : null
                );
            },
        },
        {
            field: "action2",
            renderHeader: () => {
                return <span className={classes.columns}>{""}</span>;
            },
            width: 130,
            align: "center",
            renderCell: (cellValues) => {
                return (
                    !INVALID_EDIT_STATUS.includes(cellValues.row.Status) ? <Tooltip title="Editar">
                        <Button
                            color="secondary"
                            startIcon={<EditIcon />}
                            onClick={() => redirectVisaEdit(cellValues.row)}
                        >
                        </Button>
                    </Tooltip> : null
                );
            },
        }

    ]

    const columnsCollaborator = [
        {
            field: "Name",
            renderHeader: () => {
                return <span className={classes.columns}>Nombre</span>
            },
            width: 150,
            headerAlign: "center",
            renderCell: (cellValues) => {
                return (
                    <span> {cellValues.row.Name}</span>
                );
            }
        },
        {
            field: "LastName",
            renderHeader: () => {
                return <span className={classes.columns}>Primer apellido</span>
            },
            width: 150,
            headerAlign: "center",
            renderCell: (cellValues) => {
                return (
                    <span> {cellValues.row.LastName}</span>
                );
            }
        },
        {
            field: "SurName",
            renderHeader: () => {
                return <span className={classes.columns}>Segundo apellido</span>
            },
            width: 150,
            renderCell: (cellValues) => {
                return (
                    <span> {cellValues.row.SurName}</span>
                );
            }
        },
        {
            field: "Ced",
            renderHeader: () => {
                return <span className={classes.columns}>Cédula</span>
            },
            width: 150,
            headerAlign: "center",
            renderCell: (cellValues) => {
                return (
                    <span> {cellValues.row.Ced}</span>
                );
            }
        },
        {
            field: "VisaType",
            renderHeader: () => {
                return <span className={classes.columns}>Tipo de visado</span>
            },
            width: 330,
            headerAlign: "center",
            renderCell: (cellValues) => {
                return (
                    <span> {cellValues.row.VisaType}</span>
                );
            }
        },
        {
            field: "Countrie",
            renderHeader: () => {
                return <span className={classes.columns}>País</span>
            },
            width: 200,
            headerAlign: "center",
            renderCell: (cellValues) => {
                return (
                    <span>{cellValues.row.Countrie}</span>
                );
            }
        },
        {
            field: "Vendor",
            renderHeader: () => {
                return <span className={classes.columns}>Socio Comercial</span>
            },
            width: 350,
            headerAlign: "center",
            renderCell: (cellValues) => {
                return (
                    <span>{cellValues.row.Vendor}</span>
                );
            }
        },
        {
            field: "ExpirationDate",
            renderHeader: () => {
                return <span className={classes.columns}>Vencimiento</span>
            },
            width: 150,
            align: "center",
            headerAlign: "center",
            renderCell: (cellValues) => {
                return (
                    <span>{formatDate(cellValues.row.ExpirationDate)}</span>
                );
            }
        },
        {
            field: "VisaDuration",
            renderHeader: () => {
                return <span className={classes.columns}>Duración</span>
            },
            width: 100,
            headerAlign: "center",
            renderCell: (cellValues) => {
                return (
                    <span> {cellValues.row.VisaDuration}</span>
                );
            }
        },
        {
            field: "action1",
            renderHeader: () => {
                return <span className={classes.columns}>{"Visado"}</span>;
            },
            width: 100,
            align: "center",
            renderCell: (cellValues) => {
                return (
                    <Tooltip title={cellValues.row.IsActive ? "Descargar" : "Colaborador no activo"}>
                        <span>
                            <Button
                                color="secondary"
                                startIcon={<GetAppIcon />}
                                onClick={() => redirectVisaDownload(cellValues.row)}
                                disabled={!cellValues.row.IsActive}
                            >
                            </Button>
                        </span>
                    </Tooltip>
                );
            },
        }
    ]

    return (
        <div className={classes.container}>
            <DataGrid
                rows={rows}
                columns={variant === 'solicitudes' ? columnsRequest : columnsCollaborator}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[25, 50, 100]}
                pagination
                getRowId={(row) => row.ApplicationId ? row.ApplicationId : row.Id}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                autoHeight
                onSelectionModelChange={(rows) => {
                    handleChangeRowsSelect(rows);
                }}
                isRowSelectable={(e) => { }}
                components={{
                    NoRowsOverlay: () => (
                        <span className={classes.notFoundText}>
                            No se han encontrado registros
                        </span>
                    ),
                }}
                localeText={esES.props.MuiDataGrid.localeText}
            />
        </div>
    )
}


export default VisaApplicationTable
