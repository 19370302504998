import React from "react";
import Button from "@material-ui/core/Button";

import { makeStyles } from "@material-ui/styles";

//icons
import iconVencido from "./../../../assets/status/error.png";
import iconPorfVender from "./../../../assets/status/forbidden.png";
import iconVigente from "./../../../assets/status/check.png";
import SinAsignar from "./../../../assets/status/SinAsignar.png";
import iconCanceled from "./../../../assets/status/cancelar.png";
import iconEliminado from "./../../../assets/status/borrar.png";

const icons = {
  Rechazadas: iconVencido,
  Aceptadas: iconVigente,
  Recibidas: iconPorfVender,
  "Sin Asignar": SinAsignar,
  Canceladas: iconCanceled,
  Eliminados: iconEliminado
};

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    background: "#d2eeff",
    padding: "15px 1rem",
    borderRadius: "5px",
    border: "2px solid " + theme.palette.primary.main,
    marginBottom: "15px",
    width: "78rem"
  },
  titleContainer: {
    width: "8rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  title: {
    fontSize: "1.3rem",
    color: theme.palette.primary.main
  },
  itemContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginLeft: "50px"
  },
  itemTitle: {
    margin: 0,
    fontSize: "2rem",
    color: "#0071CE",
    textTransform: "none !important",
    padding: 0
  },
  itemDescription: {
    margin: 0,
    fontSize: "0.8rem",
    color: "#0071CE",
    textTransform: "none !important"
  },
  button: {
    textTransform: "none !important"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
  iconStatus: {
    width: 15,
    height: 15,
    marginRight: 5,
  },
  Recibido: {
    color: "#000",
  },
  Rechazado: {
    color: "red"
  },
  Vencido: {
    color: "red",
  },
  Vigente: {
    color: "green",
  },
  Aprobado: {
    color: "#3bb54a",
  },
  Borrar: {
    color: "red",
  },
  Eliminado: {
    color: "#3C3737",
  },
  Cancelado: {
    color: "#3C3737",
  },
  "Sin-Asignar": {
    color: "#0072ce",
  },
  "Por-vencer": {
    color: "#0072ce",
  }
}));



export default function Dashboard({
  dataDashboard,
  showDashboardDetails,
  seeDetails,
  handleStatusDashboard,
  handleDashboardAll,
  handleDashboardAllWithCountry
}) {
  const classes = useStyles();

  return (
    <div>
      {dataDashboard.map(e => (
        // Major: Missing "key" prop for element in iterator
        // tags: jsx, performance, react
        // Reason to ignore: there's no Id property in the Dashboard object
        // See Walmart.VisadoSeguridad.Api.Repository.Entities.Dashboard for reference
        <div className={classes.container}>
          <div className={classes.titleContainer}>
            <h3 className={classes.title}>Solicitudes de visados</h3>
          </div>
          <div className={classes.itemContainer}>
            {/* <p className={classes.itemTitle}>{e.CountrieTotal}</p> */}
            <Button
              size="small"
              onClick={() => handleDashboardAllWithCountry(e.CountrieId)}
              data-testid="Solicitudes totales"
              color="secondary"
              className={classes.itemTitle}>
              {e.CountrieTotal}
            </Button>
            <Button
              size="small"
              onClick={() => handleDashboardAllWithCountry(e.CountrieId)}
              color="secondary"
              className={classes.itemDescription}>
              Solicitudes totales
            </Button>
            {/* <p className={classes.itemDescription}>Solicitudes totales</p> */}
          </div>
          <div className={classes.itemContainer}>
            <Button
              size="small"
              onClick={() => handleStatusDashboard("Aprobado", e.CountrieId)}
              color="secondary"
              data-testid="Aprobadas"
              className={classes.itemTitle}>
              {e.Aprobados}
            </Button>
            <Button
              size="small"
              onClick={() => handleStatusDashboard("Aprobado", e.CountrieId)}
              color="secondary"
              className={classes.itemDescription}>
              <div className={classes.iconContainer}>
                <img
                  className={classes.iconStatus}
                  src={icons["Aceptadas"]}
                />
                <span className={classes["Vigente"]}>Aprobadas</span>
              </div>
            </Button>
          </div>
          <div className={classes.itemContainer}>
            <Button
              size="small"
              onClick={() => handleStatusDashboard("Rechazado", e.CountrieId)}
              color="secondary"
              data-testid="Rechazadas"
              className={classes.itemTitle}>
              {e.Rechazados}
            </Button>
            <Button
              size="small"
              onClick={() => handleStatusDashboard("Rechazado", e.CountrieId)}
              color="secondary"
              className={classes.itemDescription}>
              <div className={classes.iconContainer}>
                <img
                  className={classes.iconStatus}
                  src={icons["Rechazadas"]}
                />
                <span className={classes["Rechazado"]}>Rechazadas</span>
              </div>
            </Button>
          </div>
          <div className={classes.itemContainer}>
            <Button
              size="small"
              onClick={() => handleStatusDashboard("Cancelado", e.CountrieId)}
              color="secondary"
              data-testid="Canceladas"
              className={classes.itemTitle}>
              {e.Cancelados}
            </Button>
            <Button
              size="small"
              onClick={() => handleStatusDashboard("Cancelado", e.CountrieId)}
              color="secondary"
              className={classes.itemDescription}>
              <div className={classes.iconContainer}>
                <img
                  className={classes.iconStatus}
                  src={icons["Canceladas"]}
                />
                <span className={classes["Cancelado"]}>Canceladas</span>
              </div>
            </Button>
          </div>
          <div className={classes.itemContainer}>
            <Button
              size="small"
              data-testid="Eliminadas"
              onClick={() => handleStatusDashboard("Eliminado", e.CountrieId)}
              color="secondary"
              className={classes.itemTitle}>
              {e.Eliminados}
            </Button>
            <Button
              size="small"
              onClick={() => handleStatusDashboard("Eliminado", e.CountrieId)}
              color="secondary"
              className={classes.itemDescription}>
              <div className={classes.iconContainer}>
                <img
                  className={classes.iconStatus}
                  src={icons["Eliminados"]}
                />
                <span className={classes["Eliminado"]}>Eliminadas</span>
              </div>
            </Button>
          </div>

          <div className={classes.itemContainer}>
            <Button
              size="small"
              data-testid="Recibidas"
              onClick={() => handleStatusDashboard("Recibido", e.CountrieId)}
              color="secondary"
              className={classes.itemTitle}>
              {e.Recibidos}
            </Button>
            <Button
              size="small"
              onClick={() => handleStatusDashboard("Recibido", e.CountrieId)}
              color="secondary"
              className={classes.itemDescription}>
              <div className={classes.iconContainer}>
                <img
                  className={classes.iconStatus}
                  src={icons["Recibidas"]}
                />
                <span className={classes["Recibido"]}>Recibidas</span>
              </div>
            </Button>
          </div>
          <div className={classes.itemContainer}>
            <Button
              size="small"
              onClick={() => handleStatusDashboard("Sin Asignar", e.CountrieId)}
              color="secondary"
              data-testid="Sin Asignar"
              className={classes.itemTitle}>
              {e.SinAsignar}
            </Button>
            <Button
              size="small"
              onClick={() => handleStatusDashboard("Sin Asignar", e.CountrieId)}
              color="secondary"
              className={classes.itemDescription}>
              <div className={classes.iconContainer}>
                <img
                  className={classes.iconStatus}
                  src={icons["Sin Asignar"]}
                />
                Sin Asignar
              </div>
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
}
