export const NORMAL_INPUT = ["required"];

export const NORMAL_INPUT_TEXT = ["required", "isString", "maxStringLength:50"];
export const NORMAL_INPUT_NUMBER = ["required", "isNumber", "maxStringLength:50"];

export const INPUT_ONLY_LETTER = ["required",'matchRegexp:^[a-zA-ZÁÉÍÓÚáéíóúñÑ ]+$', "maxStringLength:50"]

export const CHECKBOX_INPUT = ["required"];
export const NORMAL_INPUT_MAX = ["required", "maxStringLength:50"];

export const NORMAL_INPUT_MAX_TEXTAREA = ["required", "maxStringLength:125"];

export const EMAIL_INPUT = ["required", "isEmail"];
export const NORMAL_INPUT_MESSAGE = ["Este campo es requerido"];
export const CHECKBOX_INPUT_MESSAGE = ["Elegir al menos una opción"];

export const INPUT_ONLY_LETTER_MESSAGE = ["Este campo es requerido","Solo se aceptan letras","Máximo 50 caracteres"]

export const EMAIL_INPUT_MESSAGE = [
  "Este campo es requerido",
  "Email no valido"
];
export const EMAIL_INPUT_MAX_MESSAGE = [
  "Este campo es requerido",
  "Máximo 50 caracteres"
];
export const EMAIL_INPUT_TEXT_MAX_MESSAGE = [
  "Este campo es requerido",
  "Solo se aceptan letras",
  "Máximo 50 caracteres"
];
export const EMAIL_INPUT_NUMBER_MAX_MESSAGE = [
  "Este campo es requerido",
  "Solo se aceptan numeros",
  "Máximo 50 caracteres"
];
export const EMAIL_INPUT_MAX = [
  "Este campo es requerido",
  "Máximo 50 caracteres"
];

export const EMAIL_INPUT_MAX_MESSAGE_TEXTAREA = [
  "Este campo es requerido",
  "Máximo 125 caracteres"
];


